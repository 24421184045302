export default {
  publikasjoner: 'Tradisjonelle Publikasjoner',
  varemerke: {
    title: 'Norsk varemerketidende',
    ingress: 'Norsk varemerketidende er en publikasjon som inneholder kunngjøringer innenfor varemerkeområdet.',
    info_msg:
      'Norsk varemerketidende er publisert i PDF-format og ordnet etter årstall. Noen av filene er store, og det kan derfor ta litt tid å laste dem ned.',
    year_label: 'Norsk varemerketidende'
  },
  patent: {
    title: 'Norsk patenttidende',
    ingress: 'Norsk patenttidende er en publikasjon som inneholder kunngjøringer innenfor patentområdet.',
    info_msg:
      'Norsk patenttidende er publisert i PDF-format og ordnet etter årstall. Noen av filene er store, og det kan derfor ta litt tid å laste dem ned.',
    year_label: 'Norsk patenttidende'
  },
  design: {
    title: 'Norsk designtidende',
    ingress: 'Norsk designtidende er en publikasjon som inneholder kunngjøringer innenfor designområdet.',
    info_msg:
      'Norsk designtidende er publisert i PDF-format og ordnet etter årstall. Noen av filene er store, og det kan derfor ta litt tid å laste dem ned.',
    year_label: 'Norsk designtidende'
  }
};
