import type { ContinuationToken, KunngjoringFromSearch } from '../../types/entities';

export interface SearchKunngjoringStateByRettighet {
  loading: boolean;
  loaded: boolean;
  error?: string;
  searchResult: KunngjoringFromSearch[];
  paginationPage: number;
  totalHits: number;
  totalPages: number;
  continuationTokens: ContinuationToken[];
}

export const initialSearchKunngjoringStateByRettighet: SearchKunngjoringStateByRettighet = {
  loading: false,
  loaded: false,
  error: undefined,
  searchResult: [],
  paginationPage: 0,
  totalHits: 0,
  totalPages: 0,
  continuationTokens: []
};

export interface SearchKunngjoringState {
  [rettighetstype: string]: SearchKunngjoringStateByRettighet;
}

export const initialState: SearchKunngjoringState = {};
