import { Rettighetstype } from '../../../shared/types/entities';
import { Kunngjoring } from '../../types/entities';

import { KunngjoringState, KunngjoringStateById } from './state';

export const getKunngjoringStateById = (
  kunngjoringState: KunngjoringState,
  publikasjonId?: string,
  kategoriId?: string,
  rettighetsType?: Rettighetstype
): KunngjoringStateById => {
  const kunngjoringStateId = `${rettighetsType}-${publikasjonId}-${kategoriId}`;
  return kunngjoringState[kunngjoringStateId] || { loaded: false, kunngjoringer: [] };
};

export const getKunngjoringer = (
  kunngjoringState: KunngjoringState,
  publikasjonId: string,
  kategoriId: string,
  rettighetsType: Rettighetstype
): Kunngjoring[] => {
  return getKunngjoringStateById(kunngjoringState, publikasjonId, kategoriId, rettighetsType)?.kunngjoringer || [];
};

export const getLoaded = (
  kunngjoringState: KunngjoringState,
  publikasjonId: string,
  kategoriId: string,
  rettighetsType: Rettighetstype
): boolean => {
  return getKunngjoringStateById(kunngjoringState, publikasjonId, kategoriId, rettighetsType)?.loaded || false;
};

export const getError = (
  kunngjoringState: KunngjoringState,
  publikasjonId: string,
  kategoriId: string,
  rettighetsType: Rettighetstype
): string | undefined => {
  return getKunngjoringStateById(kunngjoringState, publikasjonId, kategoriId, rettighetsType)?.error;
};
