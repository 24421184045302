export interface PaginationPublikasjonState {
  currentPage: number;
  kunngjoringerPerPage: number;
  totalNumberPages: number;
}

export const initialState: PaginationPublikasjonState = {
  currentPage: 1,
  kunngjoringerPerPage: 50,
  totalNumberPages: 1
};
