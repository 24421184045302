import { rest } from 'msw';

import varemerkeKunngjoringPublikasjon4 from './varemerke-kunngjoring-get-publikasjon-id-4.json';
import varemerkeKunngjoringRegisteredVaremerke from './varemerke-kunngjoring-get-registered-kunngjoring.json';
import varemerkeKunngjorig from './varemerke-kunngjoring-get.json';

/// <summary>
/// <param name="req">an information about a matching request</param>
/// <param name="res">a functional utility to create the mocked response</param>
/// <param name="ctx">a group of functions that help to set a status code, headers, body, etc. of the mocked response.</param>
/// </summary>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getVaremerkeKunngjoringHandler = (_req: any, res: any, ctx: any) => {
  const kunngjoringType = _req.url.searchParams.get('kunngjoringtype');
  const publikasjonId = _req.url.searchParams.get('publikasjon');
  if (publikasjonId === '4') return res(ctx.status(200), ctx.json(varemerkeKunngjoringPublikasjon4));
  if (kunngjoringType === 'settled') {
    return res(ctx.status(200), ctx.json(varemerkeKunngjoringRegisteredVaremerke));
  }
  return res(ctx.status(200), ctx.json(varemerkeKunngjorig));
};

export const varemerkeKunngjoringHandlers = [
  // Handles a GET /user request
  rest.get(`${process.env.REACT_APP_SERVER_URL}/kunngjoringer`, getVaremerkeKunngjoringHandler),
  rest.put(`${process.env.REACT_APP_SERVER_URL}/kunngjoringer/:kunngjoringtype`, async (req, res, ctx) => {
    return res(ctx.status(200));
  })
];
