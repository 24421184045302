import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';

import { MenuItem, Pagination, Select, SelectChangeEvent } from '@mui/material';
import { PsBox, PsBreadcrumb, PsContainer, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { getPublikasjonStateByRettighet } from '../../../publikasjon/store/publikasjon/selectors';
import { loadPublikasjonsAsync } from '../../../publikasjon/store/publikasjon/service';
import { getCategoryStateByRettighetAndPublikasjonNr } from '../../../publikasjon/store/publikasjon-category/selectors';
import { loadPublikasjonCategoriesAsync } from '../../../publikasjon/store/publikasjon-category/service';
import Loader from '../../../shared/components/Loader';
import TechnicalErrorMessage from '../../../shared/components/TechnicalErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { setScrollPosition } from '../../../shared/store/scroll-position/reducer';
import { formatPublicationName, truncate } from '../../../shared/utils/text-utils';
import { getKunngjoringStateById } from '../../store/kunngjoring/selectors';
import { loadKunngjoringerAsync } from '../../store/kunngjoring/service';
import { setCurrentPage, setKunngjoringerPerPage, setTotalPages } from '../../store/kunngjoring-pagination/reducer';
import { Kunngjoring } from '../../types/entities';
import { sliceKunngjoringer } from '../../utils/utils';
import KunngjoringListFilter from '../KunngjoringListFilter';

import { getFilteredKunngjoringer } from './filter-utils';
import KunngjoringList from './KunngjoringList';

const KunngjoringListScreen: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [filteredKunngjoringer, setFilteredKunngjoringer] = useState<Kunngjoring[]>([]);
  const { id, categoryId } = useParams();
  const rettighetstype = useRettighetstype();

  const categoryState = useAppSelector((gs) => gs.publikasjonCategoryState);
  const publikasjonState = useAppSelector((gs) => gs.publikasjonState);
  const scrollPositionState = useAppSelector((gs) => gs.scrollPositionState);
  const publikasjonStateByRettighet = getPublikasjonStateByRettighet(publikasjonState, rettighetstype);
  const { publikasjoner, loaded: publikasjonerLoaded } = publikasjonStateByRettighet;
  const selectedPublikasjon = publikasjoner.find((x) => x.id === id);

  const categoryStateByPublikasjon = getCategoryStateByRettighetAndPublikasjonNr(categoryState, rettighetstype, id);
  const { content: categories, loaded: categoriesLoaded } = categoryStateByPublikasjon;
  const selectedPubCategory = categories.find((x) => x.categoryId === categoryId);

  const selectedCategory = categories.find((x) => x.categoryId === categoryId);

  const filter = useAppSelector((gs) => gs.kunngjoringFilterState);

  const kunngjoringState = useAppSelector((gs) => gs.kunngjoringState);
  const kunngjoringStateById = getKunngjoringStateById(kunngjoringState, id, categoryId, rettighetstype);
  const { kunngjoringer, loaded: kunngjoringerLoaded, error } = kunngjoringStateById;

  const pagination = useAppSelector((gs) => gs.paginationPublikasjonState);

  const filteredSlicedKunngjoringer = sliceKunngjoringer(
    filteredKunngjoringer,
    pagination.kunngjoringerPerPage,
    pagination.currentPage
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    // This delay ensures that the window scroll action happens after the scroll to top. Do not remove.
    setTimeout(() => {
      window.scroll(0, scrollPositionState.position);
      dispatch(setScrollPosition(0));
    }, 200);
  }, []);

  useEffect(() => {
    const updatedFiltered = getFilteredKunngjoringer(kunngjoringer, filter);
    if (updatedFiltered != filteredKunngjoringer) {
      setFilteredKunngjoringer(updatedFiltered);
      dispatch(setTotalPages(Math.ceil(updatedFiltered.length / pagination.kunngjoringerPerPage)));
    }
  }, [filter, kunngjoringer[0]?.id]);

  useEffect(() => {
    if (!kunngjoringerLoaded) {
      dispatch(loadKunngjoringerAsync(id, categoryId, rettighetstype));
    }
  }, [selectedPublikasjon, rettighetstype]);

  useEffect(() => {
    if (!publikasjonerLoaded) {
      dispatch(loadPublikasjonsAsync(rettighetstype));
    }
  }, []);

  useEffect(() => {
    if (selectedPublikasjon && !categoriesLoaded) {
      dispatch(loadPublikasjonCategoriesAsync(selectedPublikasjon.id, rettighetstype));
    }
  }, [selectedPublikasjon, rettighetstype]);

  const changePageHandler = (event: ChangeEvent<unknown>, page: number) => {
    dispatch(setCurrentPage(page));
    // This delay ensures that the window scroll action happens last of other scroll actions in the code. Do not remove.
    setTimeout(() => {
      window.scroll(0, 0);
    }, 300);
  };

  const handleChangeInKunngjoringerPerPage = (event: SelectChangeEvent<number>) => {
    const updatedKunnjoringerPerPage = event.target.value as number;
    dispatch(setKunngjoringerPerPage(updatedKunnjoringerPerPage));
    dispatch(setTotalPages(Math.ceil(filteredKunngjoringer.length / updatedKunnjoringerPerPage)));
    dispatch(setCurrentPage(1));
  };

  const publicationName = formatPublicationName(t, selectedPublikasjon);

  let categoryName: string | undefined;
  if (!!selectedCategory) {
    categoryName =
      i18n.language === 'no' ? selectedPubCategory?.norwegianDescription : selectedPubCategory?.englishDescription;
  } else {
    categoryName = t('publikasjon.ikkeFunnetTittel');
  }

  return (
    <PsContainer fluid color="primary" pFluid>
      <div>
        {publikasjonerLoaded && categoriesLoaded ? (
          <>
            <PsContainer>
              <PsBreadcrumb>
                <li>
                  <Link to="/">Tidende</Link>
                </li>
                <li>
                  <Link to={`/${rettighetstype}`}>{t(rettighetstype)}</Link>
                </li>
                <li>
                  <Link to={`/${rettighetstype}/publikasjoner`}>{t('publikasjon.publikasjoner')}</Link>
                </li>
                <li>
                  <Link to={`/${rettighetstype}/publikasjoner/${id}`}>{publicationName}</Link>
                </li>
                <li>
                  <Link to="#">{`${truncate(categoryName)}`}</Link>
                </li>
              </PsBreadcrumb>
            </PsContainer>
            <PsContainer htmlMarkup="main">
              <PsBox gutter>
                <PsText type="title1">
                  <span>{categoryName}</span>
                </PsText>
              </PsBox>
              {/* DevNote: <PublikasjonPicker /> har blitt fjernet. 
          * Årsak: Det er ikke gitt at en neste/forrige publikasjon har samme kategori/publikasjonstype.
        <PsBox gutter>
          <PublikasjonPicker />
        </PsBox>
        */}
              {selectedCategory?.categoryId != '1200' && selectedCategory?.categoryId != '0000' && (
                <PsBox>
                  <KunngjoringListFilter category={selectedPubCategory} />
                </PsBox>
              )}
              <PsBox mt={3}>
                <div>
                  {!kunngjoringerLoaded && <Loader />}
                  {error && <TechnicalErrorMessage />}

                  <PsBox flex justifyContent="space-between" alignItems="center" m={1}>
                    <PsText>
                      <span>{t('shared.hits') + ': ' + filteredKunngjoringer.length}</span>
                    </PsText>
                    <Select value={pagination.kunngjoringerPerPage} onChange={handleChangeInKunngjoringerPerPage}>
                      <MenuItem value={20}>20 {t('shared.hitsPerPage')}</MenuItem>
                      <MenuItem value={50}>50 {t('shared.hitsPerPage')}</MenuItem>
                      <MenuItem value={100}>100 {t('shared.hitsPerPage')}</MenuItem>
                    </Select>
                  </PsBox>
                  {kunngjoringerLoaded && !error && (
                    <KunngjoringList rettighetstype={rettighetstype} kunngjoringer={filteredSlicedKunngjoringer} />
                  )}
                  {kunngjoringerLoaded && pagination.totalNumberPages > 1 && (
                    <PsBox flex justifyContent="center" my={3}>
                      <Pagination
                        page={pagination.currentPage}
                        count={pagination.totalNumberPages}
                        color="secondary"
                        onChange={changePageHandler}
                      />
                    </PsBox>
                  )}
                </div>
              </PsBox>
            </PsContainer>
          </>
        ) : (
          <span>
            <Loader />
          </span>
        )}
      </div>
    </PsContainer>
  );
};

export default KunngjoringListScreen;
