import { AxiosResponse } from 'axios';

import { RettighetstypeShort } from '../../../shared/types/enums';
import axiosApi from '../../../shared/utils/axios-api';
import type { ContinuationToken, SakFromSearch } from '../../types/entities';

export class SearchSakHttpRequest {
  public static search(
    continuationToken: ContinuationToken,
    queryParams: string,
    rettighetsType: RettighetstypeShort
  ): Promise<AxiosResponse<SakFromSearch[]>> {
    return axiosApi.get<SakFromSearch[]>(
      `${process.env.REACT_APP_API_URL}/search/sak?${queryParams}&rettighetstype=${rettighetsType}`,
      {
        headers: {
          'x-continuation': continuationToken
        }
      }
    );
  }
  public static countSearch(queryParams: string, rettighetsType: RettighetstypeShort): Promise<AxiosResponse<number>> {
    return axiosApi.get<number>(
      `${process.env.REACT_APP_API_URL}/search/countsearchsak?${queryParams}&rettighetstype=${rettighetsType}`
    );
  }
}
