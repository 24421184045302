import React, { type FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsText } from '@patentstyret/designsystem-react';

import ImageContent from '../../../shared/components/ImageContent';
import { useAppSelector } from '../../../shared/hooks/redux-hooks';
import { styles } from '../../shared/styles/BaseBoxStyles';

const DetailedViewHeader: FunctionComponent = () => {
  const designSakDetails = useAppSelector((gs) => gs.designSakDetailsState.designSakDetails); //TODO

  return (
    <>
      <Box
        sx={{
          ...styles.headerBoxStyle
        }}
      >
        <PsText type="title4">
          {designSakDetails.registrationNumber ? (
            <span>{`${designSakDetails.applicationNumber} (${designSakDetails.registrationNumber})`}</span>
          ) : (
            <span>{`${designSakDetails.applicationNumber}`}</span>
          )}
        </PsText>
        <ImageContent image={designSakDetails.image} hasImage={designSakDetails.hasImage} detailedView />
      </Box>
    </>
  );
};

export default DetailedViewHeader;
