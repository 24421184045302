import React, { useEffect } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PsBox, PsContainer } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import './i18n';

import ShadowEdge from './components/Header/ShadowEdge';
import { getRoutes } from './routes';

type SupportedLanguge = 'no' | 'en';

function App() {
  const { i18n } = useTranslation();

  const onLanguageChangedEvent = (e: Event) => {
    const ce = e as CustomEvent;
    const lang = ce.detail as SupportedLanguge;
    i18n.changeLanguage(lang);
  };

  //ALT WORK-AROUND: ADD EVENT-LISTENER NATIVELY
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.addEventListener('psOnLanguageChanged', onLanguageChangedEvent);
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window?.removeEventListener('psOnLanguageChanged', onLanguageChangedEvent);
    };
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language === 'no' ? 'nb' : 'en-gb'}>
      <PsContainer fluid pFluid color="primary">
        <div>
          <ShadowEdge>
            <ps-header color="secondary" />
          </ShadowEdge>
          {getRoutes()}
          <PsBox my={10} />
          <ps-footer />
        </div>
      </PsContainer>
    </LocalizationProvider>
  );
}

export default App;
