export default {
  publikasjoner: 'Traditional Publications',
  varemerke: {
    title: 'Norwegian Official Trademarks Gazette (Varemerketidende)',
    ingress:
      'The Norwegian Trademarks Official Gazettes are available online in PDF format from the day of publication: Norsk Varemerketidende.',
    info_msg: 'The Norwegian Official Gazette is available in Norwegian only',
    year_label: 'Norwegian Trademark Gazette'
  },
  patent: {
    title: 'Norwegian Official Patent Gazette (Patenttidende)',
    ingress:
      'The Norwegian Patent Official Gazettes are available online in PDF format from the day of publication: Norsk Patenttidende.',
    info_msg: 'The Norwegian Official Gazette is available in Norwegian only',
    year_label: 'Norwegian Patent Gazette'
  },
  design: {
    title: 'Norwegian Official Design Gazette (Patenttidende)',

    ingress:
      'The Norwegian Design Official Gazettes are available online in PDF format from the day of publication: Norsk Designtidende.',
    info_msg: 'The Norwegian Official Gazette is available in Norwegian only',
    year_label: 'Norwegian Design Gazette'
  }
};
