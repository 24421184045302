import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Publikasjon } from '../../types/entities';

import { PublikasjonState, initialState } from './state';

export type PublikasjonSuccessAction = {
  id: string;
  publikasjoner: Publikasjon[];
};

export type PublikasjonFailedAction = {
  id: string;
  error: string;
};

const publikasjonSlice = createSlice({
  name: 'publikasjon',
  initialState,
  reducers: {
    loadSuccess: (state: PublikasjonState, action: PayloadAction<PublikasjonSuccessAction>) => {
      state[action.payload.id] = {
        loaded: true,
        publikasjoner: action.payload.publikasjoner
      };
    },
    loadFailed: (state: PublikasjonState, action: PayloadAction<PublikasjonFailedAction>) => {
      state[action.payload.id] = {
        loaded: true,
        error: action.payload.error,
        publikasjoner: []
      };
    }
  }
});

const { actions, reducer } = publikasjonSlice;
export const { loadSuccess, loadFailed } = actions;
export default reducer;
