import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { KunngjoringType } from '../../../shared/types/enums';
import { SearchCategoryType, SearchType, SearchPeriodType } from '../../types/enums';

import { SearchParamState, initialState } from './state';

export type SetTimePeriodAction = {
  type: SearchPeriodType;
  fromDate?: string;
  toDate?: string;
};

const searchParamSlice = createSlice({
  name: 'search-param',
  initialState,
  reducers: {
    reset: () => initialState,
    setSearchCategory: (state: SearchParamState, action: PayloadAction<SearchCategoryType>) => {
      state.category = action.payload;
    },
    setSearchString: (state: SearchParamState, action: PayloadAction<string>) => {
      state.searchString = action.payload;
      state.searchWithoutSearchString = false;
    },
    setSearchFromDate: (state: SearchParamState, action: PayloadAction<string | undefined>) => {
      state.fromDate = action.payload;
    },
    setSearchToDate: (state: SearchParamState, action: PayloadAction<string | undefined>) => {
      state.toDate = action.payload;
    },
    setTimePeriod: (state: SearchParamState, action: PayloadAction<SetTimePeriodAction>) => {
      state.periodType = action.payload.type;
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
    setSearchKunngjoringType: (state: SearchParamState, action: PayloadAction<KunngjoringType | undefined>) => {
      state.kunngjoringType = action.payload;
    },
    setSearchType: (state: SearchParamState, action: PayloadAction<SearchType>) => {
      state.searchType = action.payload;
    },
    setTimePeriodType: (state: SearchParamState, action: PayloadAction<SearchPeriodType>) => {
      state.periodType = action.payload;
    },
    setShowSearchInfo: (state: SearchParamState, action: PayloadAction<boolean>) => {
      state.showSearchInfo = action.payload;
    },
    setPressedSearchButtonWithoutSearhString: (state: SearchParamState, action: PayloadAction<boolean>) => {
      state.searchWithoutSearchString = action.payload;
    },
    setItemCount: (state: SearchParamState, action: PayloadAction<number>) => {
      state.itemCount = action.payload;
    }
  }
});

const { actions, reducer } = searchParamSlice;
export const {
  reset,
  setSearchCategory,
  setSearchString,
  setSearchType,
  setTimePeriodType,
  setSearchFromDate,
  setSearchToDate,
  setTimePeriod,
  setSearchKunngjoringType,
  setShowSearchInfo,
  setPressedSearchButtonWithoutSearhString,
  setItemCount
} = actions;
export default reducer;
