import React, { FunctionComponent, useEffect } from 'react';

import { Badge, Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PublikasjonCategory } from '../../../publikasjon/types/entities';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { changefilterSubCategories, setListOfSubCategories } from '../../store/kunngjoring-filter/reducer';
import { setCurrentPage } from '../../store/kunngjoring-pagination/reducer';
import { FilterCheckboxValue } from '../../types/entities';

interface Props {
  category: PublikasjonCategory;
}

const SelectSubCategory: FunctionComponent<Props> = ({ category: selectedCategory }: Props) => {
  const { i18n, t } = useTranslation();
  const filterState = useAppSelector((gs) => gs.kunngjoringFilterState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setListOfSubCategories(selectedCategory.categoryId));
  }, [selectedCategory]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const updatedFilterSubCategories = [...filterState.filterSubCategories];
    const index = updatedFilterSubCategories.findIndex(
      (subCategory) => subCategory.optionValue.id == event.target.value.toString()
    );
    updatedFilterSubCategories[index] = {
      checked: !filterState.filterSubCategories[index].checked,
      optionValue: filterState.filterSubCategories[index].optionValue
    };
    dispatch(changefilterSubCategories(updatedFilterSubCategories));
    dispatch(setCurrentPage(1));
  };

  const calculateNumberOfOptionsChecked = () => {
    let counter = 0;
    filterState.filterSubCategories.forEach((subCategory) => {
      if (subCategory.checked == true) counter++;
    });
    return counter;
  };

  return (
    <Badge badgeContent={calculateNumberOfOptionsChecked()} color="primary" sx={{ alignSelf: 'center' }}>
      <Select
        color="secondary"
        multiple
        value={[]}
        onChange={handleChange}
        displayEmpty={true}
        sx={{ height: 'fit-content', alignSelf: 'center', width: 'fit-content' }}
        renderValue={() => <Typography>{t('kunngjoring.eventType')}</Typography>}
      >
        {filterState.filterSubCategories.map((subCategory: FilterCheckboxValue) => (
          <MenuItem key={subCategory.optionValue.id} value={subCategory.optionValue.id}>
            <Checkbox color="secondary" checked={subCategory.checked} />
            <ListItemText primary={i18n.language === 'no' ? subCategory.optionValue.no : subCategory.optionValue.en} />
          </MenuItem>
        ))}
      </Select>
    </Badge>
  );
};

export default SelectSubCategory;
