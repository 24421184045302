import kunngjoringNo from '../../modules/kunngjoring/locales/no';
import legacyNo from '../../modules/legacy/locales/no';
import publikasjonNo from '../../modules/publikasjon/locales/no';
import sakNo from '../../modules/sak/locales/no';
import searchNo from '../../modules/search/locales/no';
import sharedNo from '../../modules/shared/locales/no';

export default {
  translation: {
    tidende: 'Tidende',
    tidende_subtitle:
      'Tidende er en publikasjon som inneholder meddelelser om offentliggjorte rettigheter som er søkt i Norge innenfor patent, varemerke og designområdet.',
    varemerke_subtitle:
      'Norsk varemerketidende er en publikasjon som inneholder kunngjøringer innenfor varemerkeområdet.',
    design_subtitle: 'Norsk designtidende er en publikasjon som inneholder kunngjøringer innenfor designområdet.',
    patent_subtitle: 'Norsk patenttidende er en publikasjon som inneholder kunngjøringer innenfor patentområdet.',
    tidende_varemerke: 'Norsk varemerketidende',
    tidende_patent: 'Norsk patenttidende',
    tidende_design: 'Norsk designtidende',
    loadingFailedError: 'Her skjedde det noe galt. Det gikk derfor ikke an til å laste inn innholdet.',
    varemerke: 'Varemerke',
    design: 'Design',
    patent: 'Patent',
    aboutTidende: 'Om tidende',
    publications: 'Periodiske publikasjoner',
    legacyPublications: 'Periodiske publikasjoner (PDF)',
    headerSearch: 'Søk i kunngjøringer og saker',
    cases: 'Saker',
    events: 'Kunngjøringer',
    publikasjon: publikasjonNo,
    kunngjoring: kunngjoringNo,
    sak: sakNo,
    shared: sharedNo,
    search: searchNo,
    legacy: legacyNo,
    nye_tjenester: 'Kunngjøringer',
    tradisjonelle_tjenester: 'Historiske kunngjøringer',
    fromDate: 'Fra og med uke 10-2024',
    toDate: 'Til og med uke 9-2024',
    disclaimer: 'Forbehold',
    link_tidendeInfo: 'Les mer om tidende her'
  }
};
