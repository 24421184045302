import { RettighetstypeShort } from '../types/enums';

export const mapRettighetstypeShort = (rettighetstype: 'varemerke' | 'design' | 'patent'): RettighetstypeShort => {
  switch (rettighetstype) {
    case 'varemerke':
      return RettighetstypeShort.Varemerke;
    case 'design':
      return RettighetstypeShort.Design;
    case 'patent':
      return RettighetstypeShort.Patent;
  }
};
