import React, { FunctionComponent, useState } from 'react';

import { PsBox, PsButton } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { BlobContainer } from '../../../search/types/enums';
import DownloadErrorMessage from '../../../shared/components/DownloadErrorMessage';
import axiosApi from '../../../shared/utils/axios-api';

interface Props {
  attachmentUrl: string;
  sakId: string;
}

const DownloadAttachment: FunctionComponent<Props> = ({ attachmentUrl, sakId }: Props) => {
  const { t } = useTranslation();
  const [downloadError, setDownloadError] = useState(false);

  const getAttachment = () => {
    const url = `${process.env.REACT_APP_API_URL}/blob/DownloadAttachment`;
    const blobContainer = BlobContainer.document;
    return axiosApi.post<Blob>(
      url,
      { filename: attachmentUrl, blobContainer },
      {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'blob'
      }
    );
  };

  const createDownloadLink = (data: Blob) => {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(data);
    link.href = url;

    const fileEnding = attachmentUrl.substring(attachmentUrl.lastIndexOf('.') + 1);

    link.setAttribute('download', `attachment${sakId}.${fileEnding}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAttachment = async () => {
    getAttachment()
      .then((response) => {
        createDownloadLink(response.data);
        setDownloadError(false);
      })
      .catch(() => {
        setDownloadError(true);
      });
  };

  return (
    <>
      <PsButton color="primary-o" onClick={downloadAttachment}>
        <span>{t('kunngjoring.downloadAttachment')}</span>
      </PsButton>
      {downloadError && (
        <PsBox m={1}>
          <DownloadErrorMessage />
        </PsBox>
      )}
    </>
  );
};

export default DownloadAttachment;
