import { AppThunk } from '../../../../core/store';
import { Rettighetstype } from '../../../shared/types/entities';
import { mapRettighetstypeShort } from '../../../shared/utils/rettighetstype-utils';
import { createQueryParams } from '../../utils/query-param-utils';
import { SearchParamState } from '../search-param/state';

import { SearchSakHttpRequest } from './http-request';
import { resetSearchSak, setLoading, loadSuccess, loadFailed, loadTotalHits } from './reducer';
import { getSearchSakStateByRettighet } from './selectors';
import { SearchSakState } from './state';

export const sakSearchAsync = (
  rettighetstype: Rettighetstype,
  searchParams: SearchParamState,
  itemCount: number
): AppThunk => {
  return (dispatch) => {
    dispatch(resetSearchSak({ id: rettighetstype, loading: true }));
    const rettighetstypeShort = mapRettighetstypeShort(rettighetstype);
    const queryParams = createQueryParams(searchParams, itemCount);
    SearchSakHttpRequest.countSearch(queryParams, rettighetstypeShort).then((response) => {
      const totalHits = response.data;
      const totalPages = Math.ceil(response.data / itemCount);
      dispatch(
        loadTotalHits({
          id: rettighetstype,
          totalHits: totalHits,
          totalPages: totalPages
        })
      );
    });
    SearchSakHttpRequest.search(null, queryParams, rettighetstypeShort)
      .then((response) => {
        const continuationToken = response.headers['x-continuation'];

        dispatch(
          loadSuccess({
            id: rettighetstype,
            continuationToken,
            searchResult: response.data,
            paginationPage: 0
          })
        );
      })
      .catch((error) => {
        dispatch(loadFailed({ id: rettighetstype, error: error.toString() }));
      });
  };
};

export const paginateSearchResultAsync = (
  rettighetstype: Rettighetstype,
  direction: 'forward' | 'backward',
  searchSakState: SearchSakState,
  searchParamState: SearchParamState
): AppThunk => {
  return (dispatch) => {
    dispatch(setLoading({ id: rettighetstype, loading: true }));
    const searchSakStateByRettighet = getSearchSakStateByRettighet(searchSakState, rettighetstype);
    const paginationPage = searchSakStateByRettighet.paginationPage;
    const nextPaginationPage = direction === 'forward' ? paginationPage + 1 : paginationPage - 1;
    let continuationToken = null;
    if (nextPaginationPage > 0) {
      continuationToken = searchSakStateByRettighet.continuationTokens[nextPaginationPage - 1];
    }
    const rettighetstypeShort = mapRettighetstypeShort(rettighetstype);
    const queryParams = createQueryParams(searchParamState, searchParamState.itemCount);
    SearchSakHttpRequest.search(continuationToken, queryParams, rettighetstypeShort)
      .then((response) => {
        const continuationToken = response.headers['x-continuation'];
        dispatch(
          loadSuccess({
            id: rettighetstype,
            continuationToken,
            searchResult: response.data,
            paginationPage: nextPaginationPage
          })
        );
      })
      .catch((error) => {
        dispatch(loadFailed({ id: rettighetstype, error: error.toString() }));
      });
  };
};
