import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsLink, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../shared/hooks/redux-hooks';
import { setScrollPosition } from '../../../../shared/store/scroll-position/reducer';
import { formatApplicantOrHolder, formatParticipant } from '../../../../shared/utils/text-utils';
import { formatJsonTimeString, manualTranslation } from '../../../../shared/utils/translation';
import { baseStyles } from '../../../styles/BaseBoxStyles';
import { DesignKunngjoringDetails } from '../../../types/entities';

interface Props {
  details: DesignKunngjoringDetails;
}

const DesignInfo: FunctionComponent<Props> = ({ details }: Props) => {
  const { t, i18n } = useTranslation();
  const sakDetailsLink = `/design/sak/${details.sakIdentification}`;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <>
      <PsBox color="primary" severity="success" py={0.5} pl={2} flex flexDirection="row" alignItems="center">
        <PsText
          type="title4"
          onClick={() => {
            dispatch(setScrollPosition(0));
            navigate(sakDetailsLink);
          }}
        >
          {' '}
          <PsLink>
            <span>{t('kunngjoring.sakDetails')}</span>
          </PsLink>
        </PsText>
      </PsBox>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <Box
          sx={{
            flex: 1,
            marginRight: { xs: 0 },
            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;'
          }}
        >
          <PsBox p={2}>
            <PsBox flex justifyContent="space-between" pb={0.5}>
              <PsText type="bold">
                <span>{t('kunngjoring.received')}: </span>
              </PsText>
              <PsText>
                <span>{formatJsonTimeString(details.designDetails.applicationDate)}</span>
              </PsText>
            </PsBox>
            <PsBox flex justifyContent="space-between" pb={0.5}>
              <PsText type="bold">
                <span>{t('shared.registered')}: </span>
              </PsText>
              <PsText>
                <span>{formatJsonTimeString(details.designDetails.registrationDate)}</span>
              </PsText>
            </PsBox>
            <PsBox flex justifyContent="space-between">
              <PsText type="bold">
                <span>{t('kunngjoring.generalAvailable')}: </span>
              </PsText>
              <PsText>
                <span>{formatJsonTimeString(details.opiDate)}</span>
              </PsText>
            </PsBox>
          </PsBox>
        </Box>

        <Box sx={{ flex: 2.5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            {formatApplicantOrHolder(t, details.designDetails.proprietors, details.designDetails.applicants)}
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            <PsText type="bold">
              <span>
                {details.designDetails.designers?.length > 1 ? t('kunngjoring.designers') : t('kunngjoring.designer')}:
              </span>
            </PsText>
            <PsBox ml={1} flex flexDirection="column">
              {details.designDetails.designers?.map((item) => (
                <PsText key={item.name}>
                  <span>{formatParticipant(item)}</span>
                </PsText>
              ))}
            </PsBox>
          </Box>
          {details.designDetails.agent && (
            <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
              <PsText type="bold">
                <span>{t('shared.representative')}:</span>
              </PsText>
              <PsBox ml={1}>
                <PsText>
                  <span>{formatParticipant(details.designDetails.agent)}</span>
                </PsText>
              </PsBox>
            </Box>
          )}
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            <PsText type="bold">
              <span>{t('kunngjoring.currentStatus')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{manualTranslation(i18n, details.designDetails.currentStatus)}</span>
                <span>{` (${formatJsonTimeString(details.designDetails.currentStatusDate)})`}</span>
              </PsText>
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            <PsText type="bold">
              <span>{t('kunngjoring.priority')}:</span>
            </PsText>
            <PsBox flex flexDirection="column">
              <PsBox ml={1}>
                {details.designDetails.priorities?.map((item) => (
                  <PsText key={item}>
                    <span>{item}</span>
                  </PsText>
                ))}
              </PsBox>
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            <PsText type="bold">
              <span>{t('kunngjoring.products')}:</span>
            </PsText>
            <PsBox ml={1}>
              {details.designDetails.productTitles.map((item) => (
                <PsText key={item}>
                  <span>{`${item} `}</span>
                </PsText>
              ))}
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart, marginBottom: { xs: 1, sm: 0 } }}>
            <PsText type="bold">
              <span>{t('kunngjoring.classes')}:</span>
            </PsText>
            <PsBox ml={1}>
              {details.designDetails.locarnoClasses.map((item) => (
                <PsText key={item}>
                  <span>{item}</span>
                </PsText>
              ))}
            </PsBox>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DesignInfo;
