import React, { FunctionComponent } from 'react';

import { PsAlert } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { Rettighetstype } from '../../../shared/types/entities';
import { ApplicationType, Kunngjoring } from '../../types/entities';
import DesignKunngjoringListItem from '../KunngjoringListItem/DesignKunngjoringListItem';
import PatentKunngjoringListItem from '../KunngjoringListItem/PatentKunngjoringListItem';
import VaremerkeKunngjoringListItem from '../KunngjoringListItem/VaremerkeKunngjoringListItem';
import KunngjoringNotConntectedToCaseItem from '../KunngjoringNotConnectedToCaseItem';

interface Props {
  rettighetstype: Rettighetstype;
  kunngjoringer: Kunngjoring[];
}

const KunngjoringList: FunctionComponent<Props> = ({ rettighetstype, kunngjoringer }: Props) => {
  const { t } = useTranslation();

  let arr = [];
  switch (rettighetstype) {
    case 'varemerke':
      arr = kunngjoringer.map((x) => {
        if (x.applicationType == ApplicationType.None) {
          return <KunngjoringNotConntectedToCaseItem key={x.id} kunngjoring={x} />;
        } else {
          return <VaremerkeKunngjoringListItem key={x.id} kunngjoring={x} />;
        }
      });
      break;
    case 'patent':
      arr = kunngjoringer.map((x) => {
        if (x.applicationType == ApplicationType.None) {
          return <KunngjoringNotConntectedToCaseItem key={x.id} kunngjoring={x} />;
        } else {
          return <PatentKunngjoringListItem key={x.id} kunngjoring={x} />;
        }
      });
      break;
    case 'design':
      arr = kunngjoringer.map((x) => {
        if (x.applicationType == ApplicationType.None) {
          return <KunngjoringNotConntectedToCaseItem key={x.id} kunngjoring={x} />;
        } else {
          return <DesignKunngjoringListItem key={x.id} kunngjoring={x} />;
        }
      });
      break;
  }

  if (arr.length > 0) {
    return <div>{arr}</div>;
  }

  return (
    <PsAlert severity="info">
      <span>{t('kunngjoring.emptyVaremerkeKunngjoringer')}</span>
    </PsAlert>
  );
};

export default KunngjoringList;
