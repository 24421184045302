import React from 'react';

import { Typography, TypographyProps, useTheme } from '@mui/material';

interface Props extends TypographyProps {
  callback: () => void;
}

const ClickableTypography = ({ callback, children, sx, ...otherProps }: Props) => {
  const theme = useTheme();
  return (
    <Typography
      tabIndex={0}
      onClick={callback}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          callback();
        }
      }}
      {...otherProps}
      sx={{
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
          color: theme.palette.primary.main
        },
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};

export default ClickableTypography;
