import React, { FunctionComponent } from 'react';

import { PublikasjonCategory } from '../../../../publikasjon/types/entities';
import { PatentKunngjoringDetails } from '../../../types/entities';
import CategorySpecificEventDetails from '../CategorySpecificEventDetails';

import DetailedViewHeader from './DetailedViewHeader';
import PatentInfo from './PatentInfo';

interface Props {
  kunngjoringDetails: PatentKunngjoringDetails;
  category?: PublikasjonCategory;
}

const KunngjoringPatentPanel: FunctionComponent<Props> = ({ category, kunngjoringDetails }: Props) => {
  return (
    <>
      <DetailedViewHeader category={category} details={kunngjoringDetails} />
      <CategorySpecificEventDetails details={kunngjoringDetails} />
      <PatentInfo details={kunngjoringDetails} />
    </>
  );
};

export default KunngjoringPatentPanel;
