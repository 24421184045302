import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PublikasjonCategory } from '../../types/entities';

import { CategoryState, initialState } from './state';

export type CategorySuccessAction = {
  id: string;
  content: PublikasjonCategory[];
};

export type CategoryFailedAction = {
  id: string;
  error: string;
};

export type PublikasjonDownloadFailedAction = {
  id: string;
  fileDownloadError: string;
};

const publikasjonCategorySlice = createSlice({
  name: 'publikasjon-category',
  initialState,
  reducers: {
    loadSuccess: (state: CategoryState, action: PayloadAction<CategorySuccessAction>) => {
      state[action.payload.id] = {
        loaded: true,
        content: action.payload.content
      };
    },
    loadFailed: (state: CategoryState, action: PayloadAction<CategoryFailedAction>) => {
      state[action.payload.id] = {
        loaded: true,
        error: action.payload.error,
        content: []
      };
    },
    loadFileDownloadError: (state: CategoryState, action: PayloadAction<PublikasjonDownloadFailedAction>) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        fileDownloadError: action.payload.fileDownloadError
      };
    }
  }
});

const { actions, reducer } = publikasjonCategorySlice;
export const { loadSuccess, loadFailed, loadFileDownloadError } = actions;
export default reducer;
