import { FilterState } from '../../store/kunngjoring-filter/state';
import { FilterCheckboxValue } from '../../types/entities';
import { ApplicationType, Kunngjoring } from '../../types/entities';

export const getFilteredKunngjoringer = (kunngjoringer: Kunngjoring[], filter: FilterState) => {
  if (kunngjoringer.length === 0) {
    return kunngjoringer;
  }

  if (filter.selectedAgentFilter) {
    kunngjoringer = filterByAgentProprietorOrApplicant(kunngjoringer, filter);
  }
  kunngjoringer = filterByApplicationType(kunngjoringer, filter);
  kunngjoringer = filterBySubCategories(kunngjoringer, filter.filterSubCategories);
  kunngjoringer = filterByMarkCategory(kunngjoringer, filter.filterMarkType);

  return kunngjoringer;
};

const filterBySubCategories = (kunngjoringer: Kunngjoring[], filterSubCategories?: FilterCheckboxValue[]) => {
  const listOfSubCategoryIds: string[] = [];
  if (filterSubCategories != null) {
    for (const key in filterSubCategories) {
      if (filterSubCategories[key].checked) {
        listOfSubCategoryIds.push(filterSubCategories[key].optionValue.id);
      }
    }
  }
  if (listOfSubCategoryIds.length > 0) {
    kunngjoringer = kunngjoringer.filter((kunngjoring) =>
      filterKunngjoringerBySubCategory(kunngjoring, listOfSubCategoryIds)
    );
  }
  return kunngjoringer;
};

const filterKunngjoringerBySubCategory = (kunngjoring: Kunngjoring, filteredSubCategoryIds: string[]) => {
  let exists = false;
  for (const filteredSubCategoryId of filteredSubCategoryIds) {
    if (kunngjoring.subCategoryId != null && kunngjoring.subCategoryId.substring(0, 4) == filteredSubCategoryId) {
      exists = true;
    }
  }
  return exists;
};

const filterByMarkCategory = (kunngjoringer: Kunngjoring[], filterMarkCategory?: FilterCheckboxValue[]) => {
  const listOfMarkCategoryIds: string[] = [];
  if (filterMarkCategory != null) {
    for (const key in filterMarkCategory) {
      if (filterMarkCategory[key].checked) {
        listOfMarkCategoryIds.push(filterMarkCategory[key].optionValue.id);
      }
    }
  }
  if (listOfMarkCategoryIds.length > 0) {
    kunngjoringer = kunngjoringer.filter((kunngjoring) =>
      filterKunngjoringerByMarkType(kunngjoring, listOfMarkCategoryIds)
    );
  }
  return kunngjoringer;
};

const filterKunngjoringerByMarkType = (kunngjoring: Kunngjoring, filteredSubCategoryIds: string[]) => {
  let exists = false;
  for (const filteredSubCategoryId of filteredSubCategoryIds) {
    if (
      kunngjoring.sakDetails.markCategory != null &&
      kunngjoring.sakDetails.markCategory.token == filteredSubCategoryId
    ) {
      exists = true;
    }
  }
  return exists;
};

const filterByApplicationType = (kunngjoringer: Kunngjoring[], filter: FilterState) => {
  const internationalValues = [
    ApplicationType.TMInternational,
    ApplicationType.DSInternational,
    ApplicationType.PTEP,
    ApplicationType.PTPCT
  ];

  const nationalValues = [
    ApplicationType.DSNational,
    ApplicationType.TMNational,
    ApplicationType.PTNational,
    ApplicationType.PTSPC
  ];

  // FilterApplicatType[0] is national and filterApplicationType[1] is international
  if (filter.filterApplicationType[1].checked && !filter.filterApplicationType[0].checked) {
    kunngjoringer = kunngjoringer.filter((x) => internationalValues.includes(x.applicationType));
  } else if (filter.filterApplicationType[0].checked && !filter.filterApplicationType[1].checked) {
    kunngjoringer = kunngjoringer.filter((x) => nationalValues.includes(x.applicationType));
  }

  return kunngjoringer;
};

const filterByAgentProprietorOrApplicant = (kunngjoringer: Kunngjoring[], filter: FilterState) => {
  const selectedAgentLowerCase = filter.selectedAgentFilter.toLowerCase();
  kunngjoringer = kunngjoringer.filter(
    (kunngjoring) =>
      kunngjoring.sakDetails.agent?.name?.toLowerCase().includes(selectedAgentLowerCase) ||
      kunngjoring.sakDetails.applicants?.some((participant) =>
        participant.name?.toLowerCase().includes(selectedAgentLowerCase)
      ) ||
      kunngjoring.sakDetails.proprietors?.some((participant) =>
        participant.name?.toLowerCase().includes(selectedAgentLowerCase)
      )
  );

  return kunngjoringer;
};
