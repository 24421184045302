import { PublikasjonCategory } from '../../types/entities';

export interface CategoryStateByPublikasjon {
  loaded: boolean;
  error?: string;
  content: PublikasjonCategory[];
  fileDownloadError?: string;
}

export interface CategoryState {
  [id: string]: CategoryStateByPublikasjon;
}

export const initialState: CategoryState = {};
