import { AppThunk } from '../../../../core/store';

import { KunngjoringDetailsHttpRequest } from './http-request';
import { loadSuccess, loadFailed } from './reducer';

export const loadVaremerkeKunngjoringDetailsAsync = (kunngjoringId: string, sakId?: string): AppThunk => {
  return (dispatch) => {
    KunngjoringDetailsHttpRequest.getVaremerkeKunngjoringDetails(kunngjoringId, sakId)
      .then((response) => {
        dispatch(loadSuccess({ id: kunngjoringId, details: response.data }));
      })
      .catch((error) => {
        dispatch(loadFailed({ id: kunngjoringId, error: error.toString() }));
      });
  };
};
