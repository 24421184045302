import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsLink, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { formatJsonTimeString, manualTranslation } from '../../../shared/utils/translation';
import { KunngjoringFromSearch } from '../../types/entities';

interface Props {
  searchKunngjoring: KunngjoringFromSearch;
}

const MainContentCard: FunctionComponent<Props> = ({ searchKunngjoring }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 5,
        overflow: 'hidden',
        textOverflow: 'nowrap',
        flex: 1
      }}
    >
      <PsBox flex flexDirection="row">
        <PsBox>
          <PsText>
            <span>
              {searchKunngjoring.applicationNumber}{' '}
              {searchKunngjoring.registrationNumber && `(${searchKunngjoring.registrationNumber})`}
            </span>
          </PsText>
        </PsBox>
      </PsBox>
      <PsBox flex alignItems="center" mb={2}>
        <PsLink>
          <PsText>
            <span>{manualTranslation(i18n, searchKunngjoring.highLevelDescription)}.</span>
          </PsText>
          <PsText>
            <span>{manualTranslation(i18n, searchKunngjoring.subLevelDescription)}</span>
          </PsText>
        </PsLink>
      </PsBox>
      <PsBox>
        <PsText>
          <span>{searchKunngjoring.legalStatusCode}</span>
        </PsText>
        <PsBox flex flexDirection="row">
          <PsText type="bold">
            <span>{t('search.kunngjoringDate')}:</span>
          </PsText>
          <PsBox ml={1}>
            <PsText>
              <span>{formatJsonTimeString(searchKunngjoring.publicationDate)}</span>
            </PsText>
          </PsBox>
        </PsBox>
        <PsBox flex flexDirection="row">
          <PsText type="bold">
            <span>{t('search.gazetteNumber')}:</span>
          </PsText>
          <PsBox ml={1}>
            <PsText>
              <span>{searchKunngjoring.gazetteNumber}</span>
            </PsText>
          </PsBox>
        </PsBox>
      </PsBox>
    </Box>
  );
};

export default MainContentCard;
