import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsLink, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../shared/hooks/redux-hooks';
import { setScrollPosition } from '../../../../shared/store/scroll-position/reducer';
import { formatApplicantOrHolder, formatAuthorization, formatParticipant } from '../../../../shared/utils/text-utils';
import { formatJsonTimeString, manualTranslation } from '../../../../shared/utils/translation';
import { baseStyles } from '../../../styles/BaseBoxStyles';
import { PatentKunngjoringDetails } from '../../../types/entities';

interface Props {
  details: PatentKunngjoringDetails;
}

const PatentInfo: FunctionComponent<Props> = ({ details }: Props) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sakDetailsLink = `/patent/sak/${details.sakIdentification}`;
  const spcBasicPatentLink = `/patent/sak/${details.patentDetails.spcData?.basicPatentCaseIdentification}`;

  return (
    <>
      <PsBox color="primary" severity="success" py={0.5} pl={2} flex flexDirection="row" alignItems="center">
        <PsText
          type="title4"
          onClick={() => {
            dispatch(setScrollPosition(0));
            navigate(sakDetailsLink);
          }}
        >
          {' '}
          <PsLink>
            <span>{t('kunngjoring.sakDetails')}</span>
          </PsLink>
        </PsText>
      </PsBox>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <Box
          sx={{
            marginRight: { xs: 0 },
            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;',
            flex: 1
          }}
        >
          <PsBox p={2}>
            <PsBox flex justifyContent="space-between" pb={0.5}>
              <PsText type="bold">
                <span>{t('kunngjoring.received')}: </span>
              </PsText>
              <PsText>
                <span>{formatJsonTimeString(details.patentDetails.applicationDate)}</span>
              </PsText>
            </PsBox>
            <PsBox flex justifyContent="space-between" pb={0.5}>
              <PsText type="bold">
                <span>{t('shared.granted')}: </span>
              </PsText>
              <PsText>
                <span>{formatJsonTimeString(details.patentDetails.registrationDate)}</span>
              </PsText>
            </PsBox>
            <PsBox flex justifyContent="space-between">
              <PsText type="bold">
                <span>{t('kunngjoring.generalAvailable')}: </span>
              </PsText>
              <PsText>
                <span>{formatJsonTimeString(details.opiDate)}</span>
              </PsText>
            </PsBox>
          </PsBox>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 2.5 }}>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            {formatApplicantOrHolder(t, details.patentDetails.proprietors, details.patentDetails.applicants)}
          </Box>
          {details.patentDetails.inventors && (
            <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
              <PsText type="bold">
                <span>
                  {details.patentDetails.inventors?.length > 1 ? t('kunngjoring.inventors') : t('kunngjoring.inventor')}
                  :
                </span>
              </PsText>
              <PsBox ml={1} flex flexDirection="column">
                {details.patentDetails.inventors?.map((item) => (
                  <PsText key={item.name}>
                    <span>{formatParticipant(item)}</span>
                  </PsText>
                ))}
              </PsBox>
            </Box>
          )}
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            <PsText type="bold">
              <span>{t('kunngjoring.currentStatus')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{manualTranslation(i18n, details.patentDetails.currentStatus)}</span>
                <span>{` (${formatJsonTimeString(details.patentDetails.currentStatusDate)})`}</span>
              </PsText>
            </PsBox>
          </Box>
          {details.patentDetails.priorities && (
            <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
              <PsText type="bold">
                <span>{t('kunngjoring.priority')}:</span>
              </PsText>
              <PsBox ml={1} flex flexDirection="column">
                <PsBox ml={1}>
                  {details.patentDetails.priorities?.map((item) => (
                    <PsText key={item}>
                      <span>{item}</span>
                    </PsText>
                  ))}
                </PsBox>
              </PsBox>
            </Box>
          )}
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            <PsText type="bold">
              <span>{t('shared.representative')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span> {formatParticipant(details.patentDetails.agent)}</span>
              </PsText>
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart, marginBottom: { xs: 1, sm: 0 } }}>
            <PsText type="bold">
              <span>{t('kunngjoring.title')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{details.patentDetails.title}</span>
              </PsText>
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart, marginBottom: { xs: 1, sm: 0 } }}>
            <PsText type="bold">
              <span>{t('kunngjoring.ipcClass')}:</span>
            </PsText>
            <PsBox ml={1}>
              {details.patentDetails.ipcClasses_Gransk?.map((item) => (
                <PsText key={item}>
                  <span>{item}</span>
                </PsText>
              ))}
            </PsBox>
          </Box>
          {details.patentDetails.spcData?.productIdentifier && (
            <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
              <PsBox mr={1}>
                <PsText type="bold">
                  <span>{t('kunngjoring.spc.productIndentifier')}:</span>
                </PsText>
              </PsBox>
              <PsText>
                <span>{details.patentDetails.spcData?.productIdentifier}</span>
              </PsText>
            </Box>
          )}
          {details.patentDetails.spcData?.productFirstAuthorization && (
            <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
              <PsBox mr={1}>
                <PsText type="bold">
                  <span>{t('kunngjoring.spc.authorizationNorway')}:</span>
                </PsText>
              </PsBox>
              <PsText>
                <span>{formatAuthorization(details.patentDetails.spcData?.productFirstAuthorization)}</span>
              </PsText>
            </Box>
          )}
          {details.patentDetails.spcData?.productEuropeanAuthorization && (
            <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
              <PsBox mr={1}>
                <PsText type="bold">
                  <span>{t('kunngjoring.spc.authorizationEAA')}:</span>
                </PsText>
              </PsBox>
              <PsText>
                <span>{formatAuthorization(details.patentDetails.spcData?.productEuropeanAuthorization)}</span>
              </PsText>
            </Box>
          )}
          {details.patentDetails.spcData?.basicPatentCaseIdentification && (
            <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
              <PsBox mr={1}>
                <PsText type="bold">
                  <span>{t('kunngjoring.spc.basicPatent')}:</span>
                </PsText>
              </PsBox>
              <PsText
                onClick={() => {
                  navigate(spcBasicPatentLink);
                }}
              >
                <PsLink>
                  <span>{details.patentDetails.spcData.basicPatentCaseIdentification.substring(2)}</span>
                </PsLink>
              </PsText>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PatentInfo;
