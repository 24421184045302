import React, { FunctionComponent } from 'react';

import { Box, useTheme } from '@mui/material';
import { PsBox, PsLink, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ImageContent from '../../../shared/components/ImageContent';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { formatParticipant } from '../../../shared/utils/text-utils';
import { formatJsonTimeString, manualTranslation } from '../../../shared/utils/translation';
import { Kunngjoring } from '../../types/entities';

interface Props {
  kunngjoring: Kunngjoring;
}

const KunngjoringNotConntectedToCaseItem: FunctionComponent<Props> = ({ kunngjoring }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const rettighetstype = useRettighetstype();
  const kunngjoringDetailsLink = `/${rettighetstype}/kunngjoring/${kunngjoring.sakIdentification}/${kunngjoring.id}`;

  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          height: 'max-content',
          alignItems: 'center',
          borderBottom: `4px solid ${theme.palette.moseGrønn.main}`,
          '&:first-of-type': {
            borderTop: `4px solid ${theme.palette.moseGrønn.main}`
          },
          padding: 2,
          boxSizing: 'border-box',
          whiteSpace: 'initial',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        (kunngjoring.categoryId === '1200' || kunngjoring.categoryId == '0400') && {
          '&:hover, &:focus-visible': {
            background: theme.palette.primary.light,
            cursor: 'pointer'
          }
        }
      ]}
      onClick={() => {
        if (
          kunngjoring.categoryId === '1200' ||
          kunngjoring.categoryId == '0400' ||
          kunngjoring.subCategoryId == '0501b'
        ) {
          navigate(kunngjoringDetailsLink);
        }
      }}
    >
      {kunngjoring.genericPublishingData?.image?.miniature && (
        <ImageContent hasImage image={kunngjoring.genericPublishingData.image} />
      )}
      <PsBox flex flexDirection="column" justifyContent="flex-start">
        {kunngjoring.genericPublishingData?.identification && kunngjoring.categoryId == '1200' && (
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'start'
            }}
          >
            <PsText>
              <PsLink>
                <span>{kunngjoring.genericPublishingData?.identification}</span>
              </PsLink>
            </PsText>
          </Box>
        )}
        {(kunngjoring.categoryId == '0400' || kunngjoring.subCategoryId == '0501b') && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'start',
                flexDirection: 'column',
                mb: 1
              }}
            >
              <PsText>
                <PsLink>
                  <span>{t('kunngjoring.cnComplaintTitle')}</span>
                </PsLink>
              </PsText>
              <PsText type="bold">
                <span>
                  {manualTranslation(i18n, kunngjoring.highLevelDescription)}
                  {'. '}
                  {manualTranslation(i18n, kunngjoring.subLevelDescription)}.
                </span>
              </PsText>
            </Box>
            {kunngjoring.cnComplaintCompanyName && (
              <PsBox flex flexDirection="row">
                <PsText type="bold">
                  <span>{t('kunngjoring.companyName')}:</span>
                </PsText>
                <PsBox ml={1}>
                  <PsText>
                    <span>{kunngjoring.cnComplaintCompanyName}</span>
                  </PsText>
                </PsBox>
              </PsBox>
            )}
            <PsBox flex flexDirection="row">
              <PsText type="bold">
                <span>
                  {kunngjoring.basicRecordClaimant.length > 1 ? t('kunngjoring.claimants') : t('kunngjoring.claimant')}:
                </span>
              </PsText>
              {kunngjoring.basicRecordClaimant.map((claimant) => (
                <PsBox key={claimant.name} ml={1}>
                  <PsText>
                    <span>{formatParticipant(claimant)}</span>
                  </PsText>
                </PsBox>
              ))}
            </PsBox>
            <PsBox flex flexDirection="row">
              <PsText type="bold">
                <span>{t('kunngjoring.received')}:</span>
              </PsText>
              <PsBox ml={1}>
                <PsText>
                  <span>{formatJsonTimeString(kunngjoring.basicRecordFilingDate)}</span>
                </PsText>
              </PsBox>
            </PsBox>
          </>
        )}
        {kunngjoring.genericPublishingData?.type && (
          <PsBox flex flexDirection="row" my={1}>
            <PsBox>
              <PsText>
                <span>{kunngjoring.genericPublishingData.type}</span>
              </PsText>
            </PsBox>
          </PsBox>
        )}
        {kunngjoring.genericPublishingData?.title && (
          <PsBox flex flexDirection="row" my={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.title')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{kunngjoring.genericPublishingData.title}</span>
              </PsText>
            </PsBox>
          </PsBox>
        )}
        {kunngjoring.freeText && kunngjoring.categoryId != '1200' && (
          <PsBox flex flexDirection="row" my={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.message')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{kunngjoring.freeText}</span>
              </PsText>
            </PsBox>
          </PsBox>
        )}
        {kunngjoring.genericPublishingData?.publishingDate && (
          <PsBox flex flexDirection="row" my={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.messageDate')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{formatJsonTimeString(kunngjoring.genericPublishingData.publishingDate)}</span>
              </PsText>
            </PsBox>
          </PsBox>
        )}
      </PsBox>
    </Box>
  );
};

export default KunngjoringNotConntectedToCaseItem;
