import React from 'react';

import { Box } from '@mui/material';
import { PsBox, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { formatJsonTimeString } from '../../../shared/utils/translation';
import { SakFromSearch } from '../../types/entities';

interface Props {
  sak: SakFromSearch;
}

const InfoContent = ({ sak }: Props) => {
  const { t } = useTranslation();
  const registrationDate = formatJsonTimeString(sak.registrationDate);
  const lastKunngjoringDate = formatJsonTimeString(sak.lastKunngjoringDate);

  return (
    <Box
      sx={{
        height: '100%',
        padding: 3,
        display: { xs: 'none', md: 'flex' },
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
        textOverflow: 'nowrap',
        flex: 1
      }}
    >
      <PsBox flex flexDirection="row">
        <PsText type="bold">
          <span>{t('shared.registered')}:</span>
        </PsText>
        <PsBox ml={1}>
          <PsText>
            <span>{registrationDate}</span>
          </PsText>
        </PsBox>
      </PsBox>
      <PsBox flex flexDirection="row">
        <PsText type="bold">
          <span>{t('search.lastKunngjoring')}:</span>
        </PsText>
        <PsBox ml={1}>
          <PsText>
            <span>{lastKunngjoringDate}</span>
          </PsText>
        </PsBox>
      </PsBox>
      <PsBox flex flexDirection="row">
        <PsText type="bold">
          <span>{t('shared.classes')}:</span>
        </PsText>
        <PsBox ml={1}>
          <PsText>
            <span>{sak.classes ? sak.classes : sak.class}</span>
          </PsText>
        </PsBox>
      </PsBox>
    </Box>
  );
};

export default InfoContent;
