import React, { FunctionComponent } from 'react';

import { Badge, Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { changefilterMarkCategory } from '../../store/kunngjoring-filter/reducer';
import { setCurrentPage } from '../../store/kunngjoring-pagination/reducer';
import { FilterCheckboxValue } from '../../types/entities';

const SelectMarkCategory: FunctionComponent = () => {
  const { i18n, t } = useTranslation();
  const filterState = useAppSelector((gs) => gs.kunngjoringFilterState.filterMarkType);
  const dispatch = useAppDispatch();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const updatedFilterState = [...filterState];
    const index = updatedFilterState.findIndex(
      (markCategory) => markCategory.optionValue.id == event.target.value.toString()
    );
    updatedFilterState[index] = {
      checked: !filterState[index].checked,
      optionValue: filterState[index].optionValue
    };
    dispatch(changefilterMarkCategory(updatedFilterState));
    dispatch(setCurrentPage(1));
  };

  const calculateNumberOfOptionsChecked = () => {
    let counter = 0;
    filterState.forEach((markType) => {
      if (markType.checked == true) counter++;
    });
    return counter;
  };

  return (
    <Badge badgeContent={calculateNumberOfOptionsChecked()} color="primary" sx={{ alignSelf: 'center' }}>
      <Select
        color="secondary"
        multiple
        value={[]}
        onChange={handleChange}
        displayEmpty={true}
        sx={{ height: 'fit-content', alignSelf: 'center', width: 'fit-content' }}
        renderValue={() => <Typography>{t('kunngjoring.markCategory')}</Typography>}
      >
        {filterState.map((markCategory: FilterCheckboxValue) => (
          <MenuItem key={markCategory.optionValue.id} value={markCategory.optionValue.id}>
            <Checkbox color="secondary" checked={markCategory.checked} />
            <ListItemText
              primary={i18n.language === 'no' ? markCategory.optionValue.no : markCategory.optionValue.en}
            />
          </MenuItem>
        ))}
      </Select>
    </Badge>
  );
};

export default SelectMarkCategory;
