import { AxiosResponse } from 'axios';

import { RettighetstypeShort } from '../../../shared/types/enums';
import axiosApi from '../../../shared/utils/axios-api';
import type { ContinuationToken, KunngjoringFromSearch } from '../../types/entities';

export class SearchKunngjoringHttpRequest {
  public static search(
    continuationToken: ContinuationToken,
    queryParams: string,
    rettighetsType: RettighetstypeShort
  ): Promise<AxiosResponse<KunngjoringFromSearch[]>> {
    return axiosApi.get<KunngjoringFromSearch[]>(
      `${process.env.REACT_APP_API_URL}/search/kunngjoring?${queryParams}&rettighetstype=${rettighetsType}`,
      {
        headers: {
          'x-continuation': continuationToken
        }
      }
    );
  }

  public static countSearch(queryParams: string, rettighetsType: RettighetstypeShort): Promise<AxiosResponse<number>> {
    return axiosApi.get<number>(
      `${process.env.REACT_APP_API_URL}/search/countsearchkunngjoring?${queryParams}&rettighetstype=${rettighetsType}`
    );
  }
}
