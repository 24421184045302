import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VaremerkeKunngjoringDetails } from '../../types/entities';

import { VaremerkeKunngjoringDetailsState, initialState } from './state';

export type VaremerkeKunngjoringSuccessAction = {
  id: string;
  details: VaremerkeKunngjoringDetails;
};

export type VaremerkeKunngjoringFailedAction = {
  id: string;
  error: string;
};

export type VaremerkeKunngjoringDownloadFailedAction = {
  id: string;
  fileDownloadError: string;
};

const varemerkeKunngjoringDetailsSlice = createSlice({
  name: 'varemerkeKunngjoringDetails',
  initialState,
  reducers: {
    loadSuccess: (
      state: VaremerkeKunngjoringDetailsState,
      action: PayloadAction<VaremerkeKunngjoringSuccessAction>
    ) => {
      state[action.payload.id] = {
        loaded: true,
        details: action.payload.details
      };
    },
    loadFailed: (state: VaremerkeKunngjoringDetailsState, action: PayloadAction<VaremerkeKunngjoringFailedAction>) => {
      state[action.payload.id] = {
        loaded: true,
        error: action.payload.error,
        details: undefined
      };
    },
    loadFileDownloadError: (
      state: VaremerkeKunngjoringDetailsState,
      action: PayloadAction<VaremerkeKunngjoringDownloadFailedAction>
    ) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        fileDownloadError: action.payload.fileDownloadError
      };
    }
  }
});

const { actions, reducer } = varemerkeKunngjoringDetailsSlice;
export const { loadSuccess, loadFailed, loadFileDownloadError } = actions;
export default reducer;
