import axiosApi from '../../../shared/utils/axios-api';
import { DesignSakDetails } from '../../types/entities';

export class SakDetailsHttpRequest {
  public static getDesignSakDetails(sakIdentification?: string) {
    return axiosApi.get<DesignSakDetails>(
      `${process.env.REACT_APP_API_URL}/sak/designsakdetails?sakidentification=${sakIdentification}`
    );
  }
}
