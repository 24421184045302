import axiosApi from '../../../shared/utils/axios-api';
import { SelectCheckboxOptionValue } from '../../types/entities';

export class SubCategoriesHttpRequest {
  public static getSubCategories(categoryId: string) {
    return axiosApi.get<SelectCheckboxOptionValue[]>(
      `${process.env.REACT_APP_API_URL}/tidende/subcategories?categoryId=${categoryId}`
    );
  }
}
