import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './locales/en';
import no from './locales/no';

export const defaultNS = 'translation';

const isTesting = process.env.NODE_ENV === 'test';

export const resources = {
  en,
  no
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  // .init({ resources, lng: "no" }); // use language-detector instead of setting the lang
  .init({ resources, defaultNS, fallbackLng: 'no', debug: !isTesting, supportedLngs: ['no', 'en'] });

export default i18next;
