import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Kunngjoring } from '../../types/entities';

import { KunngjoringState, initialState } from './state';

export type KunngjoringSuccessAction = {
  id: string;
  kunngjoringer: Kunngjoring[];
};

export type KunngjoringFailedAction = {
  id: string;
  error: string;
};

const kunngjoringSlice = createSlice({
  name: 'kunngjoring-list',
  initialState,
  reducers: {
    loadSuccess: (state: KunngjoringState, action: PayloadAction<KunngjoringSuccessAction>) => {
      state[action.payload.id] = {
        loaded: true,
        kunngjoringer: action.payload.kunngjoringer
      };
    },
    loadFailed: (state: KunngjoringState, action: PayloadAction<KunngjoringFailedAction>) => {
      state[action.payload.id] = {
        loaded: true,
        error: action.payload.error,
        kunngjoringer: []
      };
    }
  }
});

const { actions, reducer } = kunngjoringSlice;
export const { loadSuccess, loadFailed } = actions;
export default reducer;
