import React, { FunctionComponent, useEffect } from 'react';

import { PsAlert, PsBox, PsBreadcrumb, PsContainer, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import DesignImages from '../../../shared/components/DesignImages';
import Loader from '../../../shared/components/Loader';
import TechnicalErrorMessage from '../../../shared/components/TechnicalErrorMessage';
import TimelineComponent from '../../../shared/components/Timeline';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { loadDesignSakDetailsAsync } from '../../store/design-sak-details/service'; //TODO

import DesignInfo from './DesignInfo';
import DetailedViewHeader from './DetailedViewHeader';

const DesignSakScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const routeParams = useParams();
  const rettighetstype = useRettighetstype();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (designSakDetails.sakIdentification !== routeParams.id) {
      dispatch(loadDesignSakDetailsAsync(routeParams.id));
    }
  }, []);

  const { loaded, error, designSakDetails } = useAppSelector((gs) => gs.designSakDetailsState);

  if (!loaded) {
    return <Loader />;
  }

  if (error) {
    return <TechnicalErrorMessage />;
  }

  return (
    <PsContainer>
      <div>
        <PsBreadcrumb>
          <li>
            <Link to="/">Tidende</Link>
          </li>
          <li>
            <Link to={`/${rettighetstype}`}>{t(rettighetstype)}</Link>
          </li>
          <li>
            <Link to="#">{designSakDetails.applicationNumber}</Link>
          </li>
        </PsBreadcrumb>
        <PsBox gutter>
          <PsText type="title1">
            <span>{t('sak.sak')}</span>
          </PsText>
        </PsBox>
        <DetailedViewHeader />
        <DesignInfo />
        <DesignImages designRepresentationSheets={designSakDetails.designRepresentationSheets} />
        <PsBox mb={-2} mt={2}>
          <PsAlert severity="info">{t('sak.timelineInfo')}</PsAlert>
        </PsBox>
        <TimelineComponent />
      </div>
    </PsContainer>
  );
};
export default DesignSakScreen;
