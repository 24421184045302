export default {
  received: 'Filed',
  generalAvailable: 'Publicly available',
  natureOfMark: 'Nature of mark',
  productList: 'List of goods and services',
  filterHeader: 'Filter',
  filterType: 'Representative, holder or applicant',
  emptyVaremerkeKunngjoringer: 'We´re sorry, but there are no events matching your selected category and filter.',
  category: 'Publication group',
  publicationDate: 'Publication date',
  notRegistered: 'Not registered',
  classes: 'Classes',
  holder: 'Holder',
  holders: 'Holders',
  applicant: 'Applicant',
  applicants: 'Applicants',
  representative: 'Representative',
  registered: 'Registered/Granted',
  sakDetails: 'Case details',
  markText: 'Text in mark',
  markDescription: 'Mark description',
  markColorDescription: 'Mark color description',
  markCategory: 'Mark category',
  applicationType: 'Application type',
  eventType: 'Event type',
  inventor: 'Inventor',
  inventors: 'Inventors',
  title: 'Title',
  ipcClass: 'IPC-classes',
  pdfError: 'PDF could not be generated',
  download: 'Download event',
  kunngjoring: 'Event',
  gaaTilbakeTilSak: 'Go back to case',
  eventDate: 'Event date',
  expiryDate: 'Expires',
  designer: 'Designer',
  designers: 'Designers',
  products: 'Product',
  priority: 'Priority data',
  currentStatus: 'Current status',
  claimant: 'Requestor',
  claimants: 'Requestors',
  claimantAttorney: 'Requestor representative',
  requestFilingDate: 'Request filing date',
  decision: 'Decision',
  message: 'Remark',
  errorPdf: 'Failed to get the pdf, please give it another go',
  priorAgent: 'Prior representative',
  priorAgents: 'Prior representatives',
  priorHolder: 'Prior holder',
  priorHolders: 'Prior holders',
  annotationID: 'Annotation ID',
  agreementType: 'Agreement type',
  agreementParties: 'Agreement parties',
  scope: 'Scope',
  description: 'Description',
  messageData: 'Remark data',
  messageDate: 'Remark date',
  link: 'Link',
  companyName: 'Company name',
  cnComplaintTitle: 'Administrative review of company name registration',
  claim: 'Claim',
  decisionAuthority: 'Decision authority',
  finalDecisionDate: 'Date decision final',
  attachment: 'Attachment',
  downloadAttachment: 'Download attachment',
  spc: {
    productIndentifier: 'Markeded product',
    authorizationNorway: 'Authorization in Norway',
    authorizationEAA: 'Authorization within EAA',
    basicPatent: 'Basic patent'
  },
  associatedMarks: {
    transformedTo: 'Transformed case',
    divisionalParent: 'Divisional parent',
    changeOfMark: 'Change of mark',
    associatedMark: 'Associated mark',
    statusAssociatedMark: 'Current status for associated mark'
  }
};
