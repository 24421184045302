import { AppThunk } from '../../../../core/store';
import { Rettighetstype } from '../../../shared/types/entities';
import { mapRettighetstypeShort } from '../../../shared/utils/rettighetstype-utils';

import { KunngjoringHttpRequest } from './http-request';
import { loadSuccess, loadFailed } from './reducer';

export const loadKunngjoringerAsync = (
  publikasjonId?: string,
  kategoriId?: string,
  rettighetstype?: Rettighetstype
): AppThunk => {
  return (dispatch) => {
    const rettighetstypeShort = rettighetstype ? mapRettighetstypeShort(rettighetstype) : undefined;
    const kunngjoringStateId = `${rettighetstype}-${publikasjonId}-${kategoriId}`;
    KunngjoringHttpRequest.getKunngjoringer(publikasjonId, kategoriId, rettighetstypeShort)
      .then((response) => {
        dispatch(loadSuccess({ id: kunngjoringStateId, kunngjoringer: response.data }));
      })
      .catch((error) => {
        dispatch(loadFailed({ id: kunngjoringStateId, error: error.toString() }));
      });
  };
};
