import { VaremerkeSakDetails } from '../../types/entities';

export interface VaremerkeSakDetailsState {
  success: boolean;
  loaded: boolean;
  error?: string;
  varemerkeSakDetails: VaremerkeSakDetails;
}

export const initialState: VaremerkeSakDetailsState = {
  success: false,
  loaded: false,
  error: undefined,
  varemerkeSakDetails: {} as VaremerkeSakDetails
};
