import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsText, PsBox } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { formatJsonTimeString, manualTranslation } from '../../../../shared/utils/translation';
import { baseStyles } from '../../../styles/BaseBoxStyles';
import { AssociatedMark } from '../../../types/entities';

interface Props {
  associatedMarks: AssociatedMark[];
}

const AssociatedMarkInfo: FunctionComponent<Props> = ({ associatedMarks }: Props) => {
  const { t, i18n } = useTranslation();

  const associationCategory = (categoryDescriptionFromDB: string) => {
    if (categoryDescriptionFromDB == 'Transformedto') {
      return t('kunngjoring.associatedMarks.transformedTo');
    } else if (categoryDescriptionFromDB == 'DivisionalParent')
      return t('kunngjoring.associatedMarks.divisionalParent');
    else {
      return t('kunngjoring.associatedMarks.changeOfMark');
    }
  };

  const applicationAndRegistrationNumber = (associatedMark: AssociatedMark) => {
    const regNr = associatedMark.registrationNumber;
    if (regNr != null) {
      return `${associatedMark.applicationNumber} (${regNr})`;
    } else return associatedMark.applicationNumber;
  };

  return (
    <>
      {associatedMarks.map((associatedMark) => (
        <>
          <Box key={associatedMark.applicationNumber} sx={{ ...baseStyles.baseBoxStyleHeader }}>
            <PsText type="bold">
              <span>{t('kunngjoring.associatedMarks.associatedMark')}:</span>
            </PsText>
            <PsBox ml={1} flex flexDirection="row">
              <PsText>
                <span>
                  <span>{applicationAndRegistrationNumber(associatedMark)}</span>
                </span>
              </PsText>
              <PsBox ml={1}>
                <PsText>
                  <span>
                    <span>{associationCategory(associatedMark.associationCategory)}</span>
                  </span>
                </PsText>
              </PsBox>
            </PsBox>
          </Box>
          <Box key={associatedMark.applicationNumber} sx={{ ...baseStyles.baseBoxStyleHeader }}>
            <PsText type="bold">
              <span>{t('kunngjoring.associatedMarks.statusAssociatedMark')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{`${manualTranslation(
                  i18n,
                  associatedMark.currentStatus.highLevelStatusDescription
                )} (${formatJsonTimeString(associatedMark.currentStatus.effectDate)})`}</span>
              </PsText>
            </PsBox>
          </Box>
        </>
      ))}
    </>
  );
};

export default AssociatedMarkInfo;
