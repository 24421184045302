import { PatentSakDetails } from '../../types/entities';

export interface PatentSakDetailsState {
  success: boolean;
  loaded: boolean;
  error?: string;
  patentSakDetails: PatentSakDetails;
}

export const initialState: PatentSakDetailsState = {
  success: false,
  loaded: false,
  error: undefined,
  patentSakDetails: {} as PatentSakDetails
};
