import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { KunngjoringFromSearch } from '../../types/entities';

import { SearchKunngjoringState, initialSearchKunngjoringStateByRettighet, initialState } from './state';

export type SearchKunngjoringResetAction = {
  id: string;
  loading: boolean;
};

export type SearchKunngjoringSuccessAction = {
  id: string;
  continuationToken: string;
  paginationPage: number;
  searchResult: KunngjoringFromSearch[];
};

export type KunngjoringHitsSuccessAction = {
  id: string;
  totalHits: number;
  totalPages: number;
};

export type SearchKunngjoringFailedAction = {
  id: string;
  error: string;
};

const searchKunnkjoringSlice = createSlice({
  name: 'search-kunngjoring',
  initialState,
  reducers: {
    resetSearchKunngjoring: (state: SearchKunngjoringState, action: PayloadAction<SearchKunngjoringResetAction>) => {
      state[action.payload.id] = {
        ...initialSearchKunngjoringStateByRettighet,
        loading: action.payload.loading
      };
    },
    setLoading: (state: SearchKunngjoringState, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const curr = state[action.payload.id] || initialSearchKunngjoringStateByRettighet;
      state[action.payload.id] = {
        ...curr,
        loading: action.payload.loading,
        loaded: !action.payload.loading,
        error: undefined
      };
    },
    loadTotalHits: (state: SearchKunngjoringState, action: PayloadAction<KunngjoringHitsSuccessAction>) => {
      const curr = state[action.payload.id] || initialSearchKunngjoringStateByRettighet;
      state[action.payload.id] = {
        ...curr,
        totalHits: action.payload.totalHits,
        totalPages: action.payload.totalPages
      };
    },
    loadSuccess: (state: SearchKunngjoringState, action: PayloadAction<SearchKunngjoringSuccessAction>) => {
      const curr = state[action.payload.id] || initialSearchKunngjoringStateByRettighet;
      const continuationTokens = [...curr.continuationTokens];
      const currToken = action.payload.continuationToken;
      if (currToken && !continuationTokens.includes(currToken)) {
        continuationTokens.push(currToken);
      }
      state[action.payload.id] = {
        ...curr,
        loading: false,
        loaded: true,
        error: undefined,
        searchResult: action.payload.searchResult,
        continuationTokens,
        paginationPage: action.payload.paginationPage
      };
    },
    loadFailed: (state: SearchKunngjoringState, action: PayloadAction<SearchKunngjoringFailedAction>) => {
      const curr = state[action.payload.id] || initialSearchKunngjoringStateByRettighet;
      state[action.payload.id] = {
        ...curr,
        loading: false,
        loaded: true,
        error: action.payload.error,
        searchResult: initialSearchKunngjoringStateByRettighet.searchResult
      };
    }
  }
});

const { actions, reducer } = searchKunnkjoringSlice;
export const { resetSearchKunngjoring, setLoading, loadSuccess, loadFailed, loadTotalHits } = actions;
export default reducer;
