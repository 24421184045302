import axiosApi from '../../../shared/utils/axios-api';
import { Kunngjoring } from '../../types/entities';

export class KunngjoringHttpRequest {
  public static getKunngjoringer(publikasjonId?: string, kunngjoringType?: string, domain?: string) {
    return axiosApi.get<Kunngjoring[]>(
      `${process.env.REACT_APP_API_URL}/tidende/kunngjoringer?categoryId=${kunngjoringType}&GazetteNumber=${publikasjonId}&domain=${domain}`
    );
  }
}
