import React, { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';
import { PsBox, PsMaterialIcon } from '@patentstyret/designsystem-react';

import { Image } from '../types/entities';

import ImageContent from './ImageContent';
import { StyledTooltip } from './Tooltip';

interface Props {
  markFeature: string;
  image: Image;
  hasImage: boolean;
  title: string;
}

const ImageInListItem: FunctionComponent<Props> = ({ markFeature, image, hasImage, title: varemerkeText }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: { xs: 'flex-start', sm: 'center' },
        alignItems: { xs: 'flex-start', sm: 'center' },
        minWidth: { sm: '130px' },
        maxWidth: { sm: '130px' }
      }}
    >
      {markFeature == 'BBM_NATUREMARK_WORD' && (
        <StyledTooltip title={varemerkeText}>
          <Typography sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>{varemerkeText}</Typography>
        </StyledTooltip>
      )}
      {(markFeature == 'BBM_NATUREMARK_COMBINED_FIGURATIVE' ||
        markFeature == 'BBM_NATUREMARK_3D' ||
        markFeature == '') && <ImageContent image={image} hasImage={hasImage} detailedView />}
      {markFeature == 'BBM_NATUREMARK_MULTIMEDIA' && (
        <PsBox p={4} color="primary">
          <PsMaterialIcon symbol="videocam" size="xl"></PsMaterialIcon>
        </PsBox>
      )}
      {markFeature == 'BBM_NATUREMARK_SOUND' && (
        <PsBox p={4} color="primary">
          <PsMaterialIcon symbol="audio_file" size="xl"></PsMaterialIcon>
        </PsBox>
      )}
    </Box>
  );
};

export default ImageInListItem;
