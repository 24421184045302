import React, { FunctionComponent, useEffect } from 'react';

import { PsBox, PsContainer, PsLinkList, PsLinkListItem } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DownloadErrorMessage from '../../../shared/components/DownloadErrorMessage';
import Loader from '../../../shared/components/Loader';
import TechnicalErrorMessage from '../../../shared/components/TechnicalErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { getCategoryStateByRettighetAndPublikasjonNr } from '../../store/publikasjon-category/selectors';
import { loadPublikasjonCategoriesAsync as loadPublikasjonCategoriesAsync } from '../../store/publikasjon-category/service';
import { Publikasjon } from '../../types/entities';

interface Props {
  publikasjon: Publikasjon;
}

const CategoryList: FunctionComponent<Props> = ({ publikasjon }: Props) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const rettighetstype = useRettighetstype();

  const categoryState = useAppSelector((gs) => gs.publikasjonCategoryState);
  const categoryStateByPublikasjon = getCategoryStateByRettighetAndPublikasjonNr(
    categoryState,
    rettighetstype,
    publikasjon.id
  );
  const { content: categories, loaded, error, fileDownloadError } = categoryStateByPublikasjon;

  useEffect(() => {
    if (!loaded) {
      dispatch(loadPublikasjonCategoriesAsync(publikasjon.id, rettighetstype));
    }
  }, [publikasjon, rettighetstype]);

  if (!publikasjon) {
    return null;
  }

  const listItemClickHandler = (e: React.MouseEvent<HTMLPsLinkListItemElement>, link: string) => {
    navigate(link);
    e.preventDefault();
  };

  const content = categories.map((category) => {
    let label = i18n.language === 'no' ? category.norwegianDescription : category.englishDescription;
    const numKunngjoringer = `(${category.numberOfKunngjoringer})`;
    label += '  ' + numKunngjoringer;
    const link = `/${rettighetstype}/publikasjoner/${publikasjon.id}/${category.categoryId}`;
    return (
      <PsLinkListItem
        key={category.categoryId}
        label={label}
        href={link}
        onClick={(e) => listItemClickHandler(e, link)}
      />
    );
  });

  return (
    <PsContainer fluid color="primary" pFluid>
      <PsContainer>
        <PsBox>
          <div>
            {!loaded && <Loader />}
            {error && <TechnicalErrorMessage />}
            {fileDownloadError && <DownloadErrorMessage />}
            {loaded && (
              <PsLinkList>
                <div>{content}</div>
              </PsLinkList>
            )}
          </div>
        </PsBox>
      </PsContainer>
    </PsContainer>
  );
};

export default CategoryList;
