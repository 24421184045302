import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ScrollPositionState, initialState } from './state';

const scrollPositionSlice = createSlice({
  name: 'scrollPosition',
  initialState,
  reducers: {
    reset: () => initialState,
    setScrollPosition: (state: ScrollPositionState, action: PayloadAction<number>) => {
      state.position = action.payload;
    }
  }
});

const { actions, reducer } = scrollPositionSlice;
export const { setScrollPosition } = actions;
export default reducer;
