import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SakFromSearch } from '../../types/entities';

import { SearchSakState, initialSearchSakStateByRettighet, initialState } from './state';

export type SearchSakResetAction = {
  id: string;
  loading: boolean;
};

export type SearchSakSuccessAction = {
  id: string;
  continuationToken: string;
  paginationPage: number;
  searchResult: SakFromSearch[];
};

export type SakHitsSuccessAction = {
  id: string;
  totalHits: number;
  totalPages: number;
};

export type SearchSakFailedAction = {
  id: string;
  error: string;
};

const searchSakSlice = createSlice({
  name: 'search-sak',
  initialState,
  reducers: {
    resetSearchSak: (state: SearchSakState, action: PayloadAction<SearchSakResetAction>) => {
      state[action.payload.id] = {
        ...initialSearchSakStateByRettighet,
        loading: action.payload.loading
      };
    },
    setLoading: (state: SearchSakState, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const curr = state[action.payload.id] || initialSearchSakStateByRettighet;
      state[action.payload.id] = {
        ...curr,
        loading: action.payload.loading,
        loaded: !action.payload.loading,
        error: undefined
      };
    },
    loadTotalHits: (state: SearchSakState, action: PayloadAction<SakHitsSuccessAction>) => {
      const curr = state[action.payload.id] || initialSearchSakStateByRettighet;
      state[action.payload.id] = {
        ...curr,
        totalHits: action.payload.totalHits,
        totalPages: action.payload.totalPages
      };
    },
    loadSuccess: (state: SearchSakState, action: PayloadAction<SearchSakSuccessAction>) => {
      const curr = state[action.payload.id] || initialSearchSakStateByRettighet;
      const continuationTokens = [...curr.continuationTokens];
      const currToken = action.payload.continuationToken;
      if (currToken && !continuationTokens.includes(currToken)) {
        continuationTokens.push(action.payload.continuationToken);
      }
      state[action.payload.id] = {
        ...curr,
        loading: false,
        loaded: true,
        error: undefined,
        searchResult: action.payload.searchResult,
        continuationTokens,
        paginationPage: action.payload.paginationPage
      };
    },
    loadFailed: (state: SearchSakState, action: PayloadAction<SearchSakFailedAction>) => {
      const curr = state[action.payload.id] || initialSearchSakStateByRettighet;
      state[action.payload.id] = {
        ...curr,
        loading: false,
        loaded: true,
        error: action.payload.error,
        searchResult: initialSearchSakStateByRettighet.searchResult
      };
    }
  }
});

const { actions, reducer } = searchSakSlice;
export const { resetSearchSak, setLoading, loadSuccess, loadFailed, loadTotalHits } = actions;
export default reducer;
