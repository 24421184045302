import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { formatParticipant } from '../../../shared/utils/text-utils';
import { KunngjoringFromSearch } from '../../types/entities';

import ProprietorField from './ProprietorField';

interface Props {
  searchKunngjoring: KunngjoringFromSearch;
}

const InfoContent: FunctionComponent<Props> = ({ searchKunngjoring }: Props) => {
  const { t } = useTranslation();
  const rettighetstype = useRettighetstype();

  return (
    <Box
      sx={{
        paddingLeft: 5,
        display: { xs: 'none', md: 'flex' },
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
        textOverflow: 'nowrap',
        flex: 1
      }}
    >
      {searchKunngjoring.spcBasicPatentCaseIdentification != null && (
        <PsBox flex flexDirection="row">
          <PsText type="bold">
            <span>{t('kunngjoring.spc.basicPatent')}:</span>
          </PsText>
          <PsBox ml={1}>
            <PsText>
              <span>{searchKunngjoring.spcBasicPatentCaseIdentification.substring(2)}</span>
            </PsText>
          </PsBox>
        </PsBox>
      )}
      <ProprietorField searchKunngjoring={searchKunngjoring} />
      <PsBox flex flexDirection="row">
        <PsText type="bold">
          <span>{t('search.representative')}: </span>
        </PsText>
        <PsBox ml={1}>
          <PsText>
            <span>
              {searchKunngjoring.agent ? formatParticipant(searchKunngjoring.agent) : t('kunngjoring.notRegistered')}
            </span>
          </PsText>
        </PsBox>
      </PsBox>
      <PsBox flex flexDirection="row">
        {rettighetstype === 'patent' && (
          <PsText type="bold">
            <span>{t('kunngjoring.title')}:</span>
          </PsText>
        )}
        {rettighetstype === 'design' && (
          <PsText type="bold">
            <span>{t('kunngjoring.products')}:</span>
          </PsText>
        )}
        {rettighetstype === 'varemerke' && (
          <PsText type="bold">
            <span>{t('kunngjoring.markText')}:</span>
          </PsText>
        )}
        <Box
          sx={{
            marginLeft: 1,
            display: 'inline-block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          <PsText>
            <span>{searchKunngjoring.title ?? ''}</span>
          </PsText>
        </Box>
      </PsBox>
      <PsBox flex flexDirection="row">
        <PsText type="bold">
          <span>{t('kunngjoring.classes')}:</span>
        </PsText>
        <PsBox ml={1}>
          <PsText>
            <span>{searchKunngjoring.class ? searchKunngjoring.class : searchKunngjoring.classes?.join(', ')}</span>
          </PsText>
        </PsBox>
      </PsBox>
    </Box>
  );
};

export default InfoContent;
