import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsContainer, PsLink, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { PublikasjonCategory } from '../../../../publikasjon/types/entities';
import ImageContent from '../../../../shared/components/ImageContent';
import { formatJsonTimeString, manualTranslation } from '../../../../shared/utils/translation';
import { baseStyles, headerStyles } from '../../../styles/BaseBoxStyles';
import { KunngjoringDetails } from '../../../types/entities';
import CategorySpecificEventDetails from '../CategorySpecificEventDetails';

interface Props {
  kunngjoringDetails: KunngjoringDetails;
  category?: PublikasjonCategory;
}

const KunngjoringNotConnectedToCasePanel: FunctionComponent<Props> = ({ kunngjoringDetails, category }: Props) => {
  const { i18n, t } = useTranslation();
  let categoryDesc = '';
  if (category) {
    categoryDesc = i18n.language === 'no' ? category.norwegianDescription : category.englishDescription;
  }

  const HeaderForCNComplaints = () => {
    return (
      <Box
        sx={{
          ...headerStyles.topBox
        }}
      >
        <PsBox>
          <PsBox flex justifyContent="flex-start">
            <PsText>
              <span>{`${kunngjoringDetails.basicRecord?.kind}${kunngjoringDetails.basicRecord?.recordIdentifier}`}</span>
            </PsText>
          </PsBox>
          <PsBox flex flexDirection="column" alignItems="flex-start" my={1}>
            <PsText type="title3" root={{ class: 'titleWithoutMargin' }}>
              <span>
                {manualTranslation(i18n, kunngjoringDetails.basicRecord?.currentStatus.highLevelStatusDescription)}
              </span>
            </PsText>
            <PsText type="title4" root={{ class: 'titleWithoutMargin' }}>
              <span>
                {manualTranslation(i18n, kunngjoringDetails.basicRecord?.currentStatus.subLevelStatusDescription)}
              </span>
            </PsText>
          </PsBox>
        </PsBox>
        <PsBox>
          <PsText>
            <span>
              {`${kunngjoringDetails.basicRecord?.currentStatus?.highLevelStatusCode}${kunngjoringDetails.basicRecord?.currentStatus?.subLevelStatusCode}`}
            </span>
          </PsText>
        </PsBox>
      </Box>
    );
  };

  return (
    <PsContainer>
      {kunngjoringDetails.publikasjonData.categoryId == '0400' ||
      kunngjoringDetails.publikasjonData.subCategoryId == '0501b' ? (
        HeaderForCNComplaints()
      ) : (
        <Box
          sx={{
            ...headerStyles.topBox
          }}
        >
          {kunngjoringDetails.eventData?.genericPublishingData?.image && (
            <ImageContent image={kunngjoringDetails.eventData?.genericPublishingData?.image} hasImage detailedView />
          )}
          <PsBox>
            <PsBox flex justifyContent="center">
              <PsText>
                <span>{kunngjoringDetails.eventData?.genericPublishingData?.identification}</span>
              </PsText>
            </PsBox>
            <PsBox flex flexDirection="column" alignItems="center" my={1}>
              <PsText type="title3" root={{ class: 'titleWithoutMargin' }}>
                <span>{kunngjoringDetails.eventData?.genericPublishingData?.title}</span>
              </PsText>
              <PsText type="title4" root={{ class: 'titleWithoutMargin' }}>
                <span>{kunngjoringDetails.eventData?.genericPublishingData?.type}</span>
              </PsText>
            </PsBox>
          </PsBox>
          <Box sx={{ minWidth: '120px', height: '1px' }}></Box> {/* To center the component above */}
        </Box>
      )}
      <Box
        sx={{
          ...baseStyles.baseBoxStyleHeader
        }}
      >
        <PsBox mr={1}>
          <PsText type="bold">
            <span>{t('kunngjoring.category') + ':'}</span>
          </PsText>
        </PsBox>
        {categoryDesc && (
          <PsText>
            <span>{categoryDesc}</span>
          </PsText>
        )}
      </Box>
      <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
        <PsBox mr={1}>
          <PsText type="bold">
            <span>{t('kunngjoring.publicationDate') + ':'}</span>
          </PsText>
        </PsBox>
        <PsText>
          <span>{formatJsonTimeString(kunngjoringDetails.kunngjoringDate)}</span>
        </PsText>
      </Box>
      <PsBox color="primary" severity="success" py={0.5} pl={2} flex flexDirection="row" alignItems="center">
        <PsText type="title4">
          <span>
            {kunngjoringDetails.publikasjonData.categoryId == '0400'
              ? 'Administrativ overprøving av foretaksnavn'
              : t('kunngjoring.messageData')}
          </span>
        </PsText>
      </PsBox>
      {kunngjoringDetails.eventData?.genericPublishingData?.description && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsBox mr={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.description')}:</span>
            </PsText>
          </PsBox>
          <PsText>
            <span>{kunngjoringDetails.eventData?.genericPublishingData?.description}</span>
          </PsText>
        </Box>
      )}
      {kunngjoringDetails.eventData?.genericPublishingData?.publishingDate && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsBox mr={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.messageDate')}:</span>
            </PsText>
          </PsBox>
          <PsText>
            <span>{formatJsonTimeString(kunngjoringDetails.eventData?.genericPublishingData?.publishingDate)}</span>
          </PsText>
        </Box>
      )}
      <CategorySpecificEventDetails details={kunngjoringDetails} />
      {kunngjoringDetails.eventData?.link && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsBox mr={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.link')}:</span>
            </PsText>
          </PsBox>
          <PsLink href={kunngjoringDetails.eventData?.link} target="_blank">
            <PsText>
              <span>{kunngjoringDetails.eventData?.link}</span>
            </PsText>
          </PsLink>
        </Box>
      )}
    </PsContainer>
  );
};

export default KunngjoringNotConnectedToCasePanel;
