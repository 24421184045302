import React from 'react';

import { Box } from '@mui/material';
import { PsBox, PsText } from '@patentstyret/designsystem-react';
import { TFunction } from 'i18next';

import { SPCAuthorization } from '../../kunngjoring/types/entities';
import { Publikasjon } from '../../publikasjon/types/entities';
import { Participant } from '../types/entities';

import { isFutureDate } from './date-utils';
import { formatJsonTimeString } from './translation';

export const capitalizeFirstLetter = (word: string) => {
  if (!word) {
    return word;
  }

  const firstLetter = word.charAt(0);
  const firstLetterCap = firstLetter.toUpperCase();
  const remainingLetters = word.slice(1);
  return firstLetterCap + remainingLetters;
};

export const truncate = (content?: string, length = 20) => {
  if (!content || content.length < length) {
    return content;
  }
  return content.slice(0, length - 1) + String.fromCharCode(8230); //&hellip;
};

export const formatPublicationName = (t: TFunction, publication?: Publikasjon, short = false) => {
  if (!publication) {
    return t('publikasjon.ikkeFunnetTittel');
  }
  if (isFutureDate(publication.publikasjonDate)) {
    return t('publikasjon.forelopig');
  }
  if (short) {
    return `${t('publikasjon.publikasjonSelect')} ${publication.nr}`;
  }
  return `${t('publikasjon.publikasjonSelect')} ${publication.nr} - ${publication.year}`;
};

export const formatParticipant = (party: Participant): string => {
  if (party == null) return '';
  if (party.city == '') return party.name + ', ' + party.countryCode;
  return party.name + ', ' + party.city + ', ' + party.countryCode;
};

export const formatApplicantOrHolder = (
  t: TFunction,
  proprietors: Participant[],
  applicants: Participant[]
): JSX.Element => {
  if (proprietors != null && proprietors.length > 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { sm: 'column', md: 'column', xl: 'row' },
          justifyContent: 'start'
        }}
      >
        <PsText type="bold">
          <span>{proprietors.length === 1 ? t('shared.holder') : t('shared.holders')}:</span>
        </PsText>
        <PsBox flex flexDirection="column" ml={1}>
          {proprietors.map((proprietor) => (
            <PsText key={proprietor.name}>
              <span>{formatParticipant(proprietor)}</span>
            </PsText>
          ))}
        </PsBox>
      </Box>
    );
  }
  return (
    <PsBox flex justifyContent="flex-start">
      <PsText type="bold">
        <span>{applicants.length === 1 ? t('shared.applicant') : t('shared.applicants')}:</span>
      </PsText>
      <PsBox ml={1} flex flexDirection="column">
        {applicants.map((applicant) => (
          <PsText key={applicant.name}>
            <span>{formatParticipant(applicant)}</span>
          </PsText>
        ))}
      </PsBox>
    </PsBox>
  );
};

export const formatAuthorization = (authorization: SPCAuthorization) => {
  return (
    formatJsonTimeString(authorization.date) +
    ' ' +
    (authorization.country ? authorization.country : '') +
    ' ' +
    authorization.number
  );
};
