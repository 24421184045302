export default {
  kunngjoring: 'Kunngjøring',
  sak: 'Sak',
  representative: 'Fullmektig',
  sakNumber: 'Saksnummer',
  proprietor: 'Innehaver',
  kunngjoringType: 'Kunngjøringstype',
  allTime: 'All tid',
  last30Days: 'Siste 30 dager',
  lastYear: 'Siste år',
  customTimeInterval: 'Egendefinert',
  closeButton: 'Lukk',
  toDate: 'Til dato',
  fromDate: 'Fra dato',
  date: 'Dato',
  kunngjoringDate: 'Kunngjøringsdato',
  status: 'Status',
  startPage: 'Du må gjøre et søk for å få opp innhold',
  noResults: 'Fant ingen resultater',
  lastKunngjoring: 'Siste hendelse',
  sok: 'Søk',
  searchWithoutSearchString: 'Du kan ikke søke uten noe søketekst',
  gazetteNumber: 'Tidende nr',
  of: 'av',
  registrationNumber: 'Registreringsnummer',
  patentNumber: 'Patentnummer'
};
