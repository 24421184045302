import React, { FunctionComponent, useEffect } from 'react';

import { PsAlert, PsBox, PsBreadcrumb, PsContainer, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import Loader from '../../../shared/components/Loader';
import TechnicalErrorMessage from '../../../shared/components/TechnicalErrorMessage';
import TimelineComponent from '../../../shared/components/Timeline';
import TrademarkClassesAccordion from '../../../shared/components/TrademarkClassesAccordion';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { loadVaremerkeSakDetailsAsync } from '../../store/varemerke-sak-details/service';

import DetailedViewHeader from './DetailedViewHeader';
import VaremerkeInfo from './VaremerkeInfo';

const VaremerkeSakScreen: FunctionComponent = () => {
  const routeParams = useParams();
  const { t } = useTranslation();
  const rettighetstype = useRettighetstype();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (varemerkeSakDetails.sakIdentification !== routeParams.id) {
      dispatch(loadVaremerkeSakDetailsAsync(routeParams.id));
    }
  }, []);

  const { loaded, error, varemerkeSakDetails } = useAppSelector((gs) => gs.varemerkeSakDetailsState);

  if (!loaded) {
    return <Loader />;
  }

  if (error) {
    return <TechnicalErrorMessage />;
  }

  return (
    <PsContainer color="primary">
      <div>
        <PsBreadcrumb>
          <li>
            <Link to="/">Tidende</Link>
          </li>
          <li>
            <Link to={`/${rettighetstype}`}>{t(rettighetstype)}</Link>
          </li>
          <li>
            <Link to="#">{varemerkeSakDetails.applicationNumber}</Link>
          </li>
        </PsBreadcrumb>
        <PsBox gutter>
          <PsText type="title1">
            <span>{t('sak.sak')}</span>
          </PsText>
        </PsBox>
        <PsContainer color="primary">
          <DetailedViewHeader />
          <VaremerkeInfo />
          <TrademarkClassesAccordion trademarkClasses={varemerkeSakDetails.goodsAndServices} />
          <PsBox mb={-2} mt={2}>
            <PsAlert severity="info">{t('sak.timelineInfo')}</PsAlert>
          </PsBox>
          <TimelineComponent />
        </PsContainer>
      </div>
    </PsContainer>
  );
};
export default VaremerkeSakScreen;
