import { useEffect, useState } from 'react';

import { BlobContainer } from '../../search/types/enums';
import imageNotFound from '../../shared/assets/image-not-found.png';
import axiosApi from '../utils/axios-api';

const useMedia = (
  hasMedia: boolean,
  fileName: string,
  isInView: boolean,
  containerType?: BlobContainer
): { mediaSrc: string; mediaLoaded: boolean } => {
  const [mediaSrc, setMediaSrc] = useState<string>('');
  const [mediaLoaded, setMediaLoaded] = useState(false);

  const container = containerType ? containerType : BlobContainer.miniature;

  const getMediaAsync = (filename: string, blobContainer: string) => {
    const url = `${process.env.REACT_APP_API_URL}/blob/DownloadDocuments`;
    return axiosApi.post<Blob>(
      url,
      { filename, blobContainer },
      {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'blob'
      }
    );
  };

  useEffect(() => {
    if (isInView) {
      if (hasMedia) {
        getMediaAsync(fileName, container)
          .then((response) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const dataURL = reader.result as string;
              setMediaSrc(dataURL);
              setMediaLoaded(true);
            };
            reader.readAsDataURL(response.data);
          })
          .catch(() => {
            setMediaLoaded(true);
            setMediaSrc(imageNotFound);
          });
      } else {
        setMediaLoaded(true);
        setMediaSrc(imageNotFound);
      }
    }
  }, [hasMedia, fileName, isInView]);

  return { mediaSrc, mediaLoaded };
};

export default useMedia;
