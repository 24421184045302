import React, { FunctionComponent } from 'react';

import { Divider } from '@mui/material';

import ImageInListItem from '../../../shared/components/ImageInListeItem';
import ListItem from '../../../shared/components/ListItem';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { KunngjoringFromSearch } from '../../types/entities';

import InfoContent from './InfoContent';
import MainContent from './MainContent';

interface Props {
  searchKunngjoring: KunngjoringFromSearch;
}

const SearchKunngjoringListItem: FunctionComponent<Props> = ({ searchKunngjoring }: Props) => {
  const rettighetstype = useRettighetstype();
  const redirectLink = `/${rettighetstype}/kunngjoring/${searchKunngjoring.sakIdentification}/${searchKunngjoring.id}`;

  return (
    <ListItem redirectLink={redirectLink}>
      <ImageInListItem
        markFeature={searchKunngjoring.markFeature ? searchKunngjoring.markFeature.token : ''}
        image={searchKunngjoring.image}
        hasImage={searchKunngjoring.hasImage}
        title={searchKunngjoring.title ?? ''}
      />
      <MainContent searchKunngjoring={searchKunngjoring} />
      <Divider orientation="vertical" flexItem sx={{ visibility: { sm: 'hidden', md: 'visible' } }} />
      <InfoContent searchKunngjoring={searchKunngjoring} />
    </ListItem>
  );
};

export default SearchKunngjoringListItem;
