import React, { ChangeEvent, FunctionComponent } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { PsBox, PsButton, PsView } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux-hooks';
import { Rettighetstype } from '../../../../shared/types/entities';
import { resetSearchKunngjoring } from '../../../store/search-kunngjoring/reducer';
import { kunngjoringSearchAsync } from '../../../store/search-kunngjoring/service';
import {
  setSearchString,
  setSearchType,
  setTimePeriod,
  setShowSearchInfo,
  setSearchFromDate,
  setPressedSearchButtonWithoutSearhString,
  setSearchToDate
} from '../../../store/search-param/reducer';
import { resetSearchSak } from '../../../store/search-sak/reducer';
import { sakSearchAsync } from '../../../store/search-sak/service';
import { SearchCategoryType, SearchType, SearchPeriodType } from '../../../types/enums';

import SelectPeriodTypeControl from './SelectPeriodTypeControl';
import SelectSearchTypeControl from './SelectSearchTypeControl';

interface Props {
  rettighetstype: Rettighetstype;
}

const SearchPanel: FunctionComponent<Props> = ({ rettighetstype }) => {
  const { t } = useTranslation();
  const searchParams = useAppSelector((gs) => gs.searchParamState);
  const { category, searchString, searchType, periodType } = searchParams;

  const dispatch = useAppDispatch();

  const searchStringChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchString(event.target.value));
  };

  const searchTypeChangeHandler = (searchType: SearchType) => {
    dispatch(setSearchType(searchType));
  };

  const periodTypeChangeHandler = (periodType: SearchPeriodType) => {
    const currentDate = new Date();
    let toDate: Date | undefined = new Date();
    let pastDate: Date | undefined = new Date();

    switch (periodType) {
      case SearchPeriodType.Last30Days: {
        //Sets to/from dates for last 30 days
        pastDate.setDate(currentDate.getDate() - 30);
        break;
      }
      case SearchPeriodType.LastYear: {
        //Sets to/from dates for last year
        pastDate.setFullYear(currentDate.getFullYear() - 1);
        break;
      }
      case SearchPeriodType.AllTime: {
        //Sets to/from dates for all time
        toDate = undefined;
        pastDate = undefined;
        break;
      }
      case SearchPeriodType.Custom: {
        //Sets to/from custom dates
        pastDate.setDate(currentDate.getDate() - 30);
        break;
      }
    }

    const pastDateString = pastDate ? pastDate.toJSON() : undefined;
    const toDateString = toDate ? toDate.toJSON() : undefined;
    dispatch(
      setTimePeriod({
        type: periodType,
        fromDate: pastDateString,
        toDate: toDateString
      })
    );
  };

  // const kunngjoringTypeChangeHandler = (filter: KunngjoringType | undefined) => {
  //   dispatch(setSearchKunngjoringType(filter));
  // };

  const getPlaceholderText = (type: SearchType) => {
    switch (type) {
      case SearchType.Agent:
        return t('search.representative');
      case SearchType.Proprietor:
        return t('search.proprietor');
      case SearchType.SakNumber:
        return t('search.sakNumber');
      case SearchType.RegistrationNumber:
        if (rettighetstype == 'patent') {
          return t('search.patentNumber');
        }
        return t('search.registrationNumber');
    }
  };

  const searchInputField = (
    <TextField
      variant="outlined"
      placeholder={getPlaceholderText(searchType)}
      value={searchString}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
      onChange={searchStringChangeHandler}
      size="small"
    />
  );

  const fromDateChangeHandler = (date: Date | null) => {
    const fromDateString = date ? date.toJSON() : undefined;
    dispatch(setSearchFromDate(fromDateString));
  };

  const toDateChangeHandler = (date: Date | null) => {
    const toDateString = date ? date.toJSON() : undefined;
    dispatch(setSearchToDate(toDateString));
  };

  const fromDateFieldMobile = (
    <MobileDatePicker
      label={t('search.fromDate')}
      renderInput={(params) => <TextField {...params} size="small" fullWidth />}
      value={searchParams.fromDate}
      onChange={fromDateChangeHandler}
    />
  );

  const fromDateFieldDesktop = (
    <DesktopDatePicker
      label={t('search.fromDate')}
      renderInput={(params) => <TextField {...params} size="small" fullWidth />}
      value={searchParams.fromDate}
      onChange={fromDateChangeHandler}
    />
  );

  const toDateFieldMobile = (
    <MobileDatePicker
      label={t('search.toDate')}
      renderInput={(params) => <TextField {...params} size="small" fullWidth />}
      value={searchParams.toDate}
      onChange={toDateChangeHandler}
    />
  );

  const toDateFieldDesktop = (
    <DesktopDatePicker
      label={t('search.toDate')}
      renderInput={(params) => <TextField {...params} size="small" fullWidth />}
      value={searchParams.toDate}
      onChange={toDateChangeHandler}
    />
  );

  const onSubmitHandler = (event: React.SyntheticEvent<HTMLFormElement>) => {
    dispatch(setShowSearchInfo(false));
    if (category === SearchCategoryType.Kunngjoring && searchParams.searchString != '') {
      dispatch(kunngjoringSearchAsync(rettighetstype, searchParams, searchParams.itemCount));
    } else if (category === SearchCategoryType.Sak && searchParams.searchString != '') {
      dispatch(sakSearchAsync(rettighetstype, searchParams, searchParams.itemCount));
    } else {
      dispatch(setPressedSearchButtonWithoutSearhString(true));
      dispatch(resetSearchSak({ id: rettighetstype, loading: false }));
      dispatch(resetSearchKunngjoring({ id: rettighetstype, loading: false }));
    }
    event.preventDefault();
  };

  return (
    <div>
      <form onSubmit={onSubmitHandler}>
        <PsView max="sm">
          <div>
            <SelectSearchTypeControl
              searchType={searchType}
              onSelectChange={searchTypeChangeHandler}
              fullWidth={true}
              rettighetsType={rettighetstype}
            />
            <PsBox mt={1}>{searchInputField}</PsBox>
            {/* <PsBox mt={1}>
              <SelectKunngjoringTypeControl
                selected={kunngjoringType}
                disabled={category === SearchCategoryType.Sak}
                onSelectChange={kunngjoringTypeChangeHandler}
                fullWidth={true}
              />
            </PsBox> */}
            <PsBox mt={1}>
              <SelectPeriodTypeControl
                selected={periodType}
                onSelectChange={periodTypeChangeHandler}
                fullWidth={true}
              />
            </PsBox>
            {periodType === SearchPeriodType.Custom && <PsBox mt={2}>{fromDateFieldMobile}</PsBox>}
            {periodType === SearchPeriodType.Custom && <PsBox mt={2}>{toDateFieldMobile}</PsBox>}
            <PsBox mt={2}>
              <PsButton type="submit" fluid>
                <span>{t('search.sok')}</span>
              </PsButton>
            </PsBox>
          </div>
        </PsView>
        <PsView min="sm">
          <div>
            <PsBox flex mt={1}>
              <SelectSearchTypeControl
                searchType={searchType}
                onSelectChange={searchTypeChangeHandler}
                rettighetsType={rettighetstype}
              />
              <div style={{ flexGrow: '1' }}>
                <PsBox ml={1}>{searchInputField}</PsBox>
              </div>
            </PsBox>
            <PsBox flex mt={1}>
              {/* <SelectKunngjoringTypeControl
                selected={kunngjoringType}
                disabled={category === SearchCategoryType.Sak}
                onSelectChange={kunngjoringTypeChangeHandler}
                fullWidth={true}
              /> 
              <PsBox mr={1}></PsBox>*/}
              <SelectPeriodTypeControl
                selected={periodType}
                onSelectChange={periodTypeChangeHandler}
                fullWidth={true}
              />
            </PsBox>
            {periodType === SearchPeriodType.Custom && (
              <PsBox mt={2} flex>
                {fromDateFieldDesktop}
                <PsBox mr={1}></PsBox>
                {toDateFieldDesktop}
              </PsBox>
            )}
            <PsBox mt={2} flex justifyContent="flex-end">
              <PsButton type="submit">
                <span>{t('search.sok')}</span>
              </PsButton>
            </PsBox>
          </div>
        </PsView>
      </form>
    </div>
  );
};

export default SearchPanel;
