import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import ImageInListItem from '../../../shared/components/ImageInListeItem';
import ListItem from '../../../shared/components/ListItem';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { Kunngjoring } from '../../types/entities';

import DateContent from './DateContent';
import MainContent from './MainContent';

interface Props {
  kunngjoring: Kunngjoring;
}

const VaremerkeKunngjoringListItem: FunctionComponent<Props> = ({ kunngjoring }: Props) => {
  const rettighetstype = useRettighetstype();
  const redirectLink = `/${rettighetstype}/kunngjoring/${kunngjoring.sakIdentification}/${kunngjoring.id}`;

  return (
    <ListItem redirectLink={redirectLink}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <Box sx={{ padding: { xs: 4, sm: 1, lg: 2 } }}>
          <ImageInListItem
            markFeature={kunngjoring.sakDetails.markFeature?.token || ''}
            image={kunngjoring.sakDetails.image}
            hasImage={kunngjoring.hasImage}
            title={kunngjoring.sakDetails.title || ''}
          />
        </Box>
        <Box
          sx={{
            marginLeft: { xs: 0, sm: 3 },
            width: '100%'
          }}
        >
          <MainContent kunngjoring={kunngjoring} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <DateContent kunngjoring={kunngjoring} />
        </Box>
      </Box>
    </ListItem>
  );
};

export default VaremerkeKunngjoringListItem;
