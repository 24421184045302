import React, { FunctionComponent, useEffect } from 'react';

import { PsAlert, PsBox, PsBreadcrumb, PsContainer, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import Loader from '../../../shared/components/Loader';
import TechnicalErrorMessage from '../../../shared/components/TechnicalErrorMessage';
import TimelineComponent from '../../../shared/components/Timeline';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { loadPatentSakDetailsAsync } from '../../store/patent-sak-details/service';

import DetailedViewHeader from './DetailedViewHeader';
import PatentInfo from './PatentInfo';

const PatentSakScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const routeParams = useParams();
  const rettighetstype = useRettighetstype();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (patentSakDetails.sakIdentification !== routeParams.id) {
      dispatch(loadPatentSakDetailsAsync(routeParams.id));
    }
  }, []);

  const { loaded, error, patentSakDetails } = useAppSelector((gs) => gs.patentSakDetailsState);

  if (!loaded) {
    return <Loader />;
  }

  if (error) {
    return <TechnicalErrorMessage />;
  }

  return (
    <PsContainer pFluid>
      <PsBreadcrumb>
        <li>
          <Link to="/">Tidende</Link>
        </li>
        <li>
          <Link to={`/${rettighetstype}`}>{t(rettighetstype)}</Link>
        </li>
        <li>
          <Link to="#">{patentSakDetails.applicationNumber}</Link>
        </li>
      </PsBreadcrumb>
      <PsBox gutter>
        <PsText type="title1">
          <span>{t('sak.sak')}</span>
        </PsText>
      </PsBox>
      <DetailedViewHeader />
      <PatentInfo />
      <PsBox mb={-2} mt={2}>
        <PsAlert severity="info">{t('sak.timelineInfo')}</PsAlert>
      </PsBox>
      <TimelineComponent />
    </PsContainer>
  );
};
export default PatentSakScreen;
