import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { Box, CircularProgress, Modal } from '@mui/material';

import { BlobContainer } from '../../search/types/enums';
import useMedia from '../hooks/useMedia';
import { Image } from '../types/entities';

interface Props {
  image: Image;
  hasImage: boolean;
  detailedView?: boolean;
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '90vw',
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: { xs: 2, sm: 4 }
};

const ImageContentCard: FunctionComponent<Props> = ({ image, hasImage, detailedView = false }: Props) => {
  const [isInView, setIsInView] = useState(false);
  const [open, setOpen] = React.useState(false);

  const { mediaSrc: imageSrc, mediaLoaded: imageLoaded } = useMedia(hasImage, image.miniature, isInView);
  const { mediaSrc: modalImageSrc, mediaLoaded: modalImageLoaded } = useMedia(
    hasImage,
    image.medium,
    open,
    BlobContainer.medium
  );
  const containerRef = useRef(null);

  const callBackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    if (entry.intersectionRatio > 0 || detailedView === true) {
      setIsInView(true);
    }
  };

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callBackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);

  const imageMaxWidth = '120px';

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          width: { sm: imageMaxWidth },
          height: '100%',
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={(event) => {
          event.stopPropagation();
          handleOpen();
        }}
      >
        <img ref={containerRef} src={imageSrc} style={{ maxWidth: imageMaxWidth, maxHeight: '100px' }} />
        {!imageLoaded && <CircularProgress />}
      </Box>
      {modalImageLoaded && (
        <Modal
          open={open}
          onClose={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();
            handleClose();
          }}
        >
          <Box sx={modalStyle}>
            <img src={modalImageSrc} style={{ maxWidth: '75vw', maxHeight: '80vh' }} />
            {!modalImageLoaded && <CircularProgress />}
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ImageContentCard;
