import React, { FunctionComponent, useEffect } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsBreadcrumb, PsButton, PsContainer, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { getPublikasjonStateByRettighet } from '../../../publikasjon/store/publikasjon/selectors';
import { loadPublikasjonsAsync } from '../../../publikasjon/store/publikasjon/service';
import { getCategoryStateByRettighetAndPublikasjonNr } from '../../../publikasjon/store/publikasjon-category/selectors';
import { loadPublikasjonCategoriesAsync } from '../../../publikasjon/store/publikasjon-category/service';
import DownloadErrorMessage from '../../../shared/components/DownloadErrorMessage';
import Loader from '../../../shared/components/Loader';
import TechnicalErrorMessage from '../../../shared/components/TechnicalErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { formatPublicationName, truncate } from '../../../shared/utils/text-utils';
import { getDesignKunngjoringStateById } from '../../store/kunngjoring-design/selectors';
import { loadDesignKunngjoringDetailsAsync } from '../../store/kunngjoring-design/service';
import { getPatentKunngjoringStateById } from '../../store/kunngjoring-patent/selectors';
import { loadPatentKunngjoringDetailsAsync } from '../../store/kunngjoring-patent/service';
import { getVaremerkeKunngjoringStateById } from '../../store/kunngjoring-varemerke/selectors';
import { loadVaremerkeKunngjoringDetailsAsync } from '../../store/kunngjoring-varemerke/service';
import { DesignKunngjoringDetails, PatentKunngjoringDetails, VaremerkeKunngjoringDetails } from '../../types/entities';

import KunngjoringDesignPanel from './KunngjoringDesignPanel';
import KunngjoringNotConnectedToCasePanel from './KunngjoringNotConnectedToCasePanel';
import KunngjoringPatentPanel from './KunngjoringPatentPanel';
import KunngjoringVaremerkePanel from './KunngjoringVaremerkePanel';
import PrintKunngjoring from './PrintKunngjoring';

const KunngjoringScreen: FunctionComponent = () => {
  const { i18n, t } = useTranslation();
  const { id, sakId } = useParams();
  const kunngjoringId = id || '';
  const rettighetstype = useRettighetstype();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const navigatedToFromSearchOrSak = searchParams.get('fromSak');
  const publikasjonState = useAppSelector((gs) => gs.publikasjonState);

  let kunngjoring: VaremerkeKunngjoringDetails | PatentKunngjoringDetails | DesignKunngjoringDetails | undefined;
  let kunngjoringLoaded = false;
  let kunngjoringError: string | undefined;
  let downloadError: string | undefined;

  switch (rettighetstype) {
    case 'varemerke':
      const varemerkeKunngjoringDetailsState = useAppSelector((gs) => gs.varemerkeKunngjoringDetailsState);
      const varemerkeKunngjoringState = getVaremerkeKunngjoringStateById(
        varemerkeKunngjoringDetailsState,
        kunngjoringId
      );
      kunngjoring = varemerkeKunngjoringState.details;
      kunngjoringLoaded = varemerkeKunngjoringState.loaded;
      kunngjoringError = varemerkeKunngjoringState.error;
      downloadError = varemerkeKunngjoringState.fileDownloadError;
      break;
    case 'patent':
      const patentKunngjoringDetailsState = useAppSelector((gs) => gs.patentKunngjoringDetailsState);
      const patentKunngjoringState = getPatentKunngjoringStateById(patentKunngjoringDetailsState, kunngjoringId);
      kunngjoring = patentKunngjoringState.details;
      kunngjoringLoaded = patentKunngjoringState.loaded;
      kunngjoringError = patentKunngjoringState.error;
      downloadError = patentKunngjoringState.fileDownloadError;

      break;
    case 'design':
      const designKunngjoringDetailsState = useAppSelector((gs) => gs.designKunngjoringDetailsState);
      const designKunngjoringState = getDesignKunngjoringStateById(designKunngjoringDetailsState, kunngjoringId);
      kunngjoring = designKunngjoringState.details;
      kunngjoringLoaded = designKunngjoringState.loaded;
      kunngjoringError = designKunngjoringState.error;
      downloadError = designKunngjoringState.fileDownloadError;
      break;
  }
  const publikasjonId = kunngjoring?.publikasjonData?.tidendeNumber;
  const publikasjonCategoryId = kunngjoring?.publikasjonData?.categoryId;
  const kunngjoringSakId = kunngjoring?.sakIdentification || '';

  const publikasjonStateByRettighet = getPublikasjonStateByRettighet(publikasjonState, rettighetstype);
  const { publikasjoner, loaded: publikasjonerLoaded, error: publikasjonerError } = publikasjonStateByRettighet;
  const selectedPublikasjon = publikasjoner.find((x) => x.id === publikasjonId);

  const categoryState = useAppSelector((gs) => gs.publikasjonCategoryState);
  const categoryStateByPublikasjon = getCategoryStateByRettighetAndPublikasjonNr(
    categoryState,
    rettighetstype,
    publikasjonId
  );
  const {
    content: categories,
    loaded: publikasjonCategoriesLoaded,
    error: publikasjonCategoriesError
  } = categoryStateByPublikasjon;
  const selectedCategory = categories.find((x) => x.categoryId === publikasjonCategoryId);
  let categoryName: string | undefined;
  if (!!selectedCategory) {
    categoryName = i18n.language === 'no' ? selectedCategory.norwegianDescription : selectedCategory.englishDescription;
  } else {
    categoryName = t('publikasjon.ikkeFunnetTittel');
  }

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!kunngjoringLoaded) {
      switch (rettighetstype) {
        case 'varemerke':
          dispatch(loadVaremerkeKunngjoringDetailsAsync(kunngjoringId, sakId));
          break;
        case 'patent':
          dispatch(loadPatentKunngjoringDetailsAsync(kunngjoringId, sakId));
          break;
        case 'design':
          dispatch(loadDesignKunngjoringDetailsAsync(kunngjoringId, sakId));
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (!publikasjonerLoaded) {
      dispatch(loadPublikasjonsAsync(rettighetstype));
    }
  }, []);

  useEffect(() => {
    if (selectedPublikasjon && !publikasjonCategoriesLoaded) {
      dispatch(loadPublikasjonCategoriesAsync(selectedPublikasjon.id, rettighetstype));
    }
  }, [selectedPublikasjon, rettighetstype]);

  const error = kunngjoringError || publikasjonerError || publikasjonCategoriesError;
  // TODO: toggle tilbake loading check på kategorier.
  // Vi har fjernet dette for å kunne laste kunngjøringer i test hvor publikajonsnummer ikke finnes
  //const loading = !error && (!kunngjoringLoaded || !publikasjonerLoaded || !publikasjonCategoriesLoaded);
  const loading = !error && (!kunngjoringLoaded || !publikasjonerLoaded);

  let kunngjoringPanel: JSX.Element | undefined;
  if (kunngjoringLoaded) {
    // Kunngjøringer som ikke er knyttet til en sak vil ha sakIdentification rettighetstypeshort (VM, DS, PT) + gruppeid - eks PT1200 og VM0400
    if (kunngjoring?.sakIdentification.length == 6) {
      kunngjoringPanel = (
        <KunngjoringNotConnectedToCasePanel category={selectedCategory} kunngjoringDetails={kunngjoring} />
      );
    } else {
      switch (rettighetstype) {
        case 'varemerke':
          kunngjoringPanel = (
            <KunngjoringVaremerkePanel
              category={selectedCategory}
              kunngjoringDetails={kunngjoring as VaremerkeKunngjoringDetails}
            />
          );
          break;
        case 'patent':
          kunngjoringPanel = (
            <KunngjoringPatentPanel
              category={selectedCategory}
              kunngjoringDetails={kunngjoring as PatentKunngjoringDetails}
            />
          );
          break;
        case 'design':
          kunngjoringPanel = (
            <KunngjoringDesignPanel
              category={selectedCategory}
              kunngjoringDetails={kunngjoring as DesignKunngjoringDetails}
            />
          );
          break;
      }
    }
  }

  return (
    <>
      <PsContainer color="primary">
        <PsBreadcrumb color="primary">
          <li>
            <Link to="/">Tidende</Link>
          </li>
          <li>
            <Link to={`/${rettighetstype}`}>{t(rettighetstype)}</Link>
          </li>
          <li>
            <Link to={`/${rettighetstype}/publikasjoner`}>{t('publikasjon.publikasjoner')}</Link>
          </li>
          <li>
            <Link to={`/${rettighetstype}/publikasjoner/${publikasjonId}`}>
              {formatPublicationName(t, selectedPublikasjon)}
            </Link>
          </li>
          <li>
            <Link to={`/${rettighetstype}/publikasjoner/${publikasjonId}/${publikasjonCategoryId}`}>
              {`${truncate(categoryName)}`}
            </Link>
          </li>
          <li>
            <Link to="#">{t('kunngjoring.kunngjoring')}</Link>
          </li>
        </PsBreadcrumb>
      </PsContainer>
      <PsContainer color="secondary" htmlMarkup="main" pFluid fluid>
        <Box sx={{ paddingBottom: { xs: 0, sm: 15 } }}>
          <PsContainer color="secondary">
            <div>
              {navigatedToFromSearchOrSak && (
                <PsBox mt={1} pt={2}>
                  <PsButton color="secondary-o" tabIndex={-1} onClick={() => navigate(-1)}>
                    <span>{t('kunngjoring.gaaTilbakeTilSak')}</span>
                  </PsButton>
                </PsBox>
              )}
              <PsBox flex justifyContent="space-between" alignItems="center" gutter>
                <PsText type="title1">
                  <span>{t('kunngjoring.kunngjoring')}</span>
                </PsText>
                <div>
                  <PrintKunngjoring
                    kunngjoringId={kunngjoringId}
                    saksId={kunngjoringSakId}
                    rettighetstype={rettighetstype}
                    publikasjonNumber={selectedPublikasjon?.id}
                  />
                </div>
              </PsBox>
            </div>
          </PsContainer>
          <PsContainer color="primary">
            <div>
              {loading && <Loader />}
              {error && <TechnicalErrorMessage />}
              {downloadError && <DownloadErrorMessage />}
              <div>{kunngjoringPanel}</div>
            </div>
          </PsContainer>
        </Box>
      </PsContainer>
    </>
  );
};

export default KunngjoringScreen;
