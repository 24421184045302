import React from 'react';

import { Box } from '@mui/material';
import { PsBox, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { formatParticipant } from '../../../shared/utils/text-utils';
import { formatJsonTimeString, manualTranslation } from '../../../shared/utils/translation';
import { baseStyles } from '../../styles/BaseBoxStyles';
import { KunngjoringDetails } from '../../types/entities';

import DownloadAttachment from './DownloadAttachment';

interface Props {
  details: KunngjoringDetails;
}

const CategorySpecificEventDetails: React.FunctionComponent<Props> = ({ details }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {details.basicRecord?.recordFilingDate && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>{t('kunngjoring.requestFilingDate')}:</span>
          </PsText>
          <PsBox ml={1}>
            <PsText>
              <span>{formatJsonTimeString(details.basicRecord.recordFilingDate)}</span>
            </PsText>
          </PsBox>
        </Box>
      )}
      {details.basicRecord?.attorney && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>{t('kunngjoring.claimantAttorney')}:</span>
          </PsText>
          <PsBox ml={1}>
            <PsText>
              <span>{formatParticipant(details.basicRecord.attorney)}</span>
            </PsText>
          </PsBox>
        </Box>
      )}
      {details.eventData?.cnComplaintCompanyName && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsBox mr={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.companyName')}:</span>
            </PsText>
          </PsBox>
          <PsText>
            <span>
              <span>{details.eventData.cnComplaintCompanyName}</span>
            </span>
          </PsText>
        </Box>
      )}
      {details.basicRecord?.claimant && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>
              {details.basicRecord.claimant.length > 1 ? t('kunngjoring.claimants') : t('kunngjoring.claimant')}:
            </span>
          </PsText>
          <PsBox ml={1} flex flexDirection="column">
            {details.basicRecord.claimant.map((item) => (
              <PsText key={item.name}>
                <span>{formatParticipant(item)}</span>
              </PsText>
            ))}
          </PsBox>
        </Box>
      )}
      {details.eventData?.previousAgent && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>
              {details.eventData.previousAgent.length > 1 ? t('kunngjoring.priorAgents') : t('kunngjoring.priorAgent')}:
            </span>
          </PsText>
          <PsBox ml={1} flex flexDirection="column">
            {details.eventData.previousAgent.map((item) => (
              <PsText key={item.name}>
                <span>{formatParticipant(item)}</span>
              </PsText>
            ))}
          </PsBox>
        </Box>
      )}
      {details.eventData?.previousPropretior && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>
              {details.eventData.previousPropretior.length > 1
                ? t('kunngjoring.priorHolders')
                : t('kunngjoring.priorHolder')}
              :
            </span>
          </PsText>
          <PsBox ml={1} flex flexDirection="column">
            {details.eventData.previousPropretior.map((item) => (
              <PsText key={item.name}>
                <span>{formatParticipant(item)}</span>
              </PsText>
            ))}
          </PsBox>
        </Box>
      )}
      {details.eventData?.cnComplaintGroundsText && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsBox mr={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.claim')}:</span>
            </PsText>
          </PsBox>
          <PsText>
            <span>{details.eventData?.cnComplaintGroundsText}</span>
          </PsText>
        </Box>
      )}
      {details.eventData?.decision?.authorithy && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsBox mr={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.decisionAuthority')}:</span>
            </PsText>
          </PsBox>
          <PsText>
            <span>{details.eventData?.decision?.authorithy}</span>
          </PsText>
        </Box>
      )}
      {details.eventData?.decision?.finalDecisionDate && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsBox mr={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.finalDecisionDate')}:</span>
            </PsText>
          </PsBox>
          <PsText>
            <span>{formatJsonTimeString(details.eventData?.decision?.finalDecisionDate)}</span>
          </PsText>
        </Box>
      )}
      {details.eventData?.decision && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>{t('kunngjoring.decision')}:</span>
          </PsText>
          <PsBox ml={1}>
            {details.eventData.decision.decisions.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  '&:not(:last-child)': {
                    marginBottom: 1
                  }
                }}
              >
                <PsText>
                  <span>{item.title}</span>
                </PsText>
                <PsText>
                  <span>{item.content}</span>
                </PsText>
              </Box>
            ))}
          </PsBox>
        </Box>
      )}
      {details.eventData?.licence?.contractType && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>{t('kunngjoring.agreementType')}:</span>
          </PsText>
          <PsBox ml={1}>
            <PsText>
              <span>{manualTranslation(i18n, details.eventData.licence.contractType)}</span>
            </PsText>
          </PsBox>
        </Box>
      )}
      {details.eventData?.licence?.licenceReference && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>{t('kunngjoring.annotationID')}:</span>
          </PsText>
          <PsBox ml={1}>
            <PsText>
              <span>{details.eventData.licence.licenceReference}</span>
            </PsText>
          </PsBox>
        </Box>
      )}
      {details.eventData?.licence?.scope && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>{t('kunngjoring.scope')}:</span>
          </PsText>
          <PsBox ml={1}>
            <PsText>
              <span>{details.eventData.licence.scope}</span>
            </PsText>
          </PsBox>
        </Box>
      )}
      {details.eventData?.licence?.licenceParties && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>{t('kunngjoring.agreementParties')}:</span>
          </PsText>

          <PsBox ml={1} flex flexDirection="column">
            {details.eventData.licence?.licenceParties.map((item) => (
              <PsText key={item.name}>
                <span>{formatParticipant(item)}</span>
              </PsText>
            ))}
          </PsBox>
        </Box>
      )}
      {details.eventData?.freeText && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>{t('kunngjoring.message')}:</span>
          </PsText>
          <PsBox ml={1}>
            <PsText>
              <span>{details.eventData.freeText}</span>
            </PsText>
          </PsBox>
        </Box>
      )}
      {details.eventData?.attachmentURL && (
        <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
          <PsText type="bold">
            <span>{t('kunngjoring.attachment')}:</span>
          </PsText>
          <PsBox ml={1}>
            <DownloadAttachment attachmentUrl={details.eventData?.attachmentURL} sakId={details.sakIdentification} />
          </PsBox>
        </Box>
      )}
    </>
  );
};

export default CategorySpecificEventDetails;
