import { SubCategoriesHttpRequest } from '../store/kunngjoring-types/http-request';
import { Kunngjoring } from '../types/entities';
import { FilterCheckboxValue, SelectCheckboxOptionValue } from '../types/entities';

export const sliceKunngjoringer = (
  kunngjoringer: Kunngjoring[],
  kunngjoringerPerPage: number,
  currentPage: number
): Kunngjoring[] => {
  const start = currentPage * kunngjoringerPerPage - kunngjoringerPerPage;
  const end = currentPage * kunngjoringerPerPage - kunngjoringerPerPage + kunngjoringerPerPage;
  return kunngjoringer.slice(start, end);
};

export const getListOfSubCategories = async (selectedCategory: string): Promise<FilterCheckboxValue[]> => {
  const filterSubCategories: FilterCheckboxValue[] = [];
  const subCategories: SelectCheckboxOptionValue[] = await SubCategoriesHttpRequest.getSubCategories(
    selectedCategory
  ).then((response) => response.data);
  for (const subCategory of subCategories) {
    filterSubCategories.push({ checked: false, optionValue: subCategory });
  }
  return filterSubCategories;
};
