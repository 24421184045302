export default {
  representative: 'Fullmektig',
  holder: 'Innehaver',
  holders: 'Innehavere',
  applicant: 'Søker',
  applicants: 'Søkere',
  registered: 'Registrert/Gitt virkning',
  classes: 'Klasser',
  received: 'Innlevert',
  generalAvailable: 'Allment tilgjengelig',
  natureOfMark: 'Merketype',
  markText: 'Merketekst',
  markDescription: 'Beskrivelse av merket',
  markColorDescription: 'Beskrivelse av fargen',
  markCategory: 'Merkekategori',
  productList: 'Vare-/tjenestefortegnelse',
  filterHeader: 'Filter',
  filterType: 'Fullmektig, innehaver eller søker',
  emptyVaremerkeKunngjoringer:
    'Vi beklager, men det er ingen kunngjøringer som passer til den valgte kategorien og filteret du har valgt.',
  category: 'Kunngjøringskategori',
  publicationDate: 'Kunngjøringsdato',
  notRegistered: 'Ikke registrert',
  sakDetails: 'Saksdetaljer',
  applicationType: 'Søknadstype',
  eventType: 'Kunngjøringstype',
  inventor: 'Oppfinner',
  inventors: 'Oppfinnere',
  title: 'Tittel',
  ipcClass: 'IPC-klasser',
  pdfError: 'PDF-generering feilet',
  download: 'Last ned kunngjøring',
  kunngjoring: 'Kunngjøring',
  gaaTilbakeTilSak: 'Gå tilbake til sak',
  eventDate: 'Hendelsesdato',
  expires: 'Utløpsdato',
  designer: 'Designer',
  designers: 'Designere',
  products: 'Produktangivelse',
  priority: 'Prioritet',
  currentStatus: 'Gjeldende status',
  claimant: 'Kravstiller',
  claimants: 'Kravstillere',
  claimantAttorney: 'Kravstillers fullmektig',
  requestFilingDate: 'Forespørsel innlevert',
  decision: 'Avgjørelse',
  message: 'Melding',
  errorPdf: 'Feilet med å hente pdf, bare til å prøve igjen',
  priorAgent: 'Tidligere fullmektig',
  priorAgents: 'Tidligere fullmektiger',
  priorHolder: 'Tidligere innehaver',
  priorHolders: 'Tidligere innehavere',
  annotationID: 'Avtalenummer',
  agreementType: 'Avtaletype',
  agreementParties: 'Avtaleparter',
  scope: 'Anmerking/omfang',
  description: 'Beskrivelse',
  messageData: 'Meldingsdata',
  messageDate: 'Meldingsdato',
  link: 'Link',
  companyName: 'Foretaksnavn',
  cnComplaintTitle: 'Administrativ overprøving av foretaksnavnregistrering',
  claim: 'Begrunnelse',
  decisionAuthority: 'Avgjørelse fra',
  finalDecisionDate: 'Dato avgjørelse endelig',
  attachment: 'Vedlegg',
  downloadAttachment: 'Last ned vedlegg',
  spc: {
    productIndentifier: 'Produktidentifikasjon',
    authorizationNorway: 'Markedsføringstillatelse i Norge',
    authorizationEAA: 'Markedsføringstillatelse i EØS',
    basicPatent: 'Basispatent'
  },
  associatedMarks: {
    transformedTo: 'Transformert sak',
    divisionalParent: 'Avdelt sak',
    changeOfMark: 'Endring av merke',
    associatedMark: 'Tilknyttet merke',
    statusAssociatedMark: 'Gjeldende status for tilknyttet merke'
  }
};
