import type { i18n } from 'i18next';

import { TranslationObject } from '../../shared/types/entities';

export const manualTranslation = (i18n: i18n, translationObject?: TranslationObject): string => {
  if (!translationObject) return '';
  return (() => {
    switch (i18n.language) {
      case 'no':
        return translationObject.no;
      case 'en':
        return translationObject.en;
      default:
        return translationObject.en;
    }
  })();
};

/**
 *
 * @param i18n The i18n translation module that is used in the app to translate
 * @param timeString Any Date parsable string, generally expected to be JSON or ISO8601 format
 * @param options An options object to configure how you want to show the string
 * @returns A string representation of the Date object, with the format of the language
 */
export const formatJsonTimeString = (timeString?: string) => {
  if (!timeString || timeString === '0001-01-01T00:00:00') return ' - ';
  const date = new Date(timeString);
  const langFormat = 'nb-NO';

  const formatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };

  return date.toLocaleDateString(langFormat, formatOptions);
};
