import axiosApi from '../../../shared/utils/axios-api';
import { DesignKunngjoringDetails } from '../../types/entities';

export class KunngjoringDetailsHttpRequest {
  public static getDesignKunngjoringDetails(kunngjoringId?: string, sakId?: string) {
    return axiosApi.get<DesignKunngjoringDetails>(
      `${process.env.REACT_APP_API_URL}/KunngjoringDetails/DetailedDesignKunngjoring?kunngjoringId=${kunngjoringId}&sakId=${sakId}`
    );
  }
}
