import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MessageKunngjoring } from '../../types/entities';

import { MessageKunngjoringState, initialState } from './state';

export type MessageKunngjoringSuccessAction = {
  id: string;
  messagekunngjoringer: MessageKunngjoring[];
};

export type MessageKunngjoringFailedAction = {
  id: string;
  error: string;
};

const messageKunngjoringSlice = createSlice({
  name: 'message-kunngjoring-list',
  initialState,
  reducers: {
    loadSuccess: (state: MessageKunngjoringState, action: PayloadAction<MessageKunngjoringSuccessAction>) => {
      state[action.payload.id] = {
        loaded: true,
        messagekunngjoringer: action.payload.messagekunngjoringer
      };
    },
    loadFailed: (state: MessageKunngjoringState, action: PayloadAction<MessageKunngjoringFailedAction>) => {
      state[action.payload.id] = {
        loaded: true,
        error: action.payload.error,
        messagekunngjoringer: []
      };
    }
  }
});

const { actions, reducer } = messageKunngjoringSlice;
export const { loadSuccess, loadFailed } = actions;
export default reducer;
