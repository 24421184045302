import axiosApi from '../../../shared/utils/axios-api';
import { PatentKunngjoringDetails } from '../../types/entities';

export class PatentKunngjoringDetailsHttpRequest {
  public static getPatentKunngjoringDetails(kunngjoringId?: string, sakId?: string) {
    return axiosApi.get<PatentKunngjoringDetails>(
      `${process.env.REACT_APP_API_URL}/KunngjoringDetails/DetailedPatentKunngjoring?kunngjoringId=${kunngjoringId}&sakId=${sakId}`
    );
  }
}
