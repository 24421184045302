import translation from './no';
type translationType = typeof translation;
const englishTranslation: translationType = {
  kunngjoring: 'Event',
  sak: 'Case',
  representative: 'Representative',
  sakNumber: 'Case number',
  proprietor: 'Proprietor',
  kunngjoringType: 'Event type',
  allTime: 'All time',
  last30Days: 'Last 30 days',
  lastYear: 'Last year',
  customTimeInterval: 'Custom',
  closeButton: 'Close',
  toDate: 'To date',
  fromDate: 'From date',
  date: 'Date',
  kunngjoringDate: 'Event date',
  status: 'Status',
  startPage: 'You need to provide a search to get content',
  noResults: 'No results found',
  lastKunngjoring: 'Last event',
  sok: 'Search',
  searchWithoutSearchString: 'You can not search without any search text',
  gazetteNumber: 'Gazette ID',
  of: 'of',
  registrationNumber: 'Registration number',
  patentNumber: 'Patent number'
};

export default englishTranslation;
