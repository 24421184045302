import React, { FunctionComponent, useState } from 'react';

import { TimelineConnector, TimelineSeparator } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Collapse } from '@mui/material';
import { PsBox, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-hooks';
import { useRettighetstype } from '../../hooks/useRettighetstype';
import ClickableTypography from '../ClickableTypography';

import TimelineBox from './TimelineBox';

const TimelineComponent: FunctionComponent = () => {
  const { t } = useTranslation();
  const rettighetsType = useRettighetstype();
  const sakDetails = useAppSelector((gs) => {
    switch (rettighetsType) {
      case 'varemerke':
        return gs.varemerkeSakDetailsState.varemerkeSakDetails;
      case 'patent':
        return gs.patentSakDetailsState.patentSakDetails;
      case 'design':
        return gs.designSakDetailsState.designSakDetails;
    }
  });

  let hideCount = 0;
  const hideElements: JSX.Element[] = [];
  const timelineElements: JSX.Element[] = [];

  sakDetails.kunngjoringer?.map((kunngjoring, index) => {
    const isLastElement = index === sakDetails.kunngjoringer.length - 1;
    const isTop3FirstElement = index <= 2;

    const [isExpanded, setIsExpanded] = useState(false);

    //Shows the 3 first and very last kunngjoring
    if (!isTop3FirstElement && !isLastElement) {
      hideElements.push(
        <TimelineBox
          sakId={sakDetails.sakIdentification}
          key={kunngjoring.id}
          kunngjoring={kunngjoring}
          hideConnector={false}
        />
      );
      hideCount++;
    } else {
      if (hideCount > 0 && isLastElement) {
        timelineElements.push(
          <div key={`expandable${index}`}>
            <TimelineItem key={`expandable-text-${index}`} sx={{ minHeight: '3em', marginLeft: 12.15 }}>
              <TimelineSeparator>
                <TimelineConnector />
              </TimelineSeparator>
              <ClickableTypography
                marginLeft={5}
                color="grey"
                align="center"
                hidden={isExpanded}
                callback={() => setIsExpanded(true)}
              >
                {hideCount} {t('sak.expandable')} {t('sak.clickHere')}
              </ClickableTypography>
              <ClickableTypography
                align="center"
                marginLeft={5}
                color="grey"
                hidden={!isExpanded}
                callback={() => setIsExpanded(false)}
              >
                {hideCount} {t('sak.expandable')} {t('sak.hide')}
              </ClickableTypography>
            </TimelineItem>
            <Collapse
              key={`accordion-${index}`}
              in={isExpanded}
              sx={{
                height: 0
              }}
            >
              {hideElements}
            </Collapse>
          </div>
        );
      }
      timelineElements.push(
        <TimelineBox
          key={`item-${kunngjoring.id}`}
          sakId={sakDetails.sakIdentification}
          kunngjoring={kunngjoring}
          hideConnector={isLastElement}
        />
      );
    }
  });

  return (
    <>
      <PsBox flex alignItems="center" gutter>
        <PsBox mr={1}>
          <PsText type="title4">
            <span>{t('sak.events')}</span>
          </PsText>
        </PsBox>
      </PsBox>
      <PsBox flex>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0
            }
          }}
        >
          {timelineElements}
        </Timeline>
      </PsBox>
    </>
  );
};

export default TimelineComponent;
