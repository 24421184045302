export default {
  patentLink: 'Patent',
  varemerkeLink: 'Varemerke',
  designLink: 'Design',
  representative: 'Representative',
  holder: 'Holder',
  holders: 'Holders',
  registered: 'Registered/Granted',
  classes: 'Classes',
  granted: 'Granted',
  status: 'Current status',
  event: 'Event',
  applicants: 'Applicants',
  applicant: 'Applicant',
  hits: 'Number of hits',
  hitsPerPage: ' hits per page',
  kunngjoringTypeOptions: {
    pending: 'Pending',
    annulment: 'Annulment',
    rejected: 'Rejected',
    shelved: 'Shelved',
    withdrawn: 'Withdrawn',
    finallyShelved: 'Finally shelved',
    ceasedCancelled: 'Ceased/Cancelled',
    registered: 'Registered',
    granted: 'Granted',
    ceased: 'Ceased'
  },
  generellFeilTittel: 'Technical Issue',
  generellFeilInnhold: 'Please try again later.',
  velg: 'Choose..',
  downloadErrorTittel: 'Error while downloading file',
  downloadErrorMessage: 'An error happened while trying to download the file.',
  playVideo: 'Play video',
  audioFile: 'Audio file'
};
