import { rest } from 'msw';

import varemerkeKunngjoringDetailedId1 from './detailed-view-1-varemerke-get.json';
import varemerkeKunngjoringDetailedId2 from './detailed-view-2-varemerke-get.json';

/// <summary>
/// <param name="req">an information about a matching request</param>
/// <param name="res">a functional utility to create the mocked response</param>
/// <param name="ctx">a group of functions that help to set a status code, headers, body, etc. of the mocked response.</param>
/// </summary>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getVaremerkeKunngjoringDetailsHandler = (_req: any, res: any, ctx: any) => {
  const hendelseId = _req.url.searchParams.get('id');
  if (hendelseId === '310') return res(ctx.status(200), ctx.json(varemerkeKunngjoringDetailedId1));
  return res(ctx.status(200), ctx.json(varemerkeKunngjoringDetailedId2));
};

export const varemerkeKunngjoringDetailsHandlers = [
  rest.get(`${process.env.REACT_APP_SERVER_URL}/detailed`, getVaremerkeKunngjoringDetailsHandler)
];
