import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsLink, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { formatApplicantOrHolder, formatParticipant } from '../../../shared/utils/text-utils';
import { manualTranslation } from '../../../shared/utils/translation';
import { SakFromSearch } from '../../types/entities';

interface Props {
  sak: SakFromSearch;
}

const MainContentCard: FunctionComponent<Props> = ({ sak }: Props) => {
  const { t, i18n } = useTranslation();
  const rettighetstype = useRettighetstype();

  return (
    <Box
      sx={{
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginX: { xs: 0, sm: 3 },
        overflow: 'hidden',
        textOverflow: 'nowrap'
      }}
    >
      <PsLink>
        <PsText>
          <span>
            {sak.applicationNumber} {sak.registrationNumber && `(${sak.registrationNumber})`}
          </span>
        </PsText>
      </PsLink>
      <PsBox flex flexDirection="row" mt={1}>
        {rettighetstype === 'patent' && (
          <PsText type="bold">
            <span>{t('kunngjoring.title')}:</span>
          </PsText>
        )}
        {rettighetstype === 'design' && (
          <PsText type="bold">
            <span>{t('kunngjoring.products')}:</span>
          </PsText>
        )}
        {rettighetstype === 'varemerke' && (
          <PsText type="bold">
            <span>{t('kunngjoring.markText')}:</span>
          </PsText>
        )}
        <Box
          sx={{
            marginLeft: 1,
            display: 'inline-block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          <PsText>
            <span>{sak.title ?? ''}</span>
          </PsText>
        </Box>
      </PsBox>
      <PsBox>{formatApplicantOrHolder(t, sak.proprietors, sak.applicants)}</PsBox>
      <PsBox flex flexDirection="row">
        <PsText type="bold">
          <span>{t('shared.representative')}:</span>
        </PsText>
        <PsBox ml={1}>
          <PsText>
            <span>{sak.agent ? formatParticipant(sak.agent) : t('kunngjoring.notRegistered')}</span>
          </PsText>
        </PsBox>
      </PsBox>
      <PsBox flex flexDirection="row">
        <PsText type="bold">
          <span>{t('shared.status')}:</span>
        </PsText>
        <PsBox ml={1}>
          <PsText>
            <span>{manualTranslation(i18n, sak.highLevelDescription)}</span>
          </PsText>
        </PsBox>
      </PsBox>
    </Box>
  );
};

export default MainContentCard;
