import { DefaultBodyType, MockedRequest, RestHandler, setupWorker } from 'msw';

import { varemerkeKunngjoringDetailsHandlers as varemerkeKunngjoringDetailsHandlers } from '../modules/kunngjoring/mocks/detailed-view-handler';
import { varemerkeKunngjoringHandlers } from '../modules/kunngjoring/mocks/varemerke-kunngjoring-handlers';

const miscHandlers: RestHandler<MockedRequest<DefaultBodyType>>[] = [];

// turn off MSW warnings for specific routes
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onUnhandledRequest = (req: any, print: any) => {
  // specify routes to exclude
  const excludedRoutes = ['/favicon.ico', '/manifest.json', '/logo192.png'];

  // check if the req.url.pathname contains excludedRoutes
  const isExcluded = excludedRoutes.some((route) => req.url.pathname.includes(route));

  if (isExcluded) {
    return;
  }

  if (req.url.pathname.startsWith('/chunks/')) {
    return;
  }

  print.warning();
};

export const initMockServiceWorker = () => {
  const combinedHandlers = [...miscHandlers, ...varemerkeKunngjoringHandlers, ...varemerkeKunngjoringDetailsHandlers];
  const worker = setupWorker(...combinedHandlers);
  worker.start({ onUnhandledRequest });
};
