export const baseStyles = {
  baseBoxStyleHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: 2,
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;'
  },
  baseBoxStyleInfoPart: {
    display: 'flex',
    alignItems: 'center',
    padding: 1,
    marginTop: { xs: 1, sm: 0 },
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;'
  },
  baseStyleSakInfoPart: {
    display: 'flex',
    alignItems: 'center',
    marginTop: { xs: 1, sm: 0 }
  }
};

export const headerStyles = {
  topBox: {
    ...baseStyles.baseBoxStyleHeader,
    borderBottom: '2px dashed',
    borderColor: 'mediumGrey.main',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: 'space-between'
  }
};
