import { Kunngjoring } from '../../types/entities';

export interface KunngjoringStateById {
  loaded?: boolean;
  error?: string;
  kunngjoringer: Kunngjoring[];
}

export interface KunngjoringState {
  [id: string]: KunngjoringStateById;
}

export const initialState: KunngjoringState = {};
