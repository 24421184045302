import { SearchParamState } from '../store/search-param/state';

type KeyValuePair = {
  key: string;
  value?: string;
};

export const createQueryParams = (searchProps: SearchParamState, itemCount: number): string => {
  const queryParams: KeyValuePair[] = [
    { key: 'searchString', value: searchProps.searchString || '' },
    { key: 'fromDate', value: searchProps.fromDate || '' },
    { key: 'toDate', value: searchProps.toDate || '' },
    { key: 'searchType', value: searchProps.searchType },
    { key: 'itemCount', value: itemCount?.toString() || '' }
  ].filter(
    (keyValuePair) => keyValuePair.value !== undefined && keyValuePair.value !== null && keyValuePair.value !== ''
  );
  return queryParams.map((obj: KeyValuePair) => (!!obj.value ? `${obj.key}=${encodeURI(obj.value)}` : '')).join('&');
};
