import React, { FunctionComponent } from 'react';

import {
  PsBox,
  PsBreadcrumb,
  PsContainer,
  PsDesignIcon,
  PsLinkList,
  PsLinkListItem,
  PsPatentIcon,
  PsText,
  PsVaremerkeIcon
} from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Rettighetstype = 'varemerke' | 'patent' | 'design';

const Home: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const listClickHandler = (e: React.MouseEvent<HTMLPsLinkListItemElement>, rettighetstype: Rettighetstype) => {
    navigate(`/${rettighetstype}`, {});
    e.preventDefault();
  };

  return (
    <PsContainer color="primary" fluid htmlMarkup="main">
      <PsContainer>
        <PsBreadcrumb>
          <li>
            <a href="#">
              <span>{t('tidende')}</span>
            </a>
          </li>
        </PsBreadcrumb>
      </PsContainer>
      <PsContainer fluid special bgImgUrl="../hero-pyramids.jpg" bgImgFrame>
        <PsContainer>
          <PsBox py={10} gutter>
            <PsText type="hero" color="white">
              <span>{t('events')}</span>
            </PsText>
            <PsText color="white">
              <span>{t('tidende_subtitle')}</span>
            </PsText>
          </PsBox>
        </PsContainer>
      </PsContainer>
      <PsContainer htmlMarkup="main">
        <PsBox pt={5}>
          <PsLinkList>
            <PsLinkListItem
              key={1}
              label={t('tidende_varemerke')}
              href="/varemerke"
              onClick={(e) => listClickHandler(e, 'varemerke')}
            >
              <PsVaremerkeIcon slot="icon" />
            </PsLinkListItem>
            <PsLinkListItem
              key={2}
              label={t('tidende_patent')}
              href="/patent"
              onClick={(e) => listClickHandler(e, 'patent')}
            >
              <PsPatentIcon slot="icon" />
            </PsLinkListItem>
            <PsLinkListItem
              key={3}
              label={t('tidende_design')}
              href="/design"
              onClick={(e) => listClickHandler(e, 'design')}
            >
              <PsDesignIcon slot="icon" />
            </PsLinkListItem>
          </PsLinkList>
        </PsBox>
      </PsContainer>
    </PsContainer>
  );
};

export default Home;
