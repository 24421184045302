import React, { FunctionComponent } from 'react';

import { PsAlert, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

interface Props {
  color?: 'primary' | 'secondary';
}

const TechnicalErrorMessage: FunctionComponent<Props> = ({ color = 'primary' }: Props) => {
  const { t } = useTranslation();

  return (
    <PsAlert color={color} label={t('shared.generellFeilTittel')} severity="danger">
      <span>
        <PsText>
          <span>{t('shared.generellFeilInnhold')}</span>
        </PsText>
      </span>
    </PsAlert>
  );
};

export default TechnicalErrorMessage;
