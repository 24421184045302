import React, { type FunctionComponent } from 'react';

import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Card, SxProps, useTheme } from '@mui/material';
import { PsBox, PsMaterialIcon, PsText, PsView } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { SakKunngjoring } from '../../../sak/types/entities';
import { useRettighetstype } from '../../hooks/useRettighetstype';
import { isFutureDate } from '../../utils/date-utils';
import { formatJsonTimeString, manualTranslation } from '../../utils/translation';

interface TimelineBoxProps {
  kunngjoring: SakKunngjoring;
  hideConnector?: boolean;
  sxOverride?: SxProps;
  sakId: string;
}

const TimelineBox: FunctionComponent<TimelineBoxProps> = ({
  kunngjoring,
  hideConnector = false,
  sxOverride,
  sakId
}) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const rettighetstype = useRettighetstype();
  const theme = useTheme();

  let kunngjoringDetailsLink = `/${rettighetstype}/kunngjoring/${sakId}/${kunngjoring.id}?fromSak=true`;

  if (kunngjoring.spcNumber != null) {
    kunngjoringDetailsLink = `/${rettighetstype}/kunngjoring/PT${kunngjoring.spcNumber}/${kunngjoring.id}?fromSak=true`;
  }

  return (
    <TimelineItem sx={{ minHeight: 0, ...sxOverride }}>
      <PsView min="sm">
        <PsBox mr={2}>
          {isFutureDate(kunngjoring.publicationDate) ? (
            <PsText color="light-gray">
              <span>{formatJsonTimeString(kunngjoring.publicationDate)}</span>
            </PsText>
          ) : (
            <PsText>
              <span>{formatJsonTimeString(kunngjoring.publicationDate)}</span>
            </PsText>
          )}
        </PsBox>
      </PsView>
      <TimelineSeparator>
        <div
          style={{
            display: 'flex',
            width: 25,
            height: 25,
            backgroundColor: theme.palette.timelinePurple.main,
            borderRadius: '50%',
            border: '2px solid black'
          }}
        />
        {!hideConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent sx={{ pt: 0 }}>
        <PsView max="sm">
          <PsBox mr={2} mb={1} mt={-1} root={{ class: 'titleWithoutMargin' }}>
            <PsText>
              <span>{formatJsonTimeString(kunngjoring.publicationDate)}</span>
            </PsText>
          </PsBox>
        </PsView>
        <Card
          onClick={() => navigate(kunngjoringDetailsLink)}
          sx={{
            display: 'flex',
            padding: 1,
            marginTop: -1,
            borderLeft: 7,
            mb: 2,
            backgroundColor: 'transparent',
            borderLeftColor: theme.palette.timelinePurple.main,
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: 'rgba(0, 52, 46, 0.05)',
              cursor: 'pointer'
            }
          }}
        >
          <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <PsBox mr={1}>
              <PsText type="bold">
                <span>{manualTranslation(i18n, kunngjoring.highLevelDescription)}</span>
              </PsText>
            </PsBox>
            <PsText>
              <span>{manualTranslation(i18n, kunngjoring.subLevelDescription)}</span>
            </PsText>
            {kunngjoring.spcNumber && `PT${kunngjoring.spcNumber}` != sakId && (
              <PsBox ml={1}>
                <PsText>
                  <span>SPC {kunngjoring.spcNumber}</span>
                </PsText>
              </PsBox>
            )}
          </Box>
          <PsMaterialIcon symbol="east" />
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimelineBox;
