import React, { FunctionComponent, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { PsButton, PsMaterialIcon } from '@patentstyret/designsystem-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../../shared/hooks/redux-hooks';
import { Rettighetstype } from '../../../../shared/types/entities';
import { mapRettighetstypeShort } from '../../../../shared/utils/rettighetstype-utils';
import { loadFileDownloadError as designLoadFileDownloadError } from '../../../store/kunngjoring-design/reducer';
import { loadFileDownloadError as patentLoadFileDownloadError } from '../../../store/kunngjoring-patent/reducer';
import { PrintKunngjoringHttpRequest } from '../../../store/kunngjoring-print/http-request';
import { loadFileDownloadError as varemerkeLoadFileDownloadError } from '../../../store/kunngjoring-varemerke/reducer';

interface Props {
  kunngjoringId: string;
  saksId: string;
  rettighetstype: Rettighetstype;
  publikasjonNumber?: string;
}

const PrintKunngjoring: FunctionComponent<Props> = ({ kunngjoringId, publikasjonNumber, rettighetstype, saksId }) => {
  const { t } = useTranslation();
  const [pdfIsLoading, setPdfIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const rettighetstypeShort = mapRettighetstypeShort(rettighetstype);

  const dispatchError = (errorMessage: string) => {
    switch (rettighetstype) {
      case 'patent':
        dispatch(patentLoadFileDownloadError({ id: kunngjoringId, fileDownloadError: errorMessage }));
        break;
      case 'design':
        dispatch(designLoadFileDownloadError({ id: kunngjoringId, fileDownloadError: errorMessage }));
        break;
      case 'varemerke':
        dispatch(varemerkeLoadFileDownloadError({ id: kunngjoringId, fileDownloadError: errorMessage }));
    }
  };

  const print = () => {
    setPdfIsLoading(true);
    dispatchError('');
    if (publikasjonNumber != null) {
      PrintKunngjoringHttpRequest.getKunngjoringPdf(kunngjoringId, rettighetstypeShort, publikasjonNumber)
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = url;
          const name = i18next.language === 'en' ? 'Event' : 'Kunngjøring';
          link.setAttribute('download', `${name}-${saksId}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          setPdfIsLoading(false);
        })
        .catch((error) => {
          setPdfIsLoading(false);
          dispatchError(error.toString());
        });
    } else {
      setPdfIsLoading(false);
      dispatchError(t('kunngjoring.errorPdf'));
    }
  };

  return (
    <>
      {!pdfIsLoading && kunngjoringId !== '' && (
        <PsButton onClick={print} title="Download PDF" aria-label="Download PDF Button" color="secondary-o" iconOnly>
          <span>
            <PsMaterialIcon symbol="download" root={{ ariaLabel: t('kunngjoring.download') }}></PsMaterialIcon>
          </span>
        </PsButton>
      )}
      {pdfIsLoading && <CircularProgress />}
    </>
  );
};

export default PrintKunngjoring;
