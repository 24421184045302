import React, { FunctionComponent, useEffect } from 'react';

import { Box } from '@mui/material';
import { PsAlert, PsBox, PsBreadcrumb, PsContainer, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import Loader from '../../shared/components/Loader';
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux-hooks';
import { useRettighetstype } from '../../shared/hooks/useRettighetstype';
import { isFutureDate } from '../../shared/utils/date-utils';
import { formatPublicationName } from '../../shared/utils/text-utils';
import { getPublikasjonStateByRettighet } from '../store/publikasjon/selectors';
import { loadPublikasjonsAsync } from '../store/publikasjon/service';

import CategoryList from './CategoryList';
import PublikasjonDownloadButtons from './PublikasjonDownloadButtons';
import PublikasjonPicker from './PublikasjonPicker';

const CategoryListScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const rettighetstype = useRettighetstype();

  const publikasjonState = useAppSelector((gs) => gs.publikasjonState);
  const { id } = useParams();
  const publikasjonStateByRettighet = getPublikasjonStateByRettighet(publikasjonState, rettighetstype);
  const { publikasjoner, loaded } = publikasjonStateByRettighet;
  const selectedPublikasjon = publikasjoner.find((x) => x.id === id);

  const notFoundMesssage = (
    <PsAlert color="primary" label={t('publikasjon.ikkeFunnetTittel')} severity="danger">
      <PsText>
        <span>{t('publikasjon.ikkeFunnetPublikasjon')}</span>
      </PsText>
    </PsAlert>
  );

  const title = formatPublicationName(t, selectedPublikasjon);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!loaded) {
      dispatch(loadPublikasjonsAsync(rettighetstype));
    }
  }, []);

  return (
    <PsContainer fluid color="primary" pFluid>
      <div>
        {loaded ? (
          <>
            <PsContainer>
              <PsBreadcrumb>
                <li>
                  <Link to="/">{t('tidende')}</Link>
                </li>
                <li>
                  <Link to={`/${rettighetstype}`}>{t(rettighetstype)}</Link>
                </li>
                <li>
                  <Link to={`/${rettighetstype}/publikasjoner`}>{t('publikasjon.publikasjoner')}</Link>
                </li>
                <li>
                  <Link to="#">{formatPublicationName(t, selectedPublikasjon)}</Link>
                </li>
              </PsBreadcrumb>
            </PsContainer>
            <PsContainer htmlMarkup="main">
              <PsBox mt={1} gutter>
                <PublikasjonPicker publikasjoner={publikasjoner} selected={selectedPublikasjon} />
              </PsBox>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <PsText type="title1">
                  <span>{title}</span>
                </PsText>
                {selectedPublikasjon && !isFutureDate(selectedPublikasjon.publikasjonDate) && (
                  <PublikasjonDownloadButtons publikasjon={selectedPublikasjon} />
                )}
              </Box>
              {!selectedPublikasjon && loaded && notFoundMesssage}
              {selectedPublikasjon && <CategoryList publikasjon={selectedPublikasjon} />}
            </PsContainer>
          </>
        ) : (
          <span>
            <Loader />
          </span>
        )}
      </div>
    </PsContainer>
  );
};

export default CategoryListScreen;
