import { KunngjoringType } from '../../../shared/types/enums';
import { SearchCategoryType, SearchPeriodType, SearchType } from '../../types/enums';

export interface SearchParamState {
  category: SearchCategoryType;
  searchString: string;
  searchType: SearchType;
  fromDate?: string;
  toDate?: string;
  itemCount: number;
  periodType: SearchPeriodType;
  kunngjoringType: KunngjoringType | undefined;
  showSearchInfo: boolean;
  searchWithoutSearchString: boolean;
}

export const initialState: SearchParamState = {
  category: SearchCategoryType.Kunngjoring,
  searchString: '',
  searchType: SearchType.SakNumber,
  fromDate: undefined,
  toDate: undefined,
  itemCount: 50,
  periodType: SearchPeriodType.AllTime,
  kunngjoringType: undefined,
  showSearchInfo: true,
  searchWithoutSearchString: false
};
