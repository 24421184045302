import React, { FunctionComponent, useRef, useEffect } from 'react';

import { PsAccordion, PsMaterialIcon } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { PublikasjonCategory } from '../../../publikasjon/types/entities';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { setFilterOpen } from '../../store/kunngjoring-filter/reducer';

import FilterInputBox from './FilterInputBox';

interface Props {
  category?: PublikasjonCategory;
}

const KunngjoringListFilter: FunctionComponent<Props> = ({ category }: Props) => {
  const { t } = useTranslation();
  const accordionRef = useRef(null);

  const kunngjoringFilterState = useAppSelector((gs) => gs.kunngjoringFilterState);
  const filterIsExpanded = kunngjoringFilterState.filterIsOpen;

  const dispatch = useAppDispatch();
  const toggleExpandHandler = (e: { detail: { value: boolean } }) => {
    dispatch(setFilterOpen(e.detail.value));
  };

  /* ALT WORK-AROUND: ADD EVENT-LISTENER NATIVELY */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (accordionRef.current as any).addEventListener('expand', toggleExpandHandler);
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (accordionRef.current as any)?.removeEventListener('expand', toggleExpandHandler);
    };
  }, []);

  return (
    <PsAccordion
      ref={accordionRef}
      label={t('kunngjoring.filterHeader')}
      expanded={filterIsExpanded}
      // onClick={toggleExpandHandler} // Feil: Trigger på både Accordion header og innhold
    >
      <div>
        <FilterInputBox category={category}></FilterInputBox>
      </div>
      <PsMaterialIcon symbol="filter_alt" slot="icon" />
    </PsAccordion>
  );
};

export default KunngjoringListFilter;
