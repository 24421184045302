export default {
  sak: 'Sak',
  holder: 'Innehaver',
  received: 'Innlevert',
  powerOfAttorney: 'Fullmektig',
  events: 'Kunngjøringer',
  grantDate: 'Meddelt',
  registered: 'Registrert/Gitt virkning',
  generalAvailable: 'Alment tilgjengelig',
  expiryDate: 'Utløpsdato',
  renewExpiryDate: 'Frist årsavgift',
  classes: 'Klasser',
  type: 'Type',
  help: 'Her er en tidslinje som viser alle kunngjøringer gjennom tidene for saken fra nyeste til eldste. For å komme til en side med mer kunngjøringsdetaljer klikker du på kunngjøringen.',
  expandable: ' kunngjøringer.',
  clickHere: 'Klikk her for å utvide...',
  hide: 'Klikk her for å skjule...',
  title: 'Tittel',
  markText: 'Merketekst',
  markDescription: 'Beskrivelse av merket',
  markColorDescription: 'Beskrivelse av fargen',
  natureOfMark: 'Merketype',
  markCategory: 'Merkekategori',
  registeredWithColors: 'Registert i farger',
  inventors: 'Oppfinnere',
  inventor: 'Oppfinner',
  ipcClasses: 'IPCklasser',
  granted: 'Meddelt',
  currentStatus: 'Gjeldende status',
  statusDate: 'Statusdato',
  divided: 'Avdelt fra søknad',
  bioDepositedMaterials: 'Deponert materiale',
  no: 'Nei',
  yes: 'Ja',
  publishedGrant: 'Kunngjort patent',
  priorities: 'Prioritet',
  renewalDeadline: 'Neste fornyelse',
  publishedRegistrationValid: 'Kunngjort reg./virkning',
  disclaimer: 'Unntaksanmerkning',
  regulations: 'Bestemmelser',
  products: 'Produktangivelse',
  designers: 'Designer',
  internationalRegDate: 'Internasjonal registreringsdato',
  internationalApplicationDate: 'Internasjonal innleveringsdato',
  pctNumber: 'PCT-nummer',
  notificationDate: 'Notifikasjonsdato',
  subsequentDesignationDate: 'Ytterlig utpekningsdato',
  madridUtCaseNumber: 'Basis for int. registrering',
  searchLink: 'Trykk her for å se mer info om saken i registerdataene til Patentstyret',
  timelineInfo:
    'Tidslinjen viser alle kunngjøringer for saker kunngjort fra og med 4/3 – 2024. Kunngjøringer frem i tid vises i grått og vil ikke være gjeldende før etter denne dato.',
  spc: {
    productIdentifier: 'Produktidentifikasjon',
    authorizationNorway: 'Markedsføringstillatelse i Norge',
    authorizationEAA: 'Markedsføringstillatelse i EØS',
    basicPatent: 'Basispatent'
  }
};
