import { PatentKunngjoringDetails } from '../../types/entities';

export interface PatentKunngjoringStateById {
  loaded: boolean;
  error?: string;
  details: PatentKunngjoringDetails | undefined;
  fileDownloadError?: string;
}

export interface PatentKunngjoringDetailsState {
  [id: string]: PatentKunngjoringStateById;
}

export const initialState: PatentKunngjoringDetailsState = {};
