import theme from '../../../../core/theme';

export const styles = {
  errorAndLoadingBoxStyle: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'center',
    margin: 8
  },
  headerBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 2,
    minHeight: '100px',
    borderTop: `4px solid ${theme.palette.borderGrønn.main}`,
    borderBottom: `4px solid ${theme.palette.borderGrønn.main}`
  }
};
