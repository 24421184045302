import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PatentKunngjoringDetails } from '../../types/entities';

import { PatentKunngjoringDetailsState, initialState } from './state';

export type PatentKunngjoringSuccessAction = {
  id: string;
  details: PatentKunngjoringDetails;
};

export type PatentKunngjoringFailedAction = {
  id: string;
  error: string;
};

export type PatentKunngjoringDownloadFailedAction = {
  id: string;
  fileDownloadError: string;
};

const patentKunngjoringDetailsSlice = createSlice({
  name: 'patentKunngjoringDetails',
  initialState,
  reducers: {
    loadSuccess: (state: PatentKunngjoringDetailsState, action: PayloadAction<PatentKunngjoringSuccessAction>) => {
      state[action.payload.id] = {
        loaded: true,
        details: action.payload.details
      };
    },
    loadFailed: (state: PatentKunngjoringDetailsState, action: PayloadAction<PatentKunngjoringFailedAction>) => {
      state[action.payload.id] = {
        loaded: true,
        error: action.payload.error,
        details: undefined
      };
    },
    loadFileDownloadError: (
      state: PatentKunngjoringDetailsState,
      action: PayloadAction<PatentKunngjoringDownloadFailedAction>
    ) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        fileDownloadError: action.payload.fileDownloadError
      };
    }
  }
});

const { actions, reducer } = patentKunngjoringDetailsSlice;
export const { loadSuccess, loadFailed, loadFileDownloadError } = actions;
export default reducer;
