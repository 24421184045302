import React from 'react';

import { Box } from '@mui/material';
import { PsBox, PsLink, PsText } from '@patentstyret/designsystem-react';

interface BoxProps {
  headline: string;
  info?: string;
  justifyContent: string;
  whiteSpace?: string;
  link?: boolean;
  linkHref?: string;
}

const BoxComponent: React.FC<BoxProps> = ({
  headline,
  info,
  justifyContent,
  whiteSpace = 'normal',
  link,
  linkHref
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: justifyContent,
        marginTop: 1,
        whiteSpace: { whiteSpace }
      }}
    >
      <PsBox mr={1}>
        <PsText type="bold">
          <span>{headline}:</span>
        </PsText>
      </PsBox>
      {link ? (
        <PsLink href={linkHref}>
          <span>{info}</span>
        </PsLink>
      ) : (
        <PsText>
          <span>{info}</span>
        </PsText>
      )}
    </Box>
  );
};

export default BoxComponent;
