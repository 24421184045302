import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import KunngjoringListScreen from '../modules/kunngjoring/components/KunngjoringListScreen';
import KunngjoringScreen from '../modules/kunngjoring/components/KunngjoringScreen';
import LegacyPublicationListScreeen from '../modules/legacy/components/LegacyPublicationListScreen';
import LegacyYearListScreeen from '../modules/legacy/components/LegacyYearListScreen';
import CategoryListScreen from '../modules/publikasjon/components/CategoryListScreen';
import PublikasjonListScreen from '../modules/publikasjon/components/PublikasjonListScreen';
import DesignSakScreen from '../modules/sak/components/DesignSakScreen';
import PatentSakScreen from '../modules/sak/components/PatentSakScreen';
import VaremerkeSakScreen from '../modules/sak/components/VaremerkeSakScreen';
import SearchScreen from '../modules/search/components/SearchScreen';

import AboutTidende from './components/AboutTidende';
import Home from './components/Home';
import RettighetHomeScreen from './components/Home/RettighetHomeScreen';

export const getRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutTidende />} />
      <Route path="/varemerke/kunngjoring/:sakId/:id" element={<KunngjoringScreen />} />
      <Route path="/design/kunngjoring/:sakId/:id" element={<KunngjoringScreen />} />
      <Route path="/patent/kunngjoring/:sakId/:id" element={<KunngjoringScreen />} />
      <Route path="/varemerke/sak/:id" element={<VaremerkeSakScreen />} />
      <Route path="/patent/sak/:id" element={<PatentSakScreen />} />
      <Route path="/design/sak/:id" element={<DesignSakScreen />} />

      {['/varemerke', '/patent', '/design'].map((path, index) => (
        <Route key={index} path={path} element={<RettighetHomeScreen />} />
      ))}
      {['/varemerke/*', '/patent/*', '/design/*'].map((path, index) => (
        <Route key={index} path={path}>
          <Route path="sok" element={<SearchScreen />} />
          <Route path="publikasjoner" element={<PublikasjonListScreen />} />
          <Route path="publikasjoner/:id/:categoryId" element={<KunngjoringListScreen />} />
          <Route path="publikasjoner/:id" element={<CategoryListScreen />} />
          <Route path="legacy/publikasjoner" element={<LegacyYearListScreeen />} />
          <Route path="legacy/publikasjoner/:year" element={<LegacyPublicationListScreeen />} />
        </Route>
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
