import { DesignSakDetails } from '../../types/entities';

export interface DesignSakDetailsState {
  success: boolean;
  loaded: boolean;
  error?: string;
  designSakDetails: DesignSakDetails;
}

export const initialState: DesignSakDetailsState = {
  success: false,
  loaded: false,
  error: undefined,
  designSakDetails: {} as DesignSakDetails
};
