import React, { FunctionComponent } from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { PsBox } from '@patentstyret/designsystem-react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { useTranslation } from 'react-i18next';
import { generatePath, matchPath, useLocation, useNavigate } from 'react-router-dom';

import { Publikasjon } from '../types/entities';

dayjs.extend(isoWeek);

interface Props {
  publikasjoner: Publikasjon[];
  selected?: Publikasjon;
}

const PublikasjonPicker: FunctionComponent<Props> = ({ publikasjoner, selected }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedPublikasjon = selected?.id;

  const routes = ['/varemerke/publikasjoner/:id', '/patent/publikasjoner/:id', '/design/publikasjoner/:id'];
  const { pathname } = useLocation();
  const pathPattern = routes.find((pattern) => matchPath(pattern, pathname));

  const goTo = (publikasjon?: Publikasjon) => {
    if (pathPattern && publikasjon != null) {
      const path = generatePath(pathPattern, { id: publikasjon.id });
      navigate(path);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const newPublikasjon = publikasjoner.find((publikasjon) => publikasjon.id === event.target.value);
    goTo(newPublikasjon);
  };

  const handleArrowBackClick = () => {
    const currentPublikasjonIndex = publikasjoner.findIndex((publikasjon) => publikasjon.id === selectedPublikasjon);
    if (currentPublikasjonIndex < publikasjoner.length - 1) {
      const newPublikasjon = publikasjoner[currentPublikasjonIndex + 1];
      goTo(newPublikasjon);
    }
  };

  const handleArrowNextClick = () => {
    const currentPublikasjonIndex = publikasjoner.findIndex((publikasjon) => publikasjon.id === selectedPublikasjon);
    if (currentPublikasjonIndex > 0) {
      const newPublikasjon = publikasjoner[currentPublikasjonIndex - 1];
      goTo(newPublikasjon);
    }
  };

  return (
    <PsBox flex alignItems="center">
      <div>
        <IconButton
          onClick={handleArrowBackClick}
          disabled={publikasjoner.length > 0 && selectedPublikasjon === publikasjoner.slice(-1)[0].id}
        >
          <ArrowBackIosIcon></ArrowBackIosIcon>
        </IconButton>
      </div>

      <div style={{ flexGrow: '1', margin: '0 2rem' }}>
        <Select
          fullWidth
          value={selectedPublikasjon}
          sx={{ backgroundColor: 'white.main' }}
          id="publikasjon-select"
          onChange={handleChange}
          defaultValue=""
        >
          {publikasjoner.map((publikasjon: Publikasjon) => (
            <MenuItem key={publikasjon.id} value={publikasjon.id}>
              {t('publikasjon.publikasjonSelect')} {publikasjon.nr} {' / '}
              {publikasjon.year}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div>
        <IconButton
          onClick={handleArrowNextClick}
          disabled={publikasjoner.length > 0 && selectedPublikasjon === publikasjoner[0].id}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </PsBox>
  );
};

export default PublikasjonPicker;
