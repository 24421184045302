import axiosApi from '../../../shared/utils/axios-api';
import { VaremerkeSakDetails } from '../../types/entities';

export class SakDetailsHttpRequest {
  public static getVaremerkeSakDetails(sakIdentification?: string) {
    return axiosApi.get<VaremerkeSakDetails>(
      `${process.env.REACT_APP_API_URL}/sak/varemerkesakdetails?sakidentification=${sakIdentification}`
    );
  }
}
