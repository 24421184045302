import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VaremerkeSakDetails } from '../../types/entities';

import { VaremerkeSakDetailsState, initialState } from './state';

const varemerkeSakDetailsSlice = createSlice({
  name: 'varemerkeSakDetails',
  initialState,
  reducers: {
    reset: () => initialState,
    setLoaded: (state: VaremerkeSakDetailsState, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
      state.success = false;
      state.error = undefined;
    },
    setSuccess: (state: VaremerkeSakDetailsState, action: PayloadAction<boolean>) => {
      state.success = action.payload;
      state.loaded = true;
      state.error = undefined;
    },
    setError: (state: VaremerkeSakDetailsState, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
      state.loaded = true;
      state.success = false;
    },
    setVaremerkeSakDetails: (state: VaremerkeSakDetailsState, action: PayloadAction<VaremerkeSakDetails>) => {
      state.varemerkeSakDetails = action.payload;
      state.loaded = true;
      state.success = false;
      state.error = undefined;
    }
  }
});

const { actions, reducer } = varemerkeSakDetailsSlice;
export const { reset, setLoaded, setSuccess, setError, setVaremerkeSakDetails } = actions;
export default reducer;
