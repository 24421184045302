import React, { FunctionComponent } from 'react';

import {
  PsBox,
  PsBreadcrumb,
  PsContainer,
  PsLink,
  PsLinkList,
  PsLinkListItem,
  PsMaterialIcon,
  PsText
} from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useRettighetstype } from '../../../modules/shared/hooks/useRettighetstype';

const RettighetHomeScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const rettighetstype = useRettighetstype();
  const linkToInfoTidende = 'https://www.patentstyret.no/om-oss/oppgavene-vare-og-hvordan-vi-jobber/om-tidende';

  const listClickHandler = (
    e: React.MouseEvent<HTMLPsLinkListItemElement>,
    link: 'publikasjoner' | 'sok' | 'legacy/publikasjoner'
  ) => {
    navigate(link);
    e.preventDefault();
  };

  let title: string;
  let subtitle: string;
  switch (rettighetstype) {
    case 'varemerke':
      title = t('tidende_varemerke');
      subtitle = t('varemerke_subtitle');
      break;
    case 'patent':
      title = t('tidende_patent');
      subtitle = t('patent_subtitle');
      break;
    case 'design':
      title = t('tidende_design');
      subtitle = t('design_subtitle');
      break;
  }

  return (
    <PsContainer color="primary" fluid>
      <PsContainer>
        <PsBreadcrumb>
          <li>
            <Link to="/">
              <span>{t('tidende')}</span>
            </Link>
          </li>
          <li>
            <Link to="#">{t(rettighetstype)}</Link>
          </li>
        </PsBreadcrumb>
      </PsContainer>
      <PsContainer color="primary" fluid bgImgUrl="../hero-pyramids.jpg" bgImgFrame>
        <PsContainer>
          <PsBox py={10} gutter>
            <PsText type="hero" color="white">
              <span>{title}</span>
            </PsText>
            <PsText color="white">
              <span>{subtitle}</span>
            </PsText>
          </PsBox>
        </PsContainer>
      </PsContainer>
      <PsContainer htmlMarkup="main">
        <PsBox pt={5}>
          <PsBox gutter flex alignItems="center">
            <PsText type="title4">
              <span>
                <span>{t('nye_tjenester')}</span>
                <PsBox pt={1}>
                  <PsText type="small">
                    <span>{t('fromDate')}</span>
                  </PsText>
                </PsBox>
              </span>
            </PsText>
          </PsBox>
          <PsBox>
            <PsText type="small"></PsText>
          </PsBox>
          <PsLinkList>
            <PsLinkListItem
              key={1}
              label={t('publications')}
              href={`/${rettighetstype}/publikasjoner`}
              onClick={(e) => listClickHandler(e, 'publikasjoner')}
            >
              <PsMaterialIcon symbol="article" slot="icon" />
            </PsLinkListItem>
            <PsLinkListItem
              key={2}
              label={t('headerSearch')}
              href={`/${rettighetstype}/sok`}
              onClick={(e) => listClickHandler(e, 'sok')}
            >
              <PsMaterialIcon symbol="search" slot="icon" />
            </PsLinkListItem>
          </PsLinkList>
          <PsBox pt={3}>
            <PsLink target="_blank" href={linkToInfoTidende}>
              <span>{t('link_tidendeInfo')}</span>
            </PsLink>
          </PsBox>
        </PsBox>
        <PsBox gutter flex alignItems="center" pt={5}>
          <PsText type="title4">
            <span>
              <span>{t('tradisjonelle_tjenester')}</span>
              <PsBox pt={1}>
                <PsText type="small">
                  <span>{t('toDate')}</span>
                </PsText>
              </PsBox>
            </span>
          </PsText>
        </PsBox>
        <PsLinkList>
          <PsLinkListItem
            label={t('legacyPublications')}
            href={`/${rettighetstype}/legacy/publikasjoner`}
            onClick={(e) => listClickHandler(e, 'legacy/publikasjoner')}
          ></PsLinkListItem>
        </PsLinkList>
      </PsContainer>
    </PsContainer>
  );
};

export default RettighetHomeScreen;
