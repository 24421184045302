import React, { FunctionComponent } from 'react';

import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SearchPeriodType } from '../../../types/enums';

interface Props {
  selected: SearchPeriodType;
  onSelectChange: (selected: SearchPeriodType) => void;
  fullWidth?: boolean;
}

const SelectPeriodTypeControl: FunctionComponent<Props> = ({ onSelectChange, selected, fullWidth = false }: Props) => {
  const { t } = useTranslation();

  const selectChangeHandler = (event: SelectChangeEvent) => {
    const selected = event.target.value as SearchPeriodType;
    onSelectChange(selected);
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <Select value={selected} onChange={selectChangeHandler} size="small" fullWidth={fullWidth}>
        <MenuItem value={SearchPeriodType.AllTime}>{t('search.allTime')}</MenuItem>
        <MenuItem value={SearchPeriodType.LastYear}>{t('search.lastYear')}</MenuItem>
        <MenuItem value={SearchPeriodType.Last30Days}>{t('search.last30Days')}</MenuItem>
        <MenuItem value={SearchPeriodType.Custom}>{t('search.customTimeInterval')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectPeriodTypeControl;
