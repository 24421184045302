import React, { FunctionComponent, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { PsBox, PsButton, PsMaterialIcon, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../shared/hooks/redux-hooks';
import { useRettighetstype } from '../../shared/hooks/useRettighetstype';
import { mapRettighetstypeShort } from '../../shared/utils/rettighetstype-utils';
import { PublikasjonerHttpRequest } from '../store/publikasjon/http-request';
import { loadFileDownloadError } from '../store/publikasjon-category/reducer';
import { Publikasjon } from '../types/entities';

export interface Props {
  publikasjon: Publikasjon;
}

const PublikasjonDownloadButtons: FunctionComponent<Props> = ({ publikasjon }: Props) => {
  const { t } = useTranslation();
  const [fileLoad, setFileLoad] = useState<boolean>(false);
  const rettighetstype = useRettighetstype();
  const rettighetstypeShort = mapRettighetstypeShort(rettighetstype);
  const dispatch = useAppDispatch();

  const createDownloadLink = (data: Blob, fileEnding: string) => {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(data);
    link.href = url;

    const name = (() => {
      switch (rettighetstype) {
        case 'varemerke':
          return t('tidende_varemerke');
        case 'patent':
          return t('tidende_patent');
        case 'design':
          return t('tidende_design');
      }
    })();

    link.setAttribute('download', `${name}-${publikasjon.id}.${fileEnding}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const uniqueIdForPublikasjonInRedux = `${rettighetstype}-${publikasjon.id}`;
  const handlePdfDownload = async () => {
    if (!publikasjon) return;
    setFileLoad(true);
    dispatch(loadFileDownloadError({ id: uniqueIdForPublikasjonInRedux, fileDownloadError: '' }));

    PublikasjonerHttpRequest.getPublicationPdf(publikasjon.id, rettighetstypeShort)
      .then((response) => {
        createDownloadLink(response.data, 'pdf');
        setFileLoad(false);
      })
      .catch((error) => {
        setFileLoad(false);
        dispatch(loadFileDownloadError({ id: uniqueIdForPublikasjonInRedux, fileDownloadError: error.toString() }));
      });
  };

  const handleJsonDownload = async () => {
    setFileLoad(true);
    dispatch(loadFileDownloadError({ id: uniqueIdForPublikasjonInRedux, fileDownloadError: '' }));

    PublikasjonerHttpRequest.getPublikasjonJson(publikasjon.id, rettighetstypeShort)
      .then((response) => {
        const jsonData = response.data;
        const jsonContent = JSON.stringify(jsonData, null, 2);
        const blob = new Blob([jsonContent], { type: 'application/json' });
        createDownloadLink(blob, 'json');
        setFileLoad(false);
      })
      .catch((error) => {
        setFileLoad(false);
        dispatch(loadFileDownloadError({ id: uniqueIdForPublikasjonInRedux, fileDownloadError: error.toString() }));
      });
  };

  return (
    <Box>
      {!fileLoad && publikasjon && (
        <PsBox flex flexDirection="row">
          <PsButton
            onClick={handlePdfDownload}
            title="Download PDF"
            aria-label="Download PDF Button"
            color="primary-o"
            iconOnly
          >
            <span>
              <PsMaterialIcon symbol="download" root={{ ariaLabel: t('publikasjon.download') }}></PsMaterialIcon>
              <PsText>
                <span>PDF</span>
              </PsText>
            </span>
          </PsButton>
          <PsBox ml={1}>
            <PsButton
              onClick={handleJsonDownload}
              title="Download JSON"
              aria-label="Download JSON Button"
              color="primary-o"
              iconOnly
            >
              <span>
                <PsMaterialIcon symbol="download" root={{ ariaLabel: t('publikasjon.download') }}></PsMaterialIcon>
                <PsText>
                  <span>JSON</span>
                </PsText>
              </span>
            </PsButton>
          </PsBox>
        </PsBox>
      )}
      {fileLoad && <CircularProgress />}
    </Box>
  );
};

export default PublikasjonDownloadButtons;
