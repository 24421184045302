import React, { FunctionComponent } from 'react';

import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PublikasjonCategory } from '../../../publikasjon/types/entities';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { setSelectedAgentFilter } from '../../store/kunngjoring-filter/reducer';
import { setCurrentPage } from '../../store/kunngjoring-pagination/reducer';

import SelectApplicationType from './SelectApplicationType';
import SelectMarkCategory from './SelectMarkCategory';
import SelectSubCategory from './SelectSubCategory';

interface Props {
  category?: PublikasjonCategory;
}

const FilterInputBox: FunctionComponent<Props> = ({ category }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filterValue = useAppSelector((gs) => gs.kunngjoringFilterState);
  const rettighetstype = useRettighetstype();

  const filterInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedAgentFilter(event.target.value));
    dispatch(setCurrentPage(1));
  };

  return (
    <Box>
      <Box sx={{ backgroundColor: 'white.main' }}>
        <TextField
          color="secondary"
          fullWidth
          sx={{ marginTop: 0 }}
          id="outlined-search"
          label={t('kunngjoring.filterType')}
          type="search"
          onChange={filterInputChangeHandler}
          value={filterValue.selectedAgentFilter}
        />
      </Box>
      <Box mt={2} display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Box mr={2} mb={2}>
          {category && <SelectSubCategory category={category} />}
        </Box>
        {rettighetstype == 'varemerke' && (
          <Box mr={2} mb={2}>
            <SelectMarkCategory />
          </Box>
        )}
        <Box>
          <SelectApplicationType />
        </Box>
      </Box>
    </Box>
  );
};

export default FilterInputBox;
