import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DesignSakDetails } from '../../types/entities';

import { DesignSakDetailsState, initialState } from './state';

const designSakDetailsSlice = createSlice({
  name: 'DesignSakDetails',
  initialState,
  reducers: {
    reset: () => initialState,
    setLoaded: (state: DesignSakDetailsState, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
      state.success = false;
      state.error = undefined;
    },
    setSuccess: (state: DesignSakDetailsState, action: PayloadAction<boolean>) => {
      state.success = action.payload;
      state.loaded = true;
      state.error = undefined;
    },
    setError: (state: DesignSakDetailsState, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
      state.loaded = true;
      state.success = false;
    },
    setDesignSakDetails: (state: DesignSakDetailsState, action: PayloadAction<DesignSakDetails>) => {
      state.designSakDetails = action.payload;
      state.loaded = true;
      state.success = false;
      state.error = undefined;
    }
  }
});

const { actions, reducer } = designSakDetailsSlice;
export const { reset, setLoaded, setSuccess, setError, setDesignSakDetails } = actions;
export default reducer;
