import { Rettighetstype } from '../../../shared/types/entities';

import { CategoryState, CategoryStateByPublikasjon } from './state';

export const getCategoryStateByRettighetAndPublikasjonNr = (
  publikasjonCategoryState: CategoryState,
  rettighetstype: Rettighetstype,
  publikasjonNr = ''
): CategoryStateByPublikasjon => {
  const uniqueId = `${rettighetstype}-${publikasjonNr}`;
  return publikasjonCategoryState[uniqueId] || { loaded: false, content: [] };
};
