import { Rettighetstype } from '../../../shared/types/entities';

import { PublikasjonState, PublikasjonStateByRettighet } from './state';

export const getPublikasjonStateByRettighet = (
  publikasjonState: PublikasjonState,
  rettighetstype: Rettighetstype
): PublikasjonStateByRettighet => {
  return publikasjonState[rettighetstype] || { loaded: false, publikasjoner: [] };
};
