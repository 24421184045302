import React, { useRef } from 'react';

import { PsToggle } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { setSearchCategory } from '../../store/search-param/reducer';
import { SearchCategoryType } from '../../types/enums';

const SearchToggle = () => {
  const { t, i18n } = useTranslation();
  const toggleRef = useRef(null);
  const searchCategory = useAppSelector((gs) => gs.searchParamState.category);

  const dispatch = useAppDispatch();
  const toggleHandler = () => {
    const newValue =
      searchCategory === SearchCategoryType.Kunngjoring ? SearchCategoryType.Sak : SearchCategoryType.Kunngjoring;
    dispatch(setSearchCategory(newValue));
  };

  const labelOn = t('events');
  let labelOff = t('cases');
  if (i18n.language === 'no') {
    labelOff = `\u00A0\u00A0\u00A0${t('cases')}\u00A0\u00A0\u00A0`;
  }

  // */ ALT WORK-AROUND: ADD EVENT-LISTENER NATIVELY
  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   (toggleRef.current as any).addEventListener('toggle', toggleHandler);
  //   return () => {
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     (toggleRef.current as any)?.removeEventListener('toggle', toggleHandler);
  //   };
  // }, []);

  return (
    <PsToggle
      ref={toggleRef}
      color="primary"
      labelOn={labelOn}
      labelOff={labelOff}
      // onToggle={(e) => toggleHandler(e.detail.value)} // TODO: DOESNT TRIGGER, SEE ON-CLICK WORK-AROUND
      onClick={() => toggleHandler()}
      value={searchCategory === SearchCategoryType.Kunngjoring}
      root={{ ariaLabel: 'Toggle between events or cases' }}
    />
  );
};

export default SearchToggle;
