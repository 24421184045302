import React, { FunctionComponent } from 'react';

import { PsBox, PsBreadcrumb, PsContainer, PsLinkList, PsLinkListItem, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useRettighetstype } from '../../shared/hooks/useRettighetstype';
import { getPublicationYearList } from '../utils/data-utils';

const LegacyYearListScreeen: FunctionComponent = () => {
  const { t } = useTranslation();
  const rettighetstype = useRettighetstype();
  const navigate = useNavigate();

  const yearList = getPublicationYearList(rettighetstype).sort((a, b) => (a.year < b.year ? 1 : -1));

  const listClickHandler = (e: React.MouseEvent<HTMLPsLinkListItemElement>, year: number) => {
    const link = `/${rettighetstype}/legacy/publikasjoner/${year}`;
    navigate(link);
    e.preventDefault();
  };

  const publikasjonerYearItems = yearList.map((item) => {
    const label = t(`legacy.${rettighetstype}.year_label`);
    const fullLabel = `${label} ${item.year}`;
    const url = `/${rettighetstype}/legacy/publikasjoner/${item.year}`;
    return (
      <PsLinkListItem
        key={item.year}
        label={fullLabel}
        href={url}
        onClick={(e) => listClickHandler(e, item.year)}
      ></PsLinkListItem>
    );
  });

  const title = t(`legacy.${rettighetstype}.title`);
  const ingress = t(`legacy.${rettighetstype}.ingress`);

  return (
    <PsContainer fluid color="primary" pFluid>
      <PsContainer>
        <PsBreadcrumb>
          <li>
            <Link to="/">{t('tidende')}</Link>
          </li>
          <li>
            <Link to={`/${rettighetstype}`}>{t(rettighetstype)}</Link>
          </li>
          <li>
            <Link to="#">{t('legacy.publikasjoner')}</Link>
          </li>
        </PsBreadcrumb>
      </PsContainer>
      <PsContainer htmlMarkup="main">
        <div>
          <PsBox gutter>
            <PsText type="title1">
              <span>{title}</span>
            </PsText>
            <PsText>
              <span>{ingress}</span>
            </PsText>
          </PsBox>
          <PsBox mt={3}></PsBox>
          {yearList.length > 0 && <PsLinkList>{publikasjonerYearItems}</PsLinkList>}
        </div>
      </PsContainer>
    </PsContainer>
  );
};

export default LegacyYearListScreeen;
