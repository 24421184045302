import { AppThunk } from '../../../../core/store';

import { SakDetailsHttpRequest } from './http-request';
import { setVaremerkeSakDetails, setError, setLoaded } from './reducer';

export const loadVaremerkeSakDetailsAsync = (sakIdentification?: string): AppThunk => {
  return (dispatch) => {
    dispatch(setLoaded(false));
    SakDetailsHttpRequest.getVaremerkeSakDetails(sakIdentification)
      .then((response) => {
        dispatch(setVaremerkeSakDetails(response.data));
      })
      .catch((error) => {
        dispatch(setError(error.toString()));
      });
  };
};
