import React, { FunctionComponent } from 'react';

import { PublikasjonCategory } from '../../../../publikasjon/types/entities';
import TrademarkClassesAccordion from '../../../../shared/components/TrademarkClassesAccordion';
import { VaremerkeKunngjoringDetails } from '../../../types/entities';
import CategorySpecificEventDetails from '../CategorySpecificEventDetails';

import AssociatedMarkInfo from './AssociatedMarkInfo';
import DetailedViewHeader from './DetailedViewHeader';
import VaremerkeInfo from './VaremerkeInfo';

interface Props {
  kunngjoringDetails: VaremerkeKunngjoringDetails;
  category?: PublikasjonCategory;
}

const KunngjoringVaremerkePanel: FunctionComponent<Props> = ({ category, kunngjoringDetails }) => {
  return (
    <>
      <DetailedViewHeader category={category} details={kunngjoringDetails} />
      <CategorySpecificEventDetails details={kunngjoringDetails} />
      {kunngjoringDetails.varemerkeDetails.associatedMarks && (
        <AssociatedMarkInfo associatedMarks={kunngjoringDetails.varemerkeDetails.associatedMarks} />
      )}
      <VaremerkeInfo details={kunngjoringDetails} />
      <TrademarkClassesAccordion trademarkClasses={kunngjoringDetails.varemerkeDetails.classes} />
    </>
  );
};

export default KunngjoringVaremerkePanel;
