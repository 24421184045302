import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsContainer, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { PublikasjonCategory } from '../../../../publikasjon/types/entities';
import ImageContent from '../../../../shared/components/ImageContent';
import { formatJsonTimeString, manualTranslation } from '../../../../shared/utils/translation';
import { baseStyles, headerStyles } from '../../../styles/BaseBoxStyles';
import { DesignKunngjoringDetails } from '../../../types/entities';

interface Props {
  details: DesignKunngjoringDetails;
  category?: PublikasjonCategory;
}

const DetailedViewHeader: FunctionComponent<Props> = ({ details, category }: Props) => {
  const { t, i18n } = useTranslation();

  let categoryDesc = '';
  if (category) {
    categoryDesc = i18n.language === 'no' ? category.norwegianDescription : category.englishDescription;
  }

  return (
    <PsContainer>
      <Box
        sx={{
          ...headerStyles.topBox
        }}
      >
        <ImageContent image={details.designDetails.image} hasImage={details.hasImage} detailedView />
        <PsBox>
          <PsBox flex justifyContent="center">
            <PsText>
              <span>
                {details.designDetails.applicationNumber}{' '}
                {details.designDetails.registrationNumber && `(${details.designDetails.registrationNumber})`}
              </span>
            </PsText>
          </PsBox>
          <PsBox flex flexDirection="column" alignItems="center" my={1}>
            <PsText type="title3" root={{ class: 'titleWithoutMargin' }}>
              <span>{manualTranslation(i18n, details.highLevelDescription).toLocaleUpperCase()}</span>
            </PsText>
            <PsText type="title4" root={{ class: 'titleWithoutMargin' }}>
              <span>{manualTranslation(i18n, details.subLevelDescription)}</span>
            </PsText>
          </PsBox>
        </PsBox>
        <PsBox>
          <PsText>
            <span>{details.nationalEventCode + ' / ' + details.legalStatusCode}</span>
          </PsText>
        </PsBox>
      </Box>
      <Box
        sx={{
          ...baseStyles.baseBoxStyleHeader
        }}
      >
        <PsBox mr={1}>
          <PsText type="bold">
            <span>{t('kunngjoring.category') + ':'}</span>
          </PsText>
        </PsBox>
        {categoryDesc && (
          <PsText>
            <span>{categoryDesc}</span>
          </PsText>
        )}
      </Box>
      <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
        <PsBox mr={1}>
          <PsText type="bold">
            <span>{t('kunngjoring.publicationDate') + ':'}</span>
          </PsText>
        </PsBox>
        <PsText>
          <span>{formatJsonTimeString(details.kunngjoringDate)}</span>
        </PsText>
      </Box>
      <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
        <PsBox mr={1}>
          <PsText type="bold">
            <span>{t('kunngjoring.eventDate') + ':'}</span>
          </PsText>
        </PsBox>
        <PsText>
          <span>{formatJsonTimeString(details.decisionDate)}</span>
        </PsText>
      </Box>
    </PsContainer>
  );
};

export default DetailedViewHeader;
