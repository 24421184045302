import { useLocation } from 'react-router-dom';

import { Rettighetstype } from '../types/entities';

export const useRettighetstype = (): Rettighetstype => {
  const location = useLocation();
  const rettighetstype = location.pathname.split('/')[1]?.toLowerCase();

  const validRettighetstyper = ['varemerke', 'patent', 'design'];
  const isValid = rettighetstype && validRettighetstyper.indexOf(rettighetstype) >= 0;

  return isValid ? (rettighetstype as Rettighetstype) : 'varemerke';
};
