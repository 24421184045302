import React, { type FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';
import { PsBox, PsContainer, PsMaterialIcon, PsText } from '@patentstyret/designsystem-react';

import ImageContent from '../../../shared/components/ImageContent';
import { StyledTooltip } from '../../../shared/components/Tooltip';
import { useAppSelector } from '../../../shared/hooks/redux-hooks';
import { styles } from '../../shared/styles/BaseBoxStyles';

const DetailedViewHeader: FunctionComponent = () => {
  const varemerkeSakDetails = useAppSelector((gs) => gs.varemerkeSakDetailsState.varemerkeSakDetails);

  return (
    <PsContainer color="primary">
      <Box
        sx={{
          ...styles.headerBoxStyle
        }}
      >
        <PsBox flex justifyContent="center">
          <PsText type="title4">
            <span>{varemerkeSakDetails.applicationNumber}</span>
            {varemerkeSakDetails.registrationNumber && (
              <span>{' (' + varemerkeSakDetails.registrationNumber + ')'}</span>
            )}
          </PsText>
        </PsBox>
        {varemerkeSakDetails.markFeature.token == 'BBM_NATUREMARK_WORD' && (
          <StyledTooltip title={varemerkeSakDetails.varemerkeText}>
            <Typography sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
              {varemerkeSakDetails.varemerkeText}
            </Typography>
          </StyledTooltip>
        )}
        {(varemerkeSakDetails.markFeature.token == 'BBM_NATUREMARK_COMBINED_FIGURATIVE' ||
          varemerkeSakDetails.markFeature.token == 'BBM_NATUREMARK_3D') && (
          <ImageContent image={varemerkeSakDetails.image} hasImage={varemerkeSakDetails.hasImage} detailedView />
        )}
        {varemerkeSakDetails.markFeature.token == 'BBM_NATUREMARK_MULTIMEDIA' && (
          <PsBox p={4} color="primary">
            <PsMaterialIcon symbol="videocam" size="xl"></PsMaterialIcon>
          </PsBox>
        )}
        {varemerkeSakDetails.markFeature.token == 'BBM_NATUREMARK_SOUND' && (
          <PsBox p={4} color="primary">
            <PsMaterialIcon symbol="audio_file" size="xl"></PsMaterialIcon>
          </PsBox>
        )}
      </Box>
    </PsContainer>
  );
};

export default DetailedViewHeader;
