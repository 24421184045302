import React, { FunctionComponent } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { Rettighetstype } from '../../../shared/types/entities';
import { getSearchKunngjoringStateByRettighet } from '../../store/search-kunngjoring/selectors';
import { paginateSearchResultAsync as kunngjoringPaginateAsync } from '../../store/search-kunngjoring/service';
import { getSearchSakStateByRettighet } from '../../store/search-sak/selectors';
import { paginateSearchResultAsync as sakPaginateAsync } from '../../store/search-sak/service';
import { SearchCategoryType } from '../../types/enums';

interface Props {
  rettighetstype: Rettighetstype;
  category: SearchCategoryType;
}

const SearchBarPaginationBar: FunctionComponent<Props> = ({ rettighetstype, category }: Props) => {
  const { t } = useTranslation();
  const searchKunngjoringState = useAppSelector((gs) => gs.searchKunngjoringState);
  const searchSakState = useAppSelector((gs) => gs.searchSakState);
  const searchParamState = useAppSelector((gs) => gs.searchParamState);
  const kunngjoringState = getSearchKunngjoringStateByRettighet(searchKunngjoringState, rettighetstype);
  const sakState = getSearchSakStateByRettighet(searchSakState, rettighetstype);

  const paginationPage =
    category === SearchCategoryType.Kunngjoring ? kunngjoringState.paginationPage : sakState.paginationPage;
  const continuationTokens =
    category === SearchCategoryType.Kunngjoring ? kunngjoringState.continuationTokens : sakState.continuationTokens;
  const searchResultLength =
    category === SearchCategoryType.Kunngjoring ? kunngjoringState.searchResult.length : sakState.searchResult.length;

  const dispatch = useAppDispatch();

  const paginateSearchResultAsync =
    category === SearchCategoryType.Kunngjoring ? kunngjoringPaginateAsync : sakPaginateAsync;

  const paginateForward = () => {
    dispatch(paginateSearchResultAsync(rettighetstype, 'forward', searchSakState, searchParamState));
    window.scroll(0, 0);
  };

  const paginateBackward = () => {
    dispatch(paginateSearchResultAsync(rettighetstype, 'backward', searchSakState, searchParamState));
    window.scroll(0, 0);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: ' row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center'
      }}
    >
      <IconButton disabled={paginationPage < 1 || searchResultLength === 0} onClick={paginateBackward}>
        <ArrowBackIcon />
      </IconButton>
      <Typography>
        {paginationPage +
          1 +
          ' ' +
          t('search.of') +
          ' ' +
          (category === SearchCategoryType.Kunngjoring ? kunngjoringState.totalPages : sakState.totalPages)}
      </Typography>
      <IconButton disabled={!continuationTokens[paginationPage]} onClick={paginateForward}>
        <ArrowForwardIcon />
      </IconButton>
    </Box>
  );
};

export default SearchBarPaginationBar;
