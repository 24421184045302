import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from '../../../../core/store';
import { FilterCheckboxValue } from '../../types/entities';
import { getListOfSubCategories } from '../../utils/utils';

import { FilterState, initialState } from './state';

//Added to handle async method getListOfKunngjoringTypes.
export const setListOfSubCategories =
  (categoryId: string): AppThunk =>
  async (dispatch) => {
    const subCategories = await getListOfSubCategories(categoryId);
    dispatch(setListOfSubCategoriesAction(subCategories));
  };

const setListOfSubCategoriesAction = createAction<FilterCheckboxValue[]>('setKunngjoringTypeFilterList');

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedAgentFilter: (state: FilterState, action: PayloadAction<string>) => {
      state.selectedAgentFilter = action.payload;
    },
    setFilterOpen: (state: FilterState, action: PayloadAction<boolean>) => {
      state.filterIsOpen = action.payload;
    },
    changefilterApplicationType: (state: FilterState, action: PayloadAction<FilterCheckboxValue[]>) => {
      state.filterApplicationType = action.payload;
    },
    changefilterSubCategories: (state: FilterState, action: PayloadAction<FilterCheckboxValue[]>) => {
      state.filterSubCategories = action.payload;
    },
    changefilterMarkCategory: (state: FilterState, action: PayloadAction<FilterCheckboxValue[]>) => {
      state.filterMarkType = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setListOfSubCategoriesAction, (state, action) => {
      state.filterSubCategories = action.payload;
    });
  }
});

const { actions, reducer } = filterSlice;
export const {
  reset,
  setSelectedAgentFilter,
  setFilterOpen,
  changefilterApplicationType,
  changefilterSubCategories,
  changefilterMarkCategory
} = actions;
export default reducer;
