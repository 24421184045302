import React, { FunctionComponent } from 'react';

import { PublikasjonCategory } from '../../../../publikasjon/types/entities';
import DesignImages from '../../../../shared/components/DesignImages';
import { DesignKunngjoringDetails } from '../../../types/entities';
import CategorySpecificEventDetails from '../CategorySpecificEventDetails';

import DesignInfo from './DesignInfo';
import DetailedViewHeader from './DetailedViewHeader';

interface Props {
  kunngjoringDetails: DesignKunngjoringDetails;
  category?: PublikasjonCategory;
}

const KunngjoringDesignPanel: FunctionComponent<Props> = ({ category, kunngjoringDetails }: Props) => {
  return (
    <>
      <DetailedViewHeader category={category} details={kunngjoringDetails} />
      <CategorySpecificEventDetails details={kunngjoringDetails} />
      <DesignInfo details={kunngjoringDetails} />
      <DesignImages designRepresentationSheets={kunngjoringDetails.designDetails.designRepresentationSheets} />
    </>
  );
};

export default KunngjoringDesignPanel;
