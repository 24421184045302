import React, { FunctionComponent } from 'react';

import { PsBox } from '@patentstyret/designsystem-react';

interface Props {
  children?: React.ReactNode;
}

const shadow = {
  boxShadow: `
    0px 2px 4px -1px rgb(0 0 0 / 20%), 
    0px 4px 5px 0px rgb(0 0 0 / 14%), 
    0px 1px 10px 0px rgb(0 0 0 / 12%)`
};

const ShadowEdge: FunctionComponent<Props> = ({ children }: Props) => {
  return (
    <PsBox mb={1}>
      <div style={shadow}>{children}</div>
    </PsBox>
  );
};

export default ShadowEdge;
