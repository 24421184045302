import React, { FunctionComponent, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsLink } from '@patentstyret/designsystem-react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import theme from '../../../../core/theme';
import { useAppSelector } from '../../../shared/hooks/redux-hooks';
import { formatApplicantOrHolder, formatAuthorization, formatParticipant } from '../../../shared/utils/text-utils';
import { formatJsonTimeString, manualTranslation } from '../../../shared/utils/translation';
import BoxComponent from '../../shared/components/BoxComponent';
import ParticipantsBox from '../../shared/components/ParticipantsBox';

const PatentInfo: FunctionComponent = () => {
  const { t, i18n } = useTranslation();

  const patentSakDetails = useAppSelector((gs) => gs.patentSakDetailsState.patentSakDetails);
  const linkToSearch = `https://search.patentstyret.no/patent/${patentSakDetails.applicationNumber}`;
  const spcBasicPatentLink = `/patent/sak/${patentSakDetails.spcData?.basicPatentCaseIdentification}`;
  const [isSPCCase, setIsSPCCase] = useState(false);

  useEffect(() => {
    if (
      patentSakDetails.spcData != null &&
      patentSakDetails.spcData.basicPatentCaseIdentification != patentSakDetails.sakIdentification
    ) {
      setIsSPCCase(true);
    }
  }, [patentSakDetails]);

  return (
    <Box
      sx={{
        borderBottom: `4px solid ${theme.palette.borderGrønn.main}`,
        padding: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }
        }}
      >
        <Box sx={{ flexGrow: 1, marginRight: { xs: 0, md: 10 } }}>
          <BoxComponent
            headline={t('sak.received')}
            info={formatJsonTimeString(patentSakDetails.applicationDate)}
            justifyContent="space-between"
          />
          <BoxComponent
            headline={t('sak.grantDate')}
            info={formatJsonTimeString(patentSakDetails.registrationDate)}
            justifyContent="space-between"
          />
          <BoxComponent
            headline={t('sak.renewExpiryDate')}
            info={formatJsonTimeString(patentSakDetails.expiryDate)}
            justifyContent="space-between"
          />
          <BoxComponent
            headline={t('sak.generalAvailable')}
            info={formatJsonTimeString(patentSakDetails.opiDate)}
            justifyContent="space-between"
            whiteSpace="nowrap"
          />
          <BoxComponent
            headline={t('sak.publishedGrant')}
            info={formatJsonTimeString(patentSakDetails.publicationRegDate)}
            justifyContent="space-between"
            whiteSpace="nowrap"
          />
        </Box>

        <Box sx={{ flexGrow: 3 }}>
          {formatApplicantOrHolder(t, patentSakDetails.proprietors, patentSakDetails.applicants)}
          <BoxComponent
            headline={t('sak.powerOfAttorney')}
            info={formatParticipant(patentSakDetails.agent)}
            justifyContent="initial"
          />
          <BoxComponent headline={t('sak.title')} info={patentSakDetails.title} justifyContent="initial" />
          <ParticipantsBox
            participants={patentSakDetails.inventors}
            justifyContent="initial"
            headline={patentSakDetails.inventors?.length > 1 ? t('sak.inventors') : t('sak.inventor')}
          />
          <BoxComponent
            headline={t('sak.currentStatus')}
            info={
              manualTranslation(i18n, patentSakDetails.currentStatus) +
              ' (' +
              dayjs(patentSakDetails.currentStatusDate).format('DD.MM.YYYY') +
              ')'
            }
            justifyContent="flex-start"
          />
          <BoxComponent
            headline={t('sak.bioDepositedMaterials')}
            info={patentSakDetails.bioDepositedMaterials ? t('sak.yes') : t('sak.no')}
            justifyContent="flex-start"
          />
          <BoxComponent
            headline={t('sak.divided')}
            info={
              patentSakDetails.dividedSeparatedFrom
                ? patentSakDetails.dividedSeparatedFrom.applicationNumber
                : t('sak.no')
            }
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.priorities')}
            info={patentSakDetails.priorities && patentSakDetails.priorities.join(', ')}
            justifyContent="flex-start"
          />
          {patentSakDetails.pctNumber && (
            <BoxComponent headline={t('sak.pctNumber')} info={patentSakDetails.pctNumber} justifyContent="flex-start" />
          )}
          {patentSakDetails.internationalApplicationDate && (
            <BoxComponent
              headline={t('sak.internationalApplicationDate')}
              info={formatJsonTimeString(patentSakDetails.internationalApplicationDate)}
              justifyContent="flex-start"
            />
          )}
          <BoxComponent
            headline={t('sak.ipcClasses')}
            info={patentSakDetails.ipcClasses_Gransk && patentSakDetails.ipcClasses_Gransk.join(', ')}
            justifyContent="flex-start"
          />
          {isSPCCase && (
            <>
              {patentSakDetails.spcData?.basicPatentCaseIdentification && (
                <BoxComponent
                  headline={t('sak.spc.basicPatent')}
                  info={patentSakDetails.spcData?.basicPatentCaseIdentification.substring(2)}
                  justifyContent="flex-start"
                  link
                  linkHref={spcBasicPatentLink}
                />
              )}
              {patentSakDetails.spcData?.productIdentifier && (
                <BoxComponent
                  headline={t('sak.spc.productIdentifier')}
                  info={patentSakDetails.spcData?.productIdentifier}
                  justifyContent="flex-start"
                />
              )}
              {patentSakDetails.spcData?.productFirstAuthorization && (
                <BoxComponent
                  headline={t('sak.spc.authorizationNorway')}
                  info={formatAuthorization(patentSakDetails.spcData?.productFirstAuthorization)}
                  justifyContent="flex-start"
                />
              )}
              {patentSakDetails.spcData?.productEuropeanAuthorization && (
                <BoxComponent
                  headline={t('sak.spc.authorizationEAA')}
                  info={formatAuthorization(patentSakDetails.spcData?.productEuropeanAuthorization)}
                  justifyContent="flex-start"
                />
              )}
            </>
          )}
        </Box>
      </Box>
      <PsBox mt={3} flex justifyContent="flex-start">
        <PsLink target="_blank" href={linkToSearch}>
          <span>{t('sak.searchLink')}</span>
        </PsLink>
      </PsBox>
    </Box>
  );
};

export default PatentInfo;
