import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsLink } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import AudioContent from '../../../shared/components/AudioContent';
import VideoContent from '../../../shared/components/VideoContent';
import { useAppSelector } from '../../../shared/hooks/redux-hooks';
import { formatApplicantOrHolder, formatParticipant } from '../../../shared/utils/text-utils';
import { formatJsonTimeString, manualTranslation } from '../../../shared/utils/translation';
import BoxComponent from '../../shared/components/BoxComponent';

const VaremerkeInfo: FunctionComponent = () => {
  const { t, i18n } = useTranslation();

  const varemerkeSakDetails = useAppSelector((gs) => gs.varemerkeSakDetailsState.varemerkeSakDetails);
  const linkToSearch = `https://search.patentstyret.no/trademark/${varemerkeSakDetails.applicationNumber}`;

  return (
    <PsBox p={3}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <Box sx={{ flexGrow: 1, marginRight: { xs: 0, md: 10 } }}>
          <BoxComponent
            headline={t('sak.received')}
            info={formatJsonTimeString(varemerkeSakDetails.applicationDate)}
            justifyContent="space-between"
          />
          <BoxComponent
            headline={t('sak.registered')}
            info={formatJsonTimeString(varemerkeSakDetails.registrationDate)}
            justifyContent="space-between"
          />
          <BoxComponent
            headline={t('sak.renewalDeadline')}
            info={formatJsonTimeString(varemerkeSakDetails.expiryDate)}
            justifyContent="space-between"
          />
          <BoxComponent
            headline={t('sak.publishedRegistrationValid')}
            info={formatJsonTimeString(varemerkeSakDetails.publicationRegDate)}
            justifyContent="space-between"
          />
          {varemerkeSakDetails.internationalRegistrationDate && (
            <BoxComponent
              headline={t('sak.internationalRegDate')}
              info={formatJsonTimeString(varemerkeSakDetails.internationalRegistrationDate)}
              justifyContent="space-between"
            />
          )}
          {varemerkeSakDetails.notificationDate && (
            <BoxComponent
              headline={t('sak.notificationDate')}
              info={formatJsonTimeString(varemerkeSakDetails.notificationDate)}
              justifyContent="space-between"
            />
          )}
          {varemerkeSakDetails.subsequentDesignationDate && (
            <BoxComponent
              headline={t('sak.subsequentDesignationDate')}
              info={formatJsonTimeString(varemerkeSakDetails.subsequentDesignationDate)}
              justifyContent="space-between"
            />
          )}
        </Box>

        <Box sx={{ flexGrow: 3 }}>
          {varemerkeSakDetails.videoFiles?.map((videoFile) => (
            <VideoContent key={videoFile.fileName} filename={videoFile.fileName} isSak></VideoContent>
          ))}
          {varemerkeSakDetails.audioFiles?.map((audioFile) => (
            <AudioContent key={audioFile.fileName} filename={audioFile.fileName} isSak></AudioContent>
          ))}
          {formatApplicantOrHolder(t, varemerkeSakDetails.proprietors, varemerkeSakDetails.applicants)}
          <BoxComponent
            headline={t('sak.powerOfAttorney')}
            info={formatParticipant(varemerkeSakDetails.agent)}
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.markText')}
            info={varemerkeSakDetails.varemerkeText}
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.natureOfMark')}
            info={manualTranslation(i18n, varemerkeSakDetails.markFeature)}
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.markCategory')}
            info={manualTranslation(i18n, varemerkeSakDetails.markCategory)}
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.markDescription')}
            info={varemerkeSakDetails.markDescription}
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.markColorDescription')}
            info={varemerkeSakDetails.colorDescription}
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.registeredWithColors')}
            info={varemerkeSakDetails.colorRegistration ? t('sak.yes') : t('sak.no')}
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.priorities')}
            info={varemerkeSakDetails.priorities && varemerkeSakDetails.priorities.join(', ')}
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.currentStatus')}
            info={
              manualTranslation(i18n, varemerkeSakDetails.currentStatus) +
              ' (' +
              formatJsonTimeString(varemerkeSakDetails.currentStatusDate) +
              ')'
            }
            justifyContent="flex-start"
          />
          <BoxComponent
            headline={t('sak.divided')}
            info={
              varemerkeSakDetails.dividedSeparatedFrom
                ? varemerkeSakDetails.dividedSeparatedFrom.applicationNumber
                : t('sak.no')
            }
            justifyContent="initial"
          />
          <BoxComponent headline={t('sak.disclaimer')} info={varemerkeSakDetails.disclaimer} justifyContent="initial" />
          {varemerkeSakDetails.markRegulations && (
            <BoxComponent
              headline={t('sak.regulations')}
              info={varemerkeSakDetails.markRegulations}
              justifyContent="initial"
            />
          )}
          {varemerkeSakDetails.madridUtCaseNumber && (
            <BoxComponent
              headline={t('sak.madridUtCaseNumber')}
              info={varemerkeSakDetails.madridUtCaseNumber}
              justifyContent="initial"
            />
          )}
        </Box>
      </Box>
      <PsBox mt={3} flex justifyContent="flex-start">
        <PsLink target="_blank" href={linkToSearch}>
          <span>{t('sak.searchLink')}</span>
        </PsLink>
      </PsBox>
    </PsBox>
  );
};

export default VaremerkeInfo;
