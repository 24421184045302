import React, { FunctionComponent } from 'react';

import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { PsAccordion, PsAlert, PsBox, PsBreadcrumb, PsContainer, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Loader from '../../../shared/components/Loader';
import TechnicalErrorMessage from '../../../shared/components/TechnicalErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux-hooks';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { getSearchKunngjoringStateByRettighet } from '../../store/search-kunngjoring/selectors';
import { kunngjoringSearchAsync } from '../../store/search-kunngjoring/service';
import { setItemCount } from '../../store/search-param/reducer';
import { getSearchSakStateByRettighet } from '../../store/search-sak/selectors';
import { sakSearchAsync } from '../../store/search-sak/service';
import { SearchCategoryType } from '../../types/enums';
import SearchKunngjoringListItem from '../KunngjoringListItem';
import SearchSakListItem from '../SearchSakListItem';

import SearchBarPaginationBar from './SearchPaginationBar';
import SearchPanel from './SearchPanel';
import SearchToggle from './SearchToggle';

const SearchScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const rettighetstype = useRettighetstype();
  const searchKunngjoringState = useAppSelector((gs) => gs.searchKunngjoringState);
  const searchSakState = useAppSelector((gs) => gs.searchSakState);
  const kunngjoringState = getSearchKunngjoringStateByRettighet(searchKunngjoringState, rettighetstype);
  const sakState = getSearchSakStateByRettighet(searchSakState, rettighetstype);
  const searchParams = useAppSelector((gs) => gs.searchParamState);

  const { category, showSearchInfo } = searchParams;
  const loading = category === SearchCategoryType.Kunngjoring ? kunngjoringState.loading : sakState.loading;
  const loaded = category === SearchCategoryType.Kunngjoring ? kunngjoringState.loaded : sakState.loaded;
  const error = category === SearchCategoryType.Kunngjoring ? kunngjoringState.error : sakState.error;
  const searchResultLength =
    category === SearchCategoryType.Kunngjoring ? kunngjoringState.searchResult.length : sakState.searchResult.length;
  const dispatch = useAppDispatch();
  let paginationBar: JSX.Element | undefined;
  if (loaded && searchResultLength > 0) {
    paginationBar = <SearchBarPaginationBar rettighetstype={rettighetstype} category={category} />;
  }

  let infoMessage: JSX.Element | undefined;
  if (showSearchInfo) {
    infoMessage = (
      <PsAlert color="primary" severity="info">
        <div>{t('search.startPage')}</div>
      </PsAlert>
    );
  }

  let noRessultsMessage: JSX.Element | undefined;
  if (!error && loaded && searchResultLength === 0) {
    noRessultsMessage = (
      <PsAlert color="primary" severity="info">
        <div>{t('search.noResults')}</div>
      </PsAlert>
    );
  }

  let pageNumberOnTopOfPage: JSX.Element | undefined;
  if (loaded && searchResultLength > 0) {
    pageNumberOnTopOfPage = (
      <PsBox flex justifyContent="flex-start" alignItems="flex-start" mt={2} mb={-2} p={1}>
        <PsText>
          <span>
            {t('shared.hits') +
              ': ' +
              (category === SearchCategoryType.Kunngjoring ? kunngjoringState.totalHits : sakState.totalHits)}
          </span>
        </PsText>
      </PsBox>
    );
  }

  let canNotSearchWithoutSearchString: JSX.Element | undefined;
  if (searchParams.searchWithoutSearchString) {
    canNotSearchWithoutSearchString = (
      <PsAlert color="primary" severity="info">
        <div>{t('search.searchWithoutSearchString')}</div>
      </PsAlert>
    );
  }

  let kunngjoringResults: JSX.Element[] | undefined;
  if (category === SearchCategoryType.Kunngjoring) {
    kunngjoringResults = kunngjoringState.searchResult.map((item) => (
      <SearchKunngjoringListItem key={item.id} searchKunngjoring={item} />
    ));
  }

  let sakResults: JSX.Element[] | undefined;
  if (category === SearchCategoryType.Sak) {
    sakResults = sakState.searchResult.map((item) => (
      <SearchSakListItem key={item.sakIdentification} searchSak={item} />
    ));
  }

  const handleChangeInKunngjoringerPerPage = (event: SelectChangeEvent<number>) => {
    const updatedKunnjoringerPerPage = event.target.value as number;
    dispatch(setItemCount(updatedKunnjoringerPerPage));
    if (category === SearchCategoryType.Kunngjoring && searchParams.searchString != '') {
      dispatch(kunngjoringSearchAsync(rettighetstype, searchParams, updatedKunnjoringerPerPage));
    } else if (category === SearchCategoryType.Sak && searchParams.searchString != '') {
      dispatch(sakSearchAsync(rettighetstype, searchParams, updatedKunnjoringerPerPage));
    }
  };

  return (
    <PsContainer fluid pFluid>
      <PsContainer>
        <PsBreadcrumb>
          <li>
            <Link to="/">Tidende</Link>
          </li>
          <li>
            <Link to={`/${rettighetstype}`}>{t(rettighetstype)}</Link>
          </li>
          <li>
            <Link to="#">{t('search.sok')}</Link>
          </li>
        </PsBreadcrumb>
      </PsContainer>
      <PsContainer>
        <PsBox gutter>
          <PsText type="title1">
            <span>{t('headerSearch')}</span>
          </PsText>
          <SearchToggle />
        </PsBox>
        <PsBox mt={2}>
          <PsAccordion label={t('search.sok')} expanded>
            <SearchPanel rettighetstype={rettighetstype} />
          </PsAccordion>
        </PsBox>
        <PsBox flex={true} justifyContent="space-between">
          {<div>{pageNumberOnTopOfPage}</div>}
          <PsBox justifyContent="flex-end" p={1}>
            <Select value={searchParams.itemCount} onChange={handleChangeInKunngjoringerPerPage}>
              <MenuItem value={20}>20 {t('shared.hitsPerPage')}</MenuItem>
              <MenuItem value={50}>50 {t('shared.hitsPerPage')}</MenuItem>
              <MenuItem value={100}>100 {t('shared.hitsPerPage')}</MenuItem>
            </Select>
          </PsBox>
        </PsBox>
        <PsBox mt={0} gutter>
          <div>
            {loading && <Loader />}
            {error && <TechnicalErrorMessage />}
            {canNotSearchWithoutSearchString}
            {infoMessage}
            {noRessultsMessage}
            {kunngjoringResults}
            {sakResults}
            {paginationBar}
          </div>
        </PsBox>
      </PsContainer>
    </PsContainer>
  );
};

export default SearchScreen;
