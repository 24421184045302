import { FilterCheckboxValue } from '../../types/entities';

export interface FilterState {
  selectedAgentFilter: string;
  filterIsOpen: boolean;
  filterSubCategories: FilterCheckboxValue[];
  filterMarkType: FilterCheckboxValue[];
  filterApplicationType: FilterCheckboxValue[];
}

export const initialState: FilterState = {
  selectedAgentFilter: '',
  filterIsOpen: false,
  filterSubCategories: [],
  filterMarkType: [
    {
      checked: false,
      optionValue: {
        id: 'BBM_TYMARK_TRADEMARK',
        en: 'Trademark',
        no: 'Varemerke'
      }
    },
    {
      checked: false,
      optionValue: {
        id: 'BBM_TYMARK_HALLMARK',
        en: 'Responsibility mark',
        no: 'Ansvarsmerke'
      }
    },
    {
      checked: false,
      optionValue: {
        id: 'BBM_TYMARK_COLLECTIVE',
        en: 'Collective mark',
        no: 'Fellesmerke'
      }
    },
    {
      checked: false,
      optionValue: {
        id: 'BBM_TYMARK_GUARANTEEORCERTIFICATION',
        en: 'Guarantee or certification mark',
        no: 'Garanti- eller kontrollmerke'
      }
    }
  ],
  filterApplicationType: [
    {
      checked: false,
      optionValue: {
        id: 'national',
        en: 'National cases',
        no: 'Nasjonale saker'
      }
    },
    {
      checked: false,
      optionValue: {
        id: 'international',
        en: 'International cases that have been made applicable in Norway',
        no: 'Internasjonale saker som er gjort gjeldende i Norge'
      }
    }
  ]
};
