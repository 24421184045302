import React, { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';
import { PsBox, PsContainer, PsMaterialIcon, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { PublikasjonCategory } from '../../../../publikasjon/types/entities';
import ImageContent from '../../../../shared/components/ImageContent';
import { StyledTooltip } from '../../../../shared/components/Tooltip';
import { formatJsonTimeString, manualTranslation } from '../../../../shared/utils/translation';
import { baseStyles, headerStyles } from '../../../styles/BaseBoxStyles';
import { VaremerkeKunngjoringDetails } from '../../../types/entities';

import '../../../styles/style.css';

interface Props {
  details: VaremerkeKunngjoringDetails;
  category?: PublikasjonCategory;
}

const DetailedViewHeader: FunctionComponent<Props> = ({ category, details }: Props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  let categoryDesc = '';
  if (category) {
    categoryDesc = i18n.language === 'no' ? category.norwegianDescription : category.englishDescription;
  }

  return (
    <PsContainer>
      <Box
        color="secondary"
        sx={{
          ...headerStyles.topBox
        }}
      >
        {details.varemerkeDetails.markFeature.token == 'BBM_NATUREMARK_WORD' && (
          <StyledTooltip title={details.varemerkeDetails.varemerkeText}>
            <Typography sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
              {details.varemerkeDetails.varemerkeText}
            </Typography>
          </StyledTooltip>
        )}
        {(details.varemerkeDetails.markFeature.token == 'BBM_NATUREMARK_COMBINED_FIGURATIVE' ||
          details.varemerkeDetails.markFeature.token == 'BBM_NATUREMARK_3D') && (
          <ImageContent image={details.varemerkeDetails.image} hasImage={details.hasImage} detailedView />
        )}
        {details.varemerkeDetails.markFeature.token == 'BBM_NATUREMARK_MULTIMEDIA' && (
          <PsBox p={4} color="primary">
            <PsMaterialIcon symbol="videocam" size="xl"></PsMaterialIcon>
          </PsBox>
        )}
        {details.varemerkeDetails.markFeature.token == 'BBM_NATUREMARK_SOUND' && (
          <PsBox p={4} color="primary">
            <PsMaterialIcon symbol="audio_file" size="xl"></PsMaterialIcon>
          </PsBox>
        )}
        <Box sx={{ flexGrow: 2 }}>
          <PsBox flex justifyContent="center">
            <PsText>
              <span>{details.varemerkeDetails.applicationNumber}</span>
            </PsText>
            {details.varemerkeDetails.registrationNumber && (
              <PsBox ml={1}>
                <PsText>
                  <span>{`(${details.varemerkeDetails.registrationNumber})`}</span>
                </PsText>
              </PsBox>
            )}
          </PsBox>
          <PsBox flex flexDirection="column" alignItems="center" my={1}>
            <PsText type="title3" root={{ class: 'titleWithoutMargin' }}>
              <span>{manualTranslation(i18n, details.highLevelDescription).toLocaleUpperCase()}</span>
            </PsText>
            <PsText type="title4" root={{ class: 'titleWithoutMargin' }}>
              <span>{manualTranslation(i18n, details.subLevelDescription)}</span>
            </PsText>
          </PsBox>
        </Box>
        <PsBox>
          <PsText>
            <span>{details.nationalEventCode + ' / ' + details.legalStatusCode}</span>
          </PsText>
        </PsBox>
      </Box>
      <Box
        sx={{
          ...baseStyles.baseBoxStyleHeader
        }}
      >
        <PsBox mr={1}>
          <PsText type="bold">
            <span>{t('kunngjoring.category') + ':'}</span>
          </PsText>
        </PsBox>
        {categoryDesc && (
          <PsText>
            <span>{categoryDesc}</span>
          </PsText>
        )}
      </Box>
      <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
        <PsBox mr={1}>
          <PsText type="bold">
            <span>{t('kunngjoring.publicationDate') + ':'}</span>
          </PsText>
        </PsBox>
        <PsText>
          <span>{formatJsonTimeString(details.kunngjoringDate)}</span>
        </PsText>
      </Box>
      <Box sx={{ ...baseStyles.baseBoxStyleHeader }}>
        <PsBox mr={1}>
          <PsText type="bold">
            <span>{t('kunngjoring.eventDate') + ':'}</span>
          </PsText>
        </PsBox>
        <PsText>
          <span>{formatJsonTimeString(details.decisionDate)}</span>
        </PsText>
      </Box>
    </PsContainer>
  );
};

export default DetailedViewHeader;
