import { VaremerkeKunngjoringDetails } from '../../types/entities';

export interface VaremerkeKunngjoringStateById {
  loaded: boolean;
  error?: string;
  details: VaremerkeKunngjoringDetails | undefined;
  fileDownloadError?: string;
}

export interface VaremerkeKunngjoringDetailsState {
  [id: string]: VaremerkeKunngjoringStateById;
}

export const initialState: VaremerkeKunngjoringDetailsState = {};
