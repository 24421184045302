import { DesignKunngjoringDetails } from '../../types/entities';

export interface DesignKunngjoringStateById {
  loaded: boolean;
  error?: string;
  details: DesignKunngjoringDetails | undefined;
  fileDownloadError?: string;
}

export interface DesignKunngjoringDetailsState {
  [id: string]: DesignKunngjoringStateById;
}

export const initialState: DesignKunngjoringDetailsState = {};
