import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PatentSakDetails } from '../../types/entities';

import { PatentSakDetailsState, initialState } from './state';

const patentSakDetailsSlice = createSlice({
  name: 'PatentSakDetails',
  initialState,
  reducers: {
    reset: () => initialState,
    setLoaded: (state: PatentSakDetailsState, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
      state.success = false;
      state.error = undefined;
    },
    setSuccess: (state: PatentSakDetailsState, action: PayloadAction<boolean>) => {
      state.success = action.payload;
      state.loaded = true;
      state.error = undefined;
    },
    setError: (state: PatentSakDetailsState, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
      state.loaded = true;
      state.success = false;
    },
    setPatentSakDetails: (state: PatentSakDetailsState, action: PayloadAction<PatentSakDetails>) => {
      state.patentSakDetails = action.payload;
      state.loaded = true;
      state.success = false;
      state.error = undefined;
    }
  }
});

const { actions, reducer } = patentSakDetailsSlice;
export const { reset, setLoaded, setSuccess, setError, setPatentSakDetails } = actions;
export default reducer;
