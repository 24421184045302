export default {
  sak: 'Case',
  holder: 'Holder',
  received: 'Filed',
  powerOfAttorney: 'Representative',
  events: 'Events',
  grantDate: 'Grant date',
  registered: 'Registered/Granted',
  generalAvailable: 'Publicly available',
  expiryDate: 'Expiry date',
  renewExpiryDate: 'Annual fee due',
  classes: 'Classes',
  type: 'Type',
  help: 'Here is a timeline showing events over time for the case from newest to oldest. To get to a page with more event-details, click on the event.',
  expandable: ' events.',
  clickHere: 'Click here to expand...',
  hide: 'Click here to hide...',
  title: 'Title',
  markText: 'Mark text',
  markDescription: 'Mark description',
  markColorDescription: 'Mark color description',
  natureOfMark: 'Nature of mark',
  markCategory: 'Mark category',
  registeredWithColors: 'Registered with colors',
  inventors: 'Inventors',
  inventor: 'Inventor',
  ipcClasses: 'IPCclasses',
  granted: 'Granted',
  currentStatus: 'Current status',
  statusDate: 'Status date',
  divided: 'Divided from application',
  bioDepositedMaterials: 'Biological sample deposit',
  no: 'No',
  yes: 'Yes',
  publishedGrant: 'Published grant',
  priorities: 'Priority data',
  renewalDeadline: 'Renewal deadline',
  publishedRegistrationValid: 'Published reg./validation',
  disclaimer: 'Disclaimer',
  regulations: 'Regulations',
  products: 'Product',
  designers: 'Designer',
  internationalRegDate: 'International registration date',
  internationalApplicationDate: 'International filing date',
  pctNumber: 'PCT number',
  notificationDate: 'First notification date',
  subsequentDesignationDate: 'Subsequent designation date',
  madridUtCaseNumber: 'Basis for Int. registration',
  searchLink: "Click here to see more info about the case in NIPO's register data",
  timelineInfo:
    'The timeline shows all announcements for cases published from 4/3 – 2024 onwards. Announcements in the future are displayed in gray and will not be applicable until after this date.',
  spc: {
    productIdentifier: 'Markeded product',
    authorizationNorway: 'Authorization in Norway',
    authorizationEAA: 'Authorization within EAA',
    basicPatent: 'Basic patent'
  }
};
