import React, { type FunctionComponent } from 'react';

import { PsAccordion, PsBox, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { GoodsAndServices } from '../../../kunngjoring/types/entities';

interface Props {
  trademarkClasses: GoodsAndServices[];
}

const TrademarkClassesAccordion: FunctionComponent<Props> = ({ trademarkClasses }: Props) => {
  const { t } = useTranslation();

  const label = `${t('kunngjoring.productList')}: ${trademarkClasses
    .map((item) => item.classNumber.toString())
    .join(', ')}`;

  return (
    <PsAccordion label={label}>
      <div>
        {trademarkClasses.map((item) => (
          <PsBox key={item?.classNumber} flex mb={1}>
            <PsBox flex alignItems="center" justifyContent="center" p={1}>
              <PsText>
                <span>{item.classNumber}</span>
              </PsText>
            </PsBox>
            <PsBox p={1}>
              <PsText>
                <span>{item.goodsAndServicesText}</span>
              </PsText>
            </PsBox>
          </PsBox>
        ))}
      </div>
    </PsAccordion>
  );
};

export default TrademarkClassesAccordion;
