import React, { FunctionComponent } from 'react';

import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../hooks/redux-hooks';
import { setScrollPosition } from '../store/scroll-position/reducer';

import CopyableClickableBox from './CopyableClickableBox';

interface Props {
  redirectLink: string;
  children: React.ReactNode;
}

const ListItem: FunctionComponent<Props> = ({ children, redirectLink }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <CopyableClickableBox
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        height: 'max-content',
        borderBottom: `4px solid ${theme.palette.moseGrønn.main}`,
        '&:first-of-type': {
          borderTop: `4px solid ${theme.palette.moseGrønn.main}`
        },
        padding: 2,
        boxSizing: 'border-box',
        whiteSpace: 'initial',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover, &:focus-visible': {
          background: theme.palette.primary.light,
          cursor: 'pointer'
        }
      }}
      callback={() => {
        dispatch(setScrollPosition(window.scrollY));
        navigate(`${redirectLink}`);
      }}
    >
      {children}
    </CopyableClickableBox>
  );
};

export default ListItem;
