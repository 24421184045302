import React from 'react';

import { PsAccordion, PsBox, PsText } from '@patentstyret/designsystem-react';

import { ImageWithSequenceNumber } from '../types/entities';

import ImageContent from './ImageContent';

interface Props {
  designRepresentationSheets: ImageWithSequenceNumber[];
}

const DesignImages: React.FunctionComponent<Props> = ({ designRepresentationSheets }: Props) => {
  return (
    <PsAccordion label="Design" noEffect>
      <PsBox flex flexWrap="wrap">
        {designRepresentationSheets.map((design) => (
          <PsBox key={design.sequenceNumber} p={2}>
            <PsText type="small">
              <span>{design.sequenceNumber}</span>
            </PsText>
            <ImageContent image={design.image} hasImage />
          </PsBox>
        ))}
      </PsBox>
    </PsAccordion>
  );
};

export default DesignImages;
