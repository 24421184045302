import React, { FunctionComponent } from 'react';

import { PsBackdrop, PsLoadingIcon } from '@patentstyret/designsystem-react';

const Loader: FunctionComponent = () => {
  return (
    <PsBackdrop>
      <span>
        <PsLoadingIcon size="xxl" />
      </span>
    </PsBackdrop>
  );
};

export default Loader;
