export enum SearchCategoryType {
  Kunngjoring = 'kunngjoring',
  Sak = 'sak'
}

export enum SearchType {
  Agent = 'agent',
  Proprietor = 'proprietor',
  SakNumber = 'sakNumber',
  RegistrationNumber = 'registrationNumber'
}

export enum SearchPeriodType {
  Last30Days = 'last30Days',
  LastYear = 'lastYear',
  AllTime = 'allTime',
  Custom = 'customTimeInterval'
}

export enum BlobContainer {
  miniature = 'Miniature',
  medium = 'Medium',
  full = 'Full',
  video = 'Video',
  audio = 'Audio',
  document = 'Document'
}
