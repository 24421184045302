import { Rettighetstype } from '../../../shared/types/entities';

import { SearchSakState, SearchSakStateByRettighet, initialSearchSakStateByRettighet } from './state';

export const getSearchSakStateByRettighet = (
  searchSakState: SearchSakState,
  rettighetstype: Rettighetstype
): SearchSakStateByRettighet => {
  return searchSakState[rettighetstype] || initialSearchSakStateByRettighet;
};
