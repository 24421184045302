import axiosApi from '../../../shared/utils/axios-api';
import { VaremerkeKunngjoringDetails } from '../../types/entities';

export class KunngjoringDetailsHttpRequest {
  public static getVaremerkeKunngjoringDetails(kunngjoringId?: string, sakId?: string) {
    return axiosApi.get<VaremerkeKunngjoringDetails>(
      `${process.env.REACT_APP_API_URL}/KunngjoringDetails/DetailedVaremerkeKunngjoring?kunngjoringId=${kunngjoringId}&sakId=${sakId}`
    );
  }
}
