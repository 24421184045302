import {
  Image,
  ImageWithSequenceNumber,
  Participant,
  TranslationObject,
  Multimedia
} from '../../shared/types/entities';

export interface Kunngjoring {
  id: string;
  sakIdentification: string;
  categoryId: string;
  subCategoryId: string;
  applicationType: ApplicationType;
  hasImage: boolean;
  highLevelStatusCode: string;
  legalStatusEventCode: LegalStatusCode;
  subLevelDescription: TranslationObject;
  highLevelDescription: TranslationObject;
  sakDetails: SakOverviewForKunngjoring;
  genericPublishingData: GenericPublishingData;
  basicRecordClaimant: Participant[];
  basicRecordFilingDate: string;
  freeText: string;
  cnComplaintCompanyName?: string;
}

export interface MessageKunngjoring {
  id: string;
  freeText: string;
}

interface GenericPublishingData {
  identification: string;
  type: string;
  title: string;
  image: Image;
  publishingDate: string;
  description: string;
}

export enum ApplicationType {
  None = 'None',
  TMNational = 'TMNational',
  TMInternational = 'TMInternational',
  PTNational = 'PTNational',
  PTPCT = 'PTPCT',
  PTSPC = 'PTSPC',
  PTEP = 'PTEP',
  DSNational = 'DSNational',
  DSInternational = 'DSInternational'
}

export interface GoodsAndServices {
  classNumber: number;
  goodsAndServicesText: string;
}

export interface SakOverviewForKunngjoring {
  agent: Participant;
  applicationNumber: string;
  registrationNumber: string;
  proprietors: Participant[];
  applicants: Participant[];
  classes?: GoodsAndServices[];
  markFeature?: TranslationObject;
  markCategory?: TranslationObject;
  registrationDate: string;
  applicationDate: string;
  image: Image;
  title: string;
  mainIPClass: string;
}

export interface LegalStatusCode {
  detailedEventCode: string;
}

export interface Pagination {
  currentPage: number;
  kunngjoringerPerPage: number;
  totalNumberPages: number;
}

export interface PublikasjonData {
  publikasjonDate: Date;
  tidendeNumber: string;
  categoryId: string;
  subCategoryId?: string;
}

export interface KunngjoringDetails {
  id: string;
  hasImage: boolean;
  sakIdentification: string;
  kunngjoringDate: string;
  decisionDate: string;
  publikasjonData: PublikasjonData;
  legalStatusCode: string;
  nationalEventCode: string;
  highLevelDescription: TranslationObject;
  subLevelDescription: TranslationObject;
  rettighetsType: string;
  opiDate: string;
  basicRecord?: BasicRecord;
  eventData?: EventData;
}

interface BasicRecord {
  claimant: Participant[];
  attorney: Participant;
  recordFilingDate: string;
  recordIdentifier: string;
  kind: string;
  currentStatus: NationalStatus;
}

interface EventData {
  decision?: Decision;
  freeText?: string;
  previousAgent?: Participant[];
  previousPropretior?: Participant[];
  licence: Licence;
  genericPublishingData?: GenericPublishingData;
  link?: string;
  cnComplaintGroundsText?: string;
  cnComplaintCompanyName?: string;
  attachmentURL?: string;
}

interface Licence {
  licenceReference: string;
  contractType: TranslationObject;
  licenceParties: Participant[];
  scope: string;
}

interface Decision {
  authorithy: string;
  decisions: Decisions[];
  finalDecisionDate: string;
}

interface Decisions {
  id: number;
  title: string;
  content: string;
}

export interface DesignKunngjoringDetails extends KunngjoringDetails {
  designDetails: DesignDetails;
}

export interface VaremerkeKunngjoringDetails extends KunngjoringDetails {
  varemerkeDetails: VaremerkeDetails;
}

export interface PatentKunngjoringDetails extends KunngjoringDetails {
  patentDetails: PatentDetails;
}

export interface SakDetailsForKunngjoringDetails {
  applicationNumber: string;
  agent: Participant;
  applicants: Participant[];
  proprietors: Participant[];
  registrationDate: string;
  registrationNumber: string;
  expiryDate: string;
  applicationDate: string;
  image: Image;
  priorities: string[];
  currentStatus: TranslationObject;
  currentStatusDate: string;
}

interface DesignDetails extends SakDetailsForKunngjoringDetails {
  locarnoClasses: string[];
  designers: Participant[];
  productTitles: string[];
  designRepresentationSheets: ImageWithSequenceNumber[];
}

interface VaremerkeDetails extends SakDetailsForKunngjoringDetails {
  classes: GoodsAndServices[];
  markFeature: TranslationObject;
  varemerkeText: string;
  colorDescription: string;
  markDescription: string;
  markCategory: TranslationObject;
  videoFiles: Multimedia[];
  audioFiles: Multimedia[];
  associatedMarks: AssociatedMark[];
}

export interface AssociatedMark {
  applicationNumber: string;
  registrationNumber: string;
  associationCategory: string;
  currentStatus: NationalStatus;
}

export interface PatentDetails extends SakDetailsForKunngjoringDetails {
  title: string;
  inventors: Participant[];
  ipcClass: string;
  ipcClasses_Gransk: string[];
  spcData?: SPCData;
}

export interface SPCData {
  spcNumber: string;
  spcFilingDate: string;
  endValidityDate: string;
  spcGrantDate: string;
  inventionTitle: string;
  applicants: Participant[];
  proprietors: Participant[];
  agent: Participant;
  productFirstAuthorization: SPCAuthorization;
  productEuropeanAuthorization: SPCAuthorization;
  productIdentifier: string;
  currentStatus: NationalStatus;
  basicPatentCaseIdentification: string;
}

export interface NationalStatus {
  effectDate: string;
  highLevelStatusCode: string;
  highLevelStatusDescription: TranslationObject;
  subLevelStatusCode: string;
  subLevelStatusDescription: TranslationObject;
}

export interface SPCAuthorization {
  date: string;
  country: string;
  number: string;
}

export interface FilterCheckboxValue {
  checked: boolean;
  optionValue: SelectCheckboxOptionValue;
}

export interface SelectCheckboxOptionValue {
  id: string;
  en: string;
  no: string;
}
