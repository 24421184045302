import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { baseStyles } from '../../kunngjoring/styles/BaseBoxStyles';
import { BlobContainer } from '../../search/types/enums';
import useMedia from '../hooks/useMedia';

interface Props {
  filename: string;
  isSak?: boolean;
}

const AudioContent: FunctionComponent<Props> = ({ isSak = false, filename }: Props) => {
  const { t } = useTranslation();

  const { mediaSrc, mediaLoaded } = useMedia(true, filename, true, BlobContainer.audio);

  return (
    <>
      {isSak ? (
        <Box sx={{ ...baseStyles.baseStyleSakInfoPart, marginBottom: { xs: 1, sm: 0 } }}>
          <PsText type="bold">
            <span>{t('shared.audioFile')}:</span>
          </PsText>
          <PsBox ml={2}>
            {mediaLoaded && (
              <audio controls>
                <source src={mediaSrc} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            )}
          </PsBox>
        </Box>
      ) : (
        <Box sx={{ ...baseStyles.baseBoxStyleInfoPart, marginBottom: { xs: 1, sm: 0 } }}>
          <PsText type="bold">
            <span>{t('shared.audioFile')}:</span>
          </PsText>
          <PsBox ml={2}>
            {mediaLoaded && (
              <audio controls>
                <source src={mediaSrc} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            )}
          </PsBox>
        </Box>
      )}
    </>
  );
};

export default AudioContent;
