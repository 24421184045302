import axiosApi from '../../../shared/utils/axios-api';
import { PatentSakDetails } from '../../types/entities';

export class SakDetailsHttpRequest {
  public static getPatentSakDetails(sakIdentification?: string) {
    return axiosApi.get<PatentSakDetails>(
      `${process.env.REACT_APP_API_URL}/sak/patentsakdetails?sakidentification=${sakIdentification}`
    );
  }
}
