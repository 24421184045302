import React, { useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { applyPolyfills, defineCustomElements } from '@patentstyret/designsystem/dist/loader';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';

import App from './core/App';
import { initMockServiceWorker } from './core/msw-setup';
import { setupStore } from './core/store';
import theme from './core/theme';

import 'dayjs/locale/nb';
import 'dayjs/locale/en';

initMockServiceWorker();
const ScrollToTop = () => {
  const pathName = useLocation();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [pathName.pathname]);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const store = setupStore();
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

applyPolyfills().then(() => {
  defineCustomElements(window);
});
