import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsLink, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { formatApplicantOrHolder, formatParticipant } from '../../../shared/utils/text-utils';
import { manualTranslation } from '../../../shared/utils/translation';
import { Kunngjoring } from '../../types/entities';

interface Props {
  kunngjoring: Kunngjoring;
}

const MainContentCard: FunctionComponent<Props> = ({ kunngjoring }: Props) => {
  const { t, i18n } = useTranslation();
  const rettighetstype = useRettighetstype();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        maxWidth: { sm: '500px', md: '900px', lg: '900px', xl: '800px' }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'start'
        }}
      >
        <PsLink>
          <PsText>
            <span>
              {kunngjoring.sakDetails.applicationNumber}{' '}
              {kunngjoring.sakDetails.registrationNumber && `(${kunngjoring.sakDetails.registrationNumber})`}
            </span>
          </PsText>
        </PsLink>
      </Box>
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 2, marginTop: 2 }}>
          <PsText type="bold">
            <span>
              {manualTranslation(i18n, kunngjoring.highLevelDescription) +
                '. ' +
                manualTranslation(i18n, kunngjoring.subLevelDescription) +
                '.'}
            </span>
          </PsText>
          <PsText type="bold">
            <span>{kunngjoring.legalStatusEventCode.detailedEventCode}</span>
          </PsText>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: { sm: 'column', md: 'column', xl: 'row' }, justifyContent: 'start' }}
        >
          <PsBox mr={1}>
            {rettighetstype === 'patent' && (
              <PsText type="bold">
                <span>{t('kunngjoring.title') + ':'}</span>
              </PsText>
            )}
            {rettighetstype === 'design' && (
              <PsText type="bold">
                <span>{t('kunngjoring.products') + ':'}</span>
              </PsText>
            )}
            {rettighetstype === 'varemerke' && (
              <PsText type="bold">
                <span>{t('kunngjoring.markText') + ':'}</span>
              </PsText>
            )}
          </PsBox>
          <Box
            sx={{
              display: 'inline-block',
              overflowWrap: 'break-word'
            }}
          >
            <PsText>
              <span>{kunngjoring.sakDetails.title}</span>
            </PsText>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { sm: 'column', md: 'column', xl: 'row' },
            justifyContent: 'start'
          }}
        >
          <PsBox>
            {formatApplicantOrHolder(t, kunngjoring.sakDetails.proprietors, kunngjoring.sakDetails.applicants)}
          </PsBox>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: { sm: 'column', md: 'column', xl: 'row' }, justifyContent: 'start' }}
        >
          <PsBox mr={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.representative') + ':'}</span>
            </PsText>
          </PsBox>
          <PsText>
            <span>
              {kunngjoring.sakDetails.agent
                ? formatParticipant(kunngjoring.sakDetails.agent)
                : t('kunngjoring.notRegistered')}
            </span>
          </PsText>
        </Box>
      </Box>
    </Box>
  );
};

export default MainContentCard;
