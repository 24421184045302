import React, { FunctionComponent } from 'react';

import { Divider } from '@mui/material';

import ImageInListItem from '../../../shared/components/ImageInListeItem';
import ListItem from '../../../shared/components/ListItem';
import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { SakFromSearch } from '../../types/entities';

import InfoContent from './InfoContent';
import MainContent from './MainContent';

interface SearchSakListItemProps {
  searchSak: SakFromSearch;
}

const SearchSakListItem: FunctionComponent<SearchSakListItemProps> = ({ searchSak }: SearchSakListItemProps) => {
  const rettighetstype = useRettighetstype();
  const redirectLink = `/${rettighetstype}/sak/${searchSak.sakIdentification}`;

  return (
    <ListItem redirectLink={redirectLink}>
      <ImageInListItem
        markFeature={searchSak.markFeature ? searchSak.markFeature.token : ''}
        image={searchSak.image}
        hasImage={searchSak.hasImage}
        title={searchSak.title ?? ''}
      />
      <MainContent sak={searchSak} />
      <Divider orientation="vertical" flexItem sx={{ visibility: { sm: 'hidden', md: 'visible' } }} />
      <InfoContent sak={searchSak} />
    </ListItem>
  );
};

export default SearchSakListItem;
