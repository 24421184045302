import type { ContinuationToken, SakFromSearch } from '../../types/entities';

export interface SearchSakStateByRettighet {
  loading: boolean;
  loaded: boolean;
  error?: string;
  searchResult: SakFromSearch[];
  paginationPage: number;
  totalHits: number;
  totalPages: number;
  continuationTokens: ContinuationToken[];
}

export const initialSearchSakStateByRettighet: SearchSakStateByRettighet = {
  loading: false,
  loaded: false,
  error: undefined,
  searchResult: [],
  paginationPage: 0,
  totalHits: 0,
  totalPages: 0,
  continuationTokens: []
};

export interface SearchSakState {
  [rettighetstype: string]: SearchSakStateByRettighet;
}

export const initialState: SearchSakState = {};
