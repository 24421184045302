import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DesignKunngjoringDetails } from '../../types/entities';

import { DesignKunngjoringDetailsState, initialState } from './state';

export type DesignKunngjoringSuccessAction = {
  id: string;
  details: DesignKunngjoringDetails;
};

export type DesignKunngjoringFailedAction = {
  id: string;
  error: string;
};

export type DesignKunngjoringDownloadFailedAction = {
  id: string;
  fileDownloadError: string;
};

const designKunngjoringDetailsSlice = createSlice({
  name: 'designKunngjoringDetails',
  initialState,
  reducers: {
    loadSuccess: (state: DesignKunngjoringDetailsState, action: PayloadAction<DesignKunngjoringSuccessAction>) => {
      state[action.payload.id] = {
        loaded: true,
        details: action.payload.details
      };
    },
    loadFailed: (state: DesignKunngjoringDetailsState, action: PayloadAction<DesignKunngjoringFailedAction>) => {
      state[action.payload.id] = {
        loaded: true,
        error: action.payload.error,
        details: undefined
      };
    },
    loadFileDownloadError: (
      state: DesignKunngjoringDetailsState,
      action: PayloadAction<DesignKunngjoringDownloadFailedAction>
    ) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        fileDownloadError: action.payload.fileDownloadError
      };
    }
  }
});

const { actions, reducer } = designKunngjoringDetailsSlice;
export const { loadSuccess, loadFailed, loadFileDownloadError } = actions;
export default reducer;
