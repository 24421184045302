import { Rettighetstype } from '../../shared/types/entities';
import {
  getPublicationYearList as getPublicationDesignYearList,
  getPublicationList as getPublicationDesignList
} from '../data/design';
import {
  getPublicationYearList as getPublicationPatentYearList,
  getPublicationList as getPublicationPatentList
} from '../data/patent';
import {
  getPublicationYearList as getPublicationVaremerkeYearList,
  getPublicationList as getPublicationVaremerkeList
} from '../data/varemerke';

export const getPublicationYearList = (rettighetstype: Rettighetstype) => {
  switch (rettighetstype) {
    case 'varemerke':
      return getPublicationVaremerkeYearList();
    case 'patent':
      return getPublicationPatentYearList();
    case 'design':
      return getPublicationDesignYearList();
  }
};

export const getPublicationList = (rettighetstype: Rettighetstype, year: number) => {
  switch (rettighetstype) {
    case 'varemerke':
      return getPublicationVaremerkeList(year);
    case 'patent':
      return getPublicationPatentList(year);
    case 'design':
      return getPublicationDesignList(year);
  }
};
