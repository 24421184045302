import React, { FunctionComponent } from 'react';

import {
  PsAlert,
  PsBox,
  PsBreadcrumb,
  PsContainer,
  PsLinkList,
  PsLinkListItem,
  PsMaterialIcon,
  PsText
} from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { useRettighetstype } from '../../shared/hooks/useRettighetstype';
import { getPublicationList } from '../utils/data-utils';

const LegacyPublicationListScreeen: FunctionComponent = () => {
  const { t } = useTranslation();
  const { year } = useParams();
  const rettighetstype = useRettighetstype();

  const yearNum = year !== undefined ? parseInt(year, 0) : 0;
  const publikasjonerList = getPublicationList(rettighetstype, yearNum).sort((a, b) => (a > b ? 1 : -1));

  const publikasjonerItems = publikasjonerList.map((item) => {
    return (
      <PsLinkListItem key={item.id} label={item.name} href={item.url} target="_blank">
        <PsMaterialIcon symbol="picture_as_pdf" slot="icon" />
      </PsLinkListItem>
    );
  });

  const title = t(`legacy.${rettighetstype}.title`);
  const titleWithYear = `${title} ${year}`;
  const infoMsg = t(`legacy.${rettighetstype}.info_msg`);

  return (
    <PsContainer fluid color="primary" pFluid>
      <PsContainer>
        <PsBreadcrumb>
          <li>
            <Link to="/">{t('tidende')}</Link>
          </li>
          <li>
            <Link to={`/${rettighetstype}`}>{t(rettighetstype)}</Link>
          </li>
          <li>
            <Link to={`/${rettighetstype}/legacy/publikasjoner`}>{t('legacy.publikasjoner')}</Link>
          </li>
          <li>
            <Link to="#">{year}</Link>
          </li>
        </PsBreadcrumb>
      </PsContainer>
      <PsContainer htmlMarkup="main">
        <div>
          <PsBox gutter>
            <PsText type="title1">
              <span>{titleWithYear}</span>
            </PsText>
            <PsAlert severity="info">
              <span>{infoMsg}</span>
            </PsAlert>
          </PsBox>
          <PsBox mt={3}></PsBox>
          {publikasjonerList.length > 0 && <PsLinkList>{publikasjonerItems}</PsLinkList>}
        </div>
      </PsContainer>
    </PsContainer>
  );
};

export default LegacyPublicationListScreeen;
