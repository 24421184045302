import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Pagination } from '../../types/entities';

import { PaginationPublikasjonState, initialState } from './state';

const paginationPublikasjonSlice = createSlice({
  name: 'paginationPublikasjon',
  initialState,
  reducers: {
    reset: () => initialState,
    setPagination: (state: PaginationPublikasjonState, action: PayloadAction<Pagination>) => {
      state.totalNumberPages = action.payload.totalNumberPages;
      state.currentPage = action.payload.currentPage;
      state.kunngjoringerPerPage = action.payload.kunngjoringerPerPage;
    },
    setCurrentPage: (state: PaginationPublikasjonState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state: PaginationPublikasjonState, action: PayloadAction<number>) => {
      state.totalNumberPages = action.payload;
    },
    setKunngjoringerPerPage: (state: PaginationPublikasjonState, action: PayloadAction<number>) => {
      state.kunngjoringerPerPage = action.payload;
    }
  }
});

const { actions, reducer } = paginationPublikasjonSlice;
export const { reset, setPagination, setCurrentPage, setTotalPages, setKunngjoringerPerPage } = actions;
export default reducer;
