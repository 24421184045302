import React, { FunctionComponent, useEffect } from 'react';

import { PsBox, PsBreadcrumb, PsContainer, PsLinkList, PsLinkListItem, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import Loader from '../../shared/components/Loader';
import TechnicalErrorMessage from '../../shared/components/TechnicalErrorMessage';
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux-hooks';
import { useRettighetstype } from '../../shared/hooks/useRettighetstype';
import { formatPublicationName } from '../../shared/utils/text-utils';
import { getPublikasjonStateByRettighet } from '../store/publikasjon/selectors';
import { loadPublikasjonsAsync } from '../store/publikasjon/service';

const PublicationListScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const rettighetstype = useRettighetstype();
  const navigate = useNavigate();

  const publikasjonState = useAppSelector((gs) => gs.publikasjonState);
  const publikasjonStateByRettighet = getPublikasjonStateByRettighet(publikasjonState, rettighetstype);
  const { publikasjoner, loaded, error } = publikasjonStateByRettighet;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loaded) {
      dispatch(loadPublikasjonsAsync(rettighetstype));
    }
  }, []);

  const listClickHandler = (e: React.MouseEvent<HTMLPsLinkListItemElement>, link: string) => {
    navigate(link);
    e.preventDefault();
  };

  const publicationList = publikasjoner.map((pub) => {
    const name = formatPublicationName(t, pub);
    const link = `/${rettighetstype}/publikasjoner/${pub.id}`;
    return <PsLinkListItem key={pub.nr} label={name} href={link} onClick={(e) => listClickHandler(e, link)} />;
  });

  const content = (
    <PsBox>
      <PsLinkList>
        <PsBox py={1}>{publicationList}</PsBox>
      </PsLinkList>
    </PsBox>
  );

  return (
    <PsContainer fluid color="primary" pFluid>
      <PsContainer>
        <PsBreadcrumb>
          <li>
            <Link to="/">{t('tidende')}</Link>
          </li>
          <li>
            <Link to={`/${rettighetstype}`}>{t(rettighetstype)}</Link>
          </li>
          <li>
            <Link to="#">{t('publikasjon.publikasjoner')}</Link>
          </li>
        </PsBreadcrumb>
      </PsContainer>
      <PsContainer htmlMarkup="main">
        <div>
          <PsBox gutter>
            <PsText type="title1">
              <span>{t('publikasjon.publikasjoner')}</span>
            </PsText>
          </PsBox>
          {!loaded && <Loader />}
          {error && <TechnicalErrorMessage />}
          {publikasjoner.length > 0 && content}
        </div>
      </PsContainer>
    </PsContainer>
  );
};

export default PublicationListScreen;
