import { Publikasjon } from '../../types/entities';

export interface PublikasjonStateByRettighet {
  loaded: boolean;
  error?: string;
  publikasjoner: Publikasjon[];
}

export interface PublikasjonState {
  [rettighetstype: string]: PublikasjonStateByRettighet;
}

export const initialState: PublikasjonState = {};
