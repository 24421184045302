import React, { PropsWithChildren, KeyboardEvent } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

type Props = PropsWithChildren & {
  callback: () => void; //Callback function that triggers onClick and on Enter pressed
  sx?: SxProps<Theme>;
};

/**
 * A box that is clickable, tabbable and doesnt trigger click event when highlighting text inside box
 */
const CopyableClickableBox = ({ callback, sx, children }: Props) => {
  const handleMouseDown = () => {
    //Clear the text selection before handling the click event
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  };

  const handleItemClick = () => {
    const selection = window.getSelection();
    if (!selection || selection.toString() === '') {
      // If no text is selected, redirect to the specific case
      callback();
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      callback();
    }
  };

  return (
    <Box tabIndex={0} sx={sx} onMouseDown={handleMouseDown} onClick={handleItemClick} onKeyDown={handleKeyPress}>
      {children}
    </Box>
  );
};

export default CopyableClickableBox;
