import React, { FunctionComponent } from 'react';

import { PsAlert, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

const DownloadErrorMessage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PsAlert color="primary" label={t('shared.downloadErrorTittel')} severity="danger">
      <PsText>
        <span>{t('shared.downloadErrorMessage')}</span>
      </PsText>
    </PsAlert>
  );
};

export default DownloadErrorMessage;
