import React, { FunctionComponent } from 'react';

import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Rettighetstype } from '../../../../shared/types/entities';
import { SearchType } from '../../../types/enums';

interface Props {
  searchType: string;
  onSelectChange: (event: SearchType) => void;
  fullWidth?: boolean;
  rettighetsType: Rettighetstype;
}

const SelectSearchTypeControl: FunctionComponent<Props> = ({
  searchType,
  onSelectChange,
  fullWidth = false,
  rettighetsType
}: Props) => {
  const { t } = useTranslation();

  const searchTypeChangeHandler = (event: SelectChangeEvent) => {
    const selected = event.target.value as SearchType;
    onSelectChange(selected);
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <Select value={searchType} onChange={searchTypeChangeHandler} size="small" fullWidth>
        <MenuItem value={'agent'}>{t('search.representative')}</MenuItem>
        <MenuItem value={'sakNumber'}>{t('search.sakNumber')}</MenuItem>
        <MenuItem value={'proprietor'}>{t('search.proprietor')}</MenuItem>
        <MenuItem value={'registrationNumber'}>
          {rettighetsType == 'patent' ? t('search.patentNumber') : t('search.registrationNumber')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectSearchTypeControl;
