import { RettighetstypeShort } from '../../../shared/types/enums';
import axiosApi from '../../../shared/utils/axios-api';
import { PublikasjonCategory } from '../../types/entities';

export class CategoryHttpRequest {
  public static getCategories(publikasjonNr: string, domain: RettighetstypeShort) {
    return axiosApi.get<PublikasjonCategory[]>(
      `${process.env.REACT_APP_API_URL}/tidende/categories?gazetteNumber=${publikasjonNr}&domain=${domain}`
    );
  }

  public static getCategoryFromCategoryId(categoryId: string) {
    return axiosApi.get<PublikasjonCategory[]>(
      `${process.env.REACT_APP_API_URL}/tidende/category?categoryId=${categoryId}`
    );
  }
}
