import kunngjoringEn from '../../modules/kunngjoring/locales/en';
import legacyEn from '../../modules/legacy/locales/en';
import publikasjonEn from '../../modules/publikasjon/locales/en';
import sakEn from '../../modules/sak/locales/en';
import searchEn from '../../modules/search/locales/en';
import sharedEn from '../../modules/shared/locales/en';

export default {
  translation: {
    tidende: 'Gazette',
    tidende_subtitle:
      'Gazette is a publication that contains information about published rights applied for in Norway within the areas of patents, trademarks, and design.',
    varemerke_subtitle:
      'The Norwegian Trademark Gazette is a publication that contains announcements within the field of trademarks.',
    design_subtitle:
      'The Norwegian Design Gazette is a publication that contains announcements within the field of designs',
    patent_subtitle:
      'The Norwegian Patent Gazette is a publication that contains announcements within the field of patents.',
    tidende_varemerke: 'Norwegian Trademark Gazette',
    tidende_patent: 'Norwegian Patent Gazette',
    tidende_design: 'Norwegian Design Gazette',
    loadingFailedError: 'Something happend and the loading of the content failed.',
    varemerke: 'Trademark',
    design: 'Design',
    patent: 'Patent',
    aboutTidende: 'About Gazette',
    publications: 'Periodical publications',
    legacyPublications: 'Periodical publications (PDF)',
    headerSearch: 'Search for events and cases',
    cases: 'Cases',
    events: 'Events',
    publikasjon: publikasjonEn,
    kunngjoring: kunngjoringEn,
    sak: sakEn,
    shared: sharedEn,
    search: searchEn,
    legacy: legacyEn,
    nye_tjenester: 'Events',
    fromDate: 'From week 10-2024 and onwards',
    toDate: 'Up to week 9-2024',
    tradisjonelle_tjenester: 'Historical Events',
    disclaimer: 'Disclaimer',
    versjon_gjeldende: 'Current version',
    link_tidendeInfo: 'Read more about the gazette here'
  }
};
