export default {
  patentLink: 'Patent',
  varemerkeLink: 'Varemerke',
  designLink: 'Design',
  representative: 'Fullmektig',
  holder: 'Innehaver',
  holders: 'Innehavere',
  registered: 'Registrert/Gitt virkning',
  granted: 'Meddelt',
  classes: 'Klasser',
  status: 'Gjeldende status',
  event: 'Kunngjøring',
  applicants: 'Søkere',
  applicant: 'Søker',
  hits: 'Antall treff',
  hitsPerPage: ' treff per side',
  kunngjoringTypeOptions: {
    pending: 'Under behandling',
    annulment: 'Annullert',
    rejected: 'Avslått',
    shelved: 'Henlagt',
    withdrawn: 'Trukket',
    finallyShelved: 'Endelig henlagt',
    ceasedCancelled: 'Registrering opphevet',
    registered: 'Registrert',
    granted: 'Besluttet gjeldende',
    ceased: 'Registrering opphørt'
  },
  generellFeilTittel: 'Teknisk Feil',
  generellFeilInnhold: 'Vennligst prøv igjen senere.',
  velg: 'Velg..',
  downloadErrorTittel: 'Feil under nedlastning av fil',
  downloadErrorMessage: 'Det skjedde en feil under nedlastning av filen.',
  playVideo: 'Spill av',
  audioFile: 'Lydfil'
};
