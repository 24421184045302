import React, { FunctionComponent } from 'react';

import { Box, CircularProgress, Modal } from '@mui/material';
import { PsBox, PsButton, PsText, PsMaterialIcon, PsView } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { baseStyles } from '../../kunngjoring/styles/BaseBoxStyles';
import { BlobContainer } from '../../search/types/enums';
import useMedia from '../hooks/useMedia';

interface Props {
  filename: string;
  isSak?: boolean;
}

const VideoContent: FunctionComponent<Props> = ({ isSak = false, filename }: Props) => {
  const styleVideo = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '60vw',
    maxHeight: '60vh'
  };
  const styleVideoMobile = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100vw',
    maxHeight: 'auto'
  };
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const { mediaSrc, mediaLoaded } = useMedia(true, filename, true, BlobContainer.video);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {isSak ? (
        <Box sx={{ ...baseStyles.baseStyleSakInfoPart, marginBottom: { xs: 1, sm: 0 } }}>
          <PsText type="bold">
            <span>Video:</span>
          </PsText>
          <PsBox ml={2}>
            <PsButton
              onClick={(event) => {
                event.stopPropagation();
                handleOpen();
              }}
            >
              <PsMaterialIcon symbol="play_arrow"></PsMaterialIcon>{' '}
              <PsText>
                <span>{t('shared.playVideo')}</span>
              </PsText>
            </PsButton>
          </PsBox>
        </Box>
      ) : (
        <Box sx={{ ...baseStyles.baseBoxStyleInfoPart, marginBottom: { xs: 1, sm: 0 } }}>
          <PsText type="bold">
            <span>Video:</span>
          </PsText>
          <PsBox ml={2}>
            <PsButton
              onClick={(event) => {
                event.stopPropagation();
                handleOpen();
              }}
            >
              <PsMaterialIcon symbol="play_arrow"></PsMaterialIcon>{' '}
              <PsText>
                <span>{t('shared.playVideo')}</span>
              </PsText>
            </PsButton>
          </PsBox>
        </Box>
      )}
      <Modal
        open={open}
        onClose={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          event.stopPropagation();
          handleClose();
        }}
      >
        <Box>
          <PsView min="md">
            {mediaLoaded && (
              <video style={styleVideo} autoPlay controls>
                <source src={mediaSrc} type="video/mp4" />
              </video>
            )}
          </PsView>
          <PsView max="md">
            {mediaLoaded && (
              <video style={styleVideoMobile} autoPlay controls>
                <source src={mediaSrc} type="video/mp4" />
              </video>
            )}
          </PsView>
          {!mediaLoaded && <CircularProgress />}
        </Box>
      </Modal>
    </>
  );
};

export default VideoContent;
