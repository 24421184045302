import { createTheme, PaletteColor, PaletteColorOptions, Theme } from '@mui/material/styles';

const { palette } = createTheme();

declare module '@mui/material/styles' {
  interface Palette {
    lightGrey: PaletteColor;
    mediumGrey: PaletteColor;
    white: PaletteColor;
    moseGrønn: PaletteColor;
    borderGrønn: PaletteColor;
    timelinePurple: PaletteColor;
  }
  interface PaletteOptions {
    lightGrey: PaletteColorOptions;
    mediumGrey: PaletteColorOptions;
    white: PaletteColorOptions;
    moseGrønn: PaletteColor;
    borderGrønn: PaletteColor;
    timelinePurple: PaletteColor;
  }
}

declare module '@mui/material/Box' {
  interface BoxPropsColorOverrides {
    lightGrey: true;
    white: true;
    mediumGrey: true;
  }
}

// A custom theme for this app
const theme: Theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#004934',
      light: '#D9FFE3'
    },
    secondary: {
      main: '#008760'
    },
    background: {
      default: '#F5F5F5'
    },
    borderGrønn: palette.augmentColor({ color: { main: '#009e6e' } }),
    moseGrønn: palette.augmentColor({ color: { main: '#004934', light: '#D9FFE3' } }),
    lightGrey: palette.augmentColor({ color: { main: '#EDEDED' } }),
    mediumGrey: palette.augmentColor({ color: { main: '#CDCDCD' } }),
    timelinePurple: palette.augmentColor({ color: { main: '#8B71A8' } }),
    white: palette.augmentColor({ color: { main: '#FFFFFF' } })
  },
  typography: {
    fontFamily: 'Roboto',
    subtitle1: {
      fontSize: '18px',
      color: '#212121',
      fontWeight: 500
    },
    subtitle2: {
      fontSize: '18px',
      color: '#004934',
      fontWeight: 500
    },
    h5: {
      fontWeight: 500
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem'
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          border: '1px solid black'
        }
      }
    }
  }
});

export default theme;
