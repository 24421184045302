export enum RettighetstypeShort {
  Varemerke = 'TM',
  Design = 'DS',
  Patent = 'PT'
}

export enum KunngjoringType {
  pending = 'pending',
  annulment = 'annulment',
  rejected = 'rejected',
  shelved = 'shelved',
  withdrawn = 'withdrawn',
  finallyShelved = 'finallyShelved',
  ceasedCancelled = 'ceasedCancelled',
  registered = 'registered',
  granted = 'granted',
  ceased = 'ceased'
}
