import i18next from 'i18next';

import { RettighetstypeShort } from '../../../shared/types/enums';
import axiosApi from '../../../shared/utils/axios-api';
import { Publikasjon, PublikasjonKunngjoringForExport } from '../../types/entities';

// TODO: har lagt til domain i spørringen. Bekrefte at det blir brukt på backend.
export class PublikasjonerHttpRequest {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getPublikasjoner(domain: RettighetstypeShort) {
    // return axiosApi.get<Publikasjon[]>(`${process.env.REACT_APP_API_URL}/tidende/publikasjoner&domain=${domain}`); // TODO: endepunktet feiler
    return axiosApi.get<Publikasjon[]>(`${process.env.REACT_APP_API_URL}/tidende/publikasjoner`);
  }

  public static getPublicationPdf(gazetteNumber: string, domain: RettighetstypeShort) {
    return axiosApi.get(
      `${process.env.REACT_APP_API_URL}/print/printtidende?GazetteNumber=${gazetteNumber}&language=${i18next.language}&domain=${domain}`,
      {
        responseType: 'blob'
      }
    );
  }

  public static getPublikasjonJson(gazetteNumber: string, domain: RettighetstypeShort) {
    switch (domain) {
      case RettighetstypeShort.Varemerke:
        return axiosApi.get<PublikasjonKunngjoringForExport[]>(
          `${process.env.REACT_APP_API_URL}/ExternalApi/AllKunngjoringerInVaremerkePublication?GazetteNumber=${gazetteNumber}`
        );
      case RettighetstypeShort.Design:
        return axiosApi.get<PublikasjonKunngjoringForExport[]>(
          `${process.env.REACT_APP_API_URL}/ExternalApi/AllKunngjoringerInDesignPublication?GazetteNumber=${gazetteNumber}`
        );
      case RettighetstypeShort.Patent:
        return axiosApi.get<PublikasjonKunngjoringForExport[]>(
          `${process.env.REACT_APP_API_URL}/ExternalApi/AllKunngjoringerInPatentPublication?GazetteNumber=${gazetteNumber}`
        );
    }
  }
}
