import { PublicationYearItem } from '../types/entities';

const basePublicationLink = 'https://search.patentstyret.no/tidende/design';

const getZeroTrailingId = (id: number) => {
  return id < 10 ? `0${id}` : `${id}`;
};

const publicationList: PublicationYearItem[] = [
  { year: 2005, publications: 26 },
  { year: 2006, publications: 26 },
  { year: 2007, publications: 26 },
  { year: 2008, publications: 26 },
  { year: 2009, publications: 27 },
  { year: 2010, publications: 26 },
  { year: 2011, publications: 26 },
  { year: 2012, publications: 26 },
  { year: 2013, publications: 26 },
  { year: 2014, publications: 26 },
  { year: 2015, publications: 26 },
  { year: 2016, publications: 26 },
  { year: 2017, publications: 26 },
  { year: 2018, publications: 26 },
  { year: 2019, publications: 26 },
  { year: 2020, publications: 27 },
  { year: 2021, publications: 26 },
  { year: 2022, publications: 26 },
  { year: 2023, publications: 26 },
  { year: 2024, publications: 4 }
];

export const getPublicationYearList = () => {
  return publicationList.map((x) => ({ year: x.year }));
};

export const getPublicationList = (year: number) => {
  const curr = publicationList.find((x) => x.year === year);
  const numPublications = curr?.publications || 0;
  const publications = [];
  for (let i = 1; i <= numPublications; i++) {
    const publikasjonName = `designtidende-nr${getZeroTrailingId(i)}-${year}.pdf`;
    publications.push({
      year,
      id: i,
      name: publikasjonName,
      url: `${basePublicationLink}/${year}/${publikasjonName}`
    });
  }
  return publications;
};
