import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsLink } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../shared/hooks/redux-hooks';
import { formatApplicantOrHolder, formatParticipant } from '../../../shared/utils/text-utils';
import { formatJsonTimeString, manualTranslation } from '../../../shared/utils/translation';
import BoxComponent from '../../shared/components/BoxComponent';
import ParticipantsBox from '../../shared/components/ParticipantsBox';

const DesignInfo: FunctionComponent = () => {
  const { t, i18n } = useTranslation();

  const designSakDetails = useAppSelector((gs) => gs.designSakDetailsState.designSakDetails);
  const linkToSearch = `https://search.patentstyret.no/design/${designSakDetails.applicationNumber}`;

  return (
    <PsBox p={3}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }
        }}
      >
        <Box sx={{ flexGrow: 1, marginRight: { xs: 0, md: 10 } }}>
          <BoxComponent
            headline={t('sak.received')}
            info={formatJsonTimeString(designSakDetails.applicationDate)} //
            justifyContent="space-between"
          />
          <BoxComponent
            headline={t('sak.registered')}
            info={formatJsonTimeString(designSakDetails.registrationDate)}
            justifyContent="space-between"
          />
          <BoxComponent
            headline={t('sak.expiryDate')}
            info={formatJsonTimeString(designSakDetails.expiryDate)}
            justifyContent="space-between"
          />
          <BoxComponent
            headline={t('sak.generalAvailable')}
            info={formatJsonTimeString(designSakDetails.opiDate)}
            justifyContent="space-between"
          />
          <BoxComponent
            headline={t('sak.publishedRegistrationValid')}
            info={formatJsonTimeString(designSakDetails.publicationRegDate)}
            justifyContent="space-between"
            whiteSpace="nowrap"
          />
          {designSakDetails.internationalRegistrationDate && (
            <BoxComponent
              headline={t('sak.internationalRegDate')}
              info={formatJsonTimeString(designSakDetails.internationalRegistrationDate)}
              justifyContent="space-between"
            />
          )}
        </Box>

        <Box sx={{ flexGrow: 3 }}>
          {formatApplicantOrHolder(t, designSakDetails.proprietors, designSakDetails.applicants)}
          <BoxComponent
            headline={t('sak.powerOfAttorney')}
            info={formatParticipant(designSakDetails.agent)}
            justifyContent="initial"
          />
          <ParticipantsBox
            headline={t('sak.designers')}
            participants={designSakDetails.designers}
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.priorities')}
            info={designSakDetails.priorities && designSakDetails.priorities.join(', ')}
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.currentStatus')}
            info={
              manualTranslation(i18n, designSakDetails.currentStatus) +
              ' (' +
              formatJsonTimeString(designSakDetails.currentStatusDate) +
              ')'
            }
            justifyContent="flex-start"
            whiteSpace="nowrap"
          />
          <BoxComponent
            headline={t('sak.divided')}
            info={
              designSakDetails.dividedSeparatedFrom
                ? designSakDetails.dividedSeparatedFrom.applicationNumber
                : t('sak.no')
            }
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.products')}
            info={designSakDetails.productTitles && designSakDetails.productTitles.join(', ')}
            justifyContent="initial"
          />
          <BoxComponent
            headline={t('sak.classes')}
            info={designSakDetails.locarnoClasses && designSakDetails.locarnoClasses.join(', ')}
            justifyContent="initial"
          />
        </Box>
      </Box>
      <PsBox mt={3} flex justifyContent="flex-start">
        <PsLink target="_blank" href={linkToSearch}>
          <span>{t('sak.searchLink')}</span>
        </PsLink>
      </PsBox>
    </PsBox>
  );
};

export default DesignInfo;
