import { Rettighetstype } from '../../../shared/types/entities';

import {
  SearchKunngjoringState,
  SearchKunngjoringStateByRettighet,
  initialSearchKunngjoringStateByRettighet
} from './state';

export const getSearchKunngjoringStateByRettighet = (
  searchKunngjoringState: SearchKunngjoringState,
  rettighetstype: Rettighetstype
): SearchKunngjoringStateByRettighet => {
  return searchKunngjoringState[rettighetstype] || initialSearchKunngjoringStateByRettighet;
};
