import React from 'react';

import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { formatApplicantOrHolder } from '../../../shared/utils/text-utils';
import { KunngjoringFromSearch } from '../../types/entities';

interface Props {
  searchKunngjoring: KunngjoringFromSearch;
}

const ProprietorField = ({ searchKunngjoring }: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: 1,
        justifyContent: 'start',
        flexDirection: { xs: 'column', sm: 'row' }
      }}
    >
      {formatApplicantOrHolder(t, searchKunngjoring.proprietors, searchKunngjoring.applicants)}
    </Box>
  );
};

export default ProprietorField;
