import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { Rettighetstype } from '../../../shared/types/entities';
import { Kunngjoring } from '../../types/entities';

interface Props {
  kunngjoring: Kunngjoring;
  rettighetstype: Rettighetstype;
}

const Classes: FunctionComponent<Props> = ({ kunngjoring, rettighetstype }) => {
  const { t } = useTranslation();
  const renderKunngjoringClasses = () => {
    if (rettighetstype === 'patent') {
      return kunngjoring.sakDetails.mainIPClass;
    }
    if (kunngjoring.sakDetails.classes != null) {
      let stringWithClasses = '';
      for (const sakClass of kunngjoring.sakDetails.classes) {
        stringWithClasses += sakClass + ', ';
      }
      return stringWithClasses.slice(0, stringWithClasses.length - 2);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <PsBox mr={1}>
        <PsText type="bold">
          <span>{t('kunngjoring.classes') + ': '}</span>
        </PsText>
      </PsBox>
      <PsText>
        <span>{renderKunngjoringClasses()}</span>
      </PsText>
    </Box>
  );
};

export default Classes;
