import { AppThunk } from '../../../../core/store';
import { Rettighetstype } from '../../../shared/types/entities';
import { mapRettighetstypeShort } from '../../../shared/utils/rettighetstype-utils';

import { PublikasjonerHttpRequest } from './http-request';
import { loadSuccess, loadFailed } from './reducer';

export const loadPublikasjonsAsync = (rettighetstype: Rettighetstype): AppThunk => {
  return (dispatch) => {
    const rettighetstypeShort = mapRettighetstypeShort(rettighetstype);
    PublikasjonerHttpRequest.getPublikasjoner(rettighetstypeShort)
      .then((response) => {
        // TODO: sort bør enten gjøres på backend eller som en util-metode der listen vises
        const sortedPublications = [...response.data]
          .sort((a, b) => {
            return new Date(a.publikasjonDate).getTime() - new Date(b.publikasjonDate).getTime();
          })
          .reverse();
        dispatch(loadSuccess({ id: rettighetstype, publikasjoner: sortedPublications }));
      })
      .catch((error) => {
        dispatch(loadFailed({ id: rettighetstype, error: error.toString() }));
      });
  };
};
