import i18next from 'i18next';

import { RettighetstypeShort } from '../../../shared/types/enums';
import axiosApi from '../../../shared/utils/axios-api';

export class PrintKunngjoringHttpRequest {
  public static getKunngjoringPdf(
    kunngjoringId: string,
    rettighetsType: RettighetstypeShort,
    publikasjonNumber: string
  ) {
    return axiosApi.get(
      `${process.env.REACT_APP_API_URL}/print/PrintKunngjoring?kunngjoringId=${kunngjoringId}&gazetteNumber=${publikasjonNumber}&language=${i18next.language}&domain=${rettighetsType}`,
      {
        responseType: 'blob'
      }
    );
  }
}
