import { AppThunk } from '../../../../core/store';
import { Rettighetstype } from '../../../shared/types/entities';
import { mapRettighetstypeShort } from '../../../shared/utils/rettighetstype-utils';
import { PublikasjonCategory } from '../../types/entities';

import { CategoryHttpRequest } from './http-request';
import { loadSuccess, loadFailed } from './reducer';

export const loadPublikasjonCategoriesAsync = (publikasjonId: string, rettighetstype: Rettighetstype): AppThunk => {
  return (dispatch) => {
    const rettighetstypeShort = mapRettighetstypeShort(rettighetstype);
    CategoryHttpRequest.getCategories(publikasjonId, rettighetstypeShort)
      .then((response) => {
        const publikasjonCategories: PublikasjonCategory[] = response.data;
        const uniqueId = `${rettighetstype}-${publikasjonId}`;
        dispatch(loadSuccess({ id: uniqueId, content: publikasjonCategories }));
      })
      .catch((error) => {
        dispatch(loadFailed({ id: publikasjonId, error: error.toString() }));
      });
  };
};
