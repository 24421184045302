import React, { type FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsText } from '@patentstyret/designsystem-react';

import ImageContent from '../../../shared/components/ImageContent';
import { useAppSelector } from '../../../shared/hooks/redux-hooks';
import { styles } from '../../shared/styles/BaseBoxStyles';

const DetailedViewHeader: FunctionComponent = () => {
  const patentSakDetails = useAppSelector((gs) => gs.patentSakDetailsState.patentSakDetails);

  return (
    <>
      <Box
        sx={{
          ...styles.headerBoxStyle
        }}
      >
        <PsText type="title4">
          {patentSakDetails.registrationNumber ? (
            <span>{`${patentSakDetails.applicationNumber} (${patentSakDetails.registrationNumber})`}</span>
          ) : (
            <span>{`${patentSakDetails.applicationNumber}`}</span>
          )}
        </PsText>
        <ImageContent image={patentSakDetails.image} hasImage={patentSakDetails.hasImage} detailedView />
      </Box>
    </>
  );
};

export default DetailedViewHeader;
