import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsText } from '@patentstyret/designsystem-react';

import { Participant } from '../../../shared/types/entities';
import { formatParticipant } from '../../../shared/utils/text-utils';

interface BoxProps {
  headline: string;
  participants: Participant[];
  justifyContent: string;
}

const ParticipantsBox: FunctionComponent<BoxProps> = ({ headline, participants, justifyContent }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: justifyContent,
        marginTop: 1
      }}
    >
      <PsBox mr={1}>
        <PsText type="bold">
          <span>{headline}:</span>
        </PsText>
      </PsBox>
      <PsBox>
        {participants?.map((item) => (
          <Box
            key={item.name}
            sx={{
              paddingBottom: participants.length > 1 ? 1 : 0,
              flex: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <PsText>
              <span>{formatParticipant(item)}</span>
            </PsText>
          </Box>
        ))}
      </PsBox>
    </Box>
  );
};

export default ParticipantsBox;
