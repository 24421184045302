import { configureStore, ThunkAction, Action, combineReducers, PreloadedState } from '@reduxjs/toolkit';

import kunngjoringReducer from '../modules/kunngjoring/store/kunngjoring/reducer';
import designKunngjoringDetailsReducer from '../modules/kunngjoring/store/kunngjoring-design/reducer';
import kunngjoringFilterReducer from '../modules/kunngjoring/store/kunngjoring-filter/reducer';
import paginationPublikasjonReducer from '../modules/kunngjoring/store/kunngjoring-pagination/reducer';
import patentKunngjoringDetailsReducer from '../modules/kunngjoring/store/kunngjoring-patent/reducer';
import varemerkeKunngjoringDetailsReducer from '../modules/kunngjoring/store/kunngjoring-varemerke/reducer';
import messageKunngjoringReducer from '../modules/kunngjoring/store/message-kunngjoring/reducer';
import publikasjonReducer from '../modules/publikasjon/store/publikasjon/reducer';
import publikasjonCategoryReducer from '../modules/publikasjon/store/publikasjon-category/reducer';
import designSakDetailsReducer from '../modules/sak/store/design-sak-details/reducer';
import patentSakDetailsReducer from '../modules/sak/store/patent-sak-details/reducer';
import varemerkeSakDetailsReducer from '../modules/sak/store/varemerke-sak-details/reducer';
import searchKunngjoringReducer from '../modules/search/store/search-kunngjoring/reducer';
import searchParamReducer from '../modules/search/store/search-param/reducer';
import searchSakReducer from '../modules/search/store/search-sak/reducer';
import scrollPositionReducer from '../modules/shared/store/scroll-position/reducer';

export const rootReducer = combineReducers({
  publikasjonState: publikasjonReducer,
  publikasjonCategoryState: publikasjonCategoryReducer,
  kunngjoringState: kunngjoringReducer,
  varemerkeKunngjoringDetailsState: varemerkeKunngjoringDetailsReducer,
  designKunngjoringDetailsState: designKunngjoringDetailsReducer,
  patentKunngjoringDetailsState: patentKunngjoringDetailsReducer,
  kunngjoringFilterState: kunngjoringFilterReducer,
  varemerkeSakDetailsState: varemerkeSakDetailsReducer,
  designSakDetailsState: designSakDetailsReducer,
  searchParamState: searchParamReducer,
  searchKunngjoringState: searchKunngjoringReducer,
  searchSakState: searchSakReducer,
  paginationPublikasjonState: paginationPublikasjonReducer,
  patentSakDetailsState: patentSakDetailsReducer,
  messageKunngjoringState: messageKunngjoringReducer,
  scrollPositionState: scrollPositionReducer
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
