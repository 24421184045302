import { MessageKunngjoring } from '../../types/entities';

export interface MessageKunngjoringStateById {
  loaded?: boolean;
  error?: string;
  messagekunngjoringer: MessageKunngjoring[];
}

export interface MessageKunngjoringState {
  [id: string]: MessageKunngjoringStateById;
}

export const initialState: MessageKunngjoringState = {};
