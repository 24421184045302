import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsText, PsView } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';

import { useRettighetstype } from '../../../shared/hooks/useRettighetstype';
import { formatJsonTimeString } from '../../../shared/utils/translation';
import { Kunngjoring } from '../../types/entities';

import Classes from './Classes';

interface Props {
  kunngjoring: Kunngjoring;
}

const DateContent: FunctionComponent<Props> = ({ kunngjoring }: Props) => {
  const rettighetstype = useRettighetstype();
  const { t } = useTranslation();
  return (
    <PsView min="lg">
      <Box
        sx={{
          display: 'flex',
          width: '350px',
          padding: 1,
          paddingLeft: 5,
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <PsBox mr={1}>
            <PsText type="bold">
              <span>{t('kunngjoring.received') + ': '}</span>
            </PsText>
          </PsBox>
          <PsText>
            <span>{formatJsonTimeString(kunngjoring.sakDetails.applicationDate)}</span>
          </PsText>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <PsBox mr={1}>
            {rettighetstype === 'patent' ? (
              <PsText type="bold">
                <span>{t('shared.granted') + ': '}</span>
              </PsText>
            ) : (
              <PsText type="bold">
                <span>{t('kunngjoring.registered') + ': '}</span>
              </PsText>
            )}
          </PsBox>
          <PsText>
            <span>{formatJsonTimeString(kunngjoring.sakDetails.registrationDate)}</span>
          </PsText>
        </Box>
        <Classes rettighetstype={rettighetstype} kunngjoring={kunngjoring} />
      </Box>
    </PsView>
  );
};

export default DateContent;
