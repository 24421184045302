import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { PsBox, PsLink, PsText } from '@patentstyret/designsystem-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AudioContent from '../../../../shared/components/AudioContent';
import VideoContent from '../../../../shared/components/VideoContent';
import { useAppDispatch } from '../../../../shared/hooks/redux-hooks';
import { setScrollPosition } from '../../../../shared/store/scroll-position/reducer';
import { formatApplicantOrHolder, formatParticipant } from '../../../../shared/utils/text-utils';
import { formatJsonTimeString, manualTranslation } from '../../../../shared/utils/translation';
import { baseStyles } from '../../../styles/BaseBoxStyles';
import { VaremerkeKunngjoringDetails } from '../../../types/entities';

interface Props {
  details: VaremerkeKunngjoringDetails;
}

const VaremerkeInfo: FunctionComponent<Props> = ({ details }: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sakDetailsLink = `/varemerke/sak/${details.sakIdentification}`;

  return (
    <>
      <PsBox color="primary" severity="success" py={0.5} pl={2} flex flexDirection="row" alignItems="center">
        <PsText
          type="title4"
          onClick={() => {
            dispatch(setScrollPosition(0));
            navigate(sakDetailsLink);
          }}
        >
          <PsLink>
            {' '}
            <span>{t('kunngjoring.sakDetails')}</span>
          </PsLink>
        </PsText>
      </PsBox>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <Box
          sx={{
            flex: 1,
            marginRight: { xs: 0 },
            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px;'
          }}
        >
          <PsBox p={2}>
            <PsBox flex justifyContent="space-between" pb={0.5}>
              <PsText type="bold">
                <span>{t('kunngjoring.received')}: </span>
              </PsText>
              <PsText>
                <span>{formatJsonTimeString(details.varemerkeDetails.applicationDate)}</span>
              </PsText>
            </PsBox>
            <PsBox flex justifyContent="space-between" pb={0.5}>
              <PsText type="bold">
                <span>{t('shared.registered')}: </span>
              </PsText>
              <PsText>
                <span>{formatJsonTimeString(details.varemerkeDetails.registrationDate)}</span>
              </PsText>
            </PsBox>
          </PsBox>
        </Box>

        <Box sx={{ flex: 2.5, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box>
            {details.varemerkeDetails.videoFiles &&
              details.varemerkeDetails?.videoFiles?.map((videoFile) => (
                <VideoContent key={videoFile.fileName} filename={videoFile.fileName}></VideoContent>
              ))}
          </Box>
          <Box>
            {details.varemerkeDetails.audioFiles &&
              details.varemerkeDetails?.audioFiles?.map((audioFile) => (
                <AudioContent key={audioFile.fileName} filename={audioFile.fileName}></AudioContent>
              ))}
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            {formatApplicantOrHolder(t, details.varemerkeDetails.proprietors, details.varemerkeDetails.applicants)}
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            <PsText type="bold">
              <span>{t('shared.representative')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{formatParticipant(details.varemerkeDetails.agent)}</span>
              </PsText>
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            <PsText type="bold">
              <span>{t('kunngjoring.currentStatus')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{manualTranslation(i18n, details.varemerkeDetails.currentStatus)}</span>
                <span>{` (${formatJsonTimeString(details.varemerkeDetails.currentStatusDate)})`}</span>
              </PsText>
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            <PsText type="bold">
              <span>{t('kunngjoring.priority')}:</span>
            </PsText>
            <PsBox ml={1} flex flexDirection="column">
              <PsBox ml={1}>
                {details.varemerkeDetails.priorities?.map((item) => (
                  <PsText key={item}>
                    <span>{item}</span>
                  </PsText>
                ))}
              </PsBox>
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart }}>
            <PsText type="bold">
              <span>{t('kunngjoring.natureOfMark')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{manualTranslation(i18n, details.varemerkeDetails.markFeature)}</span>
              </PsText>
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart, marginBottom: { xs: 1, sm: 0 } }}>
            <PsText type="bold">
              <span>{t('kunngjoring.markText')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{details.varemerkeDetails.varemerkeText}</span>
              </PsText>
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart, marginBottom: { xs: 1, sm: 0 } }}>
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <PsText type="bold">
                <span>{t('kunngjoring.markDescription')}:</span>
              </PsText>
            </Box>
            <PsBox ml={1}>
              <PsText>
                <span>{details.varemerkeDetails.markDescription}</span>
              </PsText>
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart, marginBottom: { xs: 1, sm: 0 } }}>
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <PsText type="bold">
                <span>{t('kunngjoring.markColorDescription')}:</span>
              </PsText>
            </Box>
            <PsBox ml={1}>
              <PsText>
                <span>{details.varemerkeDetails.colorDescription}</span>
              </PsText>
            </PsBox>
          </Box>
          <Box sx={{ ...baseStyles.baseBoxStyleInfoPart, marginBottom: { xs: 1, sm: 0 } }}>
            <PsText type="bold">
              <span>{t('kunngjoring.markCategory')}:</span>
            </PsText>
            <PsBox ml={1}>
              <PsText>
                <span>{manualTranslation(i18n, details.varemerkeDetails.markCategory)}</span>
              </PsText>
            </PsBox>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VaremerkeInfo;
